import axios from 'axios'
import { consoleApiBaseUrl } from '../../../config'
import { User } from '../../../reducers/auth/auth.types'
import getAccessToken from '../../../helpers/access-token'

const baseUrl = `${consoleApiBaseUrl}/twirp/pb.DepositOffer`

interface Formvalues {
  [index: string]: string | string[] | boolean | number | undefined
}

interface ApiReturnValue {
  screens?: string[]
  success: boolean
  error: string
}

export const createOffer = async (
  values: Formvalues,
  user: User
): Promise<ApiReturnValue> => {
  const apiEndpoint = `${baseUrl}/CreateOffer`

  /* eslint-disable */
  const {
    name,
    coupon_code,
    title,
    subtitle,
    tags,
    ftu_title,
    ftu_subtitle,
    icon_url,
    details,
    offer_type = 'deposit',
    budget_type,
    budget_value,
    p_max_attempt_per_day,
    p_max_attempt_overall,
    p_subsequent_use_interval_minutes,
    t1,
    t2,
    t3,
    t4,
    min_amount,
    max_amount,
    reward_type,
    product_id,
    percent_value,
    max_reward_amount,
    destination,
    staggered_reward_template_id,
    audience_ids,
    audience_correlation: audienceCorrelation,
    should_show,
    show_after,
    hide_offer_on_screens,
  } = values
  /* eslint-enable */

  const body = {
    name,
    coupon_code,
    title,
    subtitle,
    tags,
    ftu_title,
    ftu_subtitle,
    icon_url,
    details,
    offer_type,
    budget_type,
    budget_value,
    p_max_attempt_per_day,
    p_max_attempt_overall,
    p_subsequent_use_interval_minutes,
    t1,
    t2,
    t3,
    t4,
    trigger: {
      min_amount,
      max_amount,
    },
    rewards: [
      {
        reward_type,
        cash_reward: {
          percent_value,
          max_amount: max_reward_amount,
          destination,
        },
        staggered_reward: {
          template_id: staggered_reward_template_id,
        },
      },
    ],
    audience_ids,
    audience_correlation: parseInt(audienceCorrelation as string, 10),
    offer_valid_text_config: {
      should_show,
      show_after,
    },
    created_by: user.email,
    hide_offer_on_screens,
  }

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: body,
  })
    .then(() => {
      return {
        success: true,
        error: '',
      }
    })
    .catch(errorResponse => {
      console.error(errorResponse)
      const error = errorResponse.response?.data?.msg || 'Error creating offer!'
      return {
        success: false,
        error,
      }
    })
}

export const unpublishOffer = async (
  offerId: string,
  reason: string
): Promise<ApiReturnValue> => {
  const apiEndpoint = `${baseUrl}/UnpublishOffer`
  const body = {
    offer_id: offerId,
    reason,
  }

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: body,
  })
    .then(() => {
      return {
        success: true,
        error: '',
      }
    })
    .catch(errorResponse => {
      console.error(errorResponse)
      const error = errorResponse.response.data?.msg
      return {
        success: false,
        error,
      }
    })
}

export const getOfferScreenNames = async (): Promise<ApiReturnValue> => {
  const apiEndpoint = `${baseUrl}/GetOfferScreenNames`
  const body = {}

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: body,
  })
    .then(response => {
      const { screens } = response.data
      return {
        screens,
        success: true,
        error: '',
      }
    })
    .catch(errorResponse => {
      console.error(errorResponse)
      const error = errorResponse?.response?.data?.msg
      return {
        success: false,
        error,
      }
    })
}
