import firebase from 'firebase/app'
import { useState, useEffect } from 'react'

const useSyncFirestoreDocument = <T>(docPath: string): T | null => {
  const [doc, setDoc] = useState<T | null>(null)

  useEffect(() => {
    firebase
      .firestore()
      .doc(docPath)
      .onSnapshot(snap => {
        setDoc(snap.data() as T)
      })
  }, [docPath])

  return doc
}

export default useSyncFirestoreDocument
