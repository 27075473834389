import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './RefundDetails.module.css'
import {
  syncIntercomRefunds,
  cancelIntercomRefundsSync,
} from '../../../../../../actions/watson/player/orders/orders'
import { AppState } from '../../../../../../reducers/reducers'

const RefundDetails: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { intercomRefunds } = useSelector(
    (state: AppState) => state.watson.player.orders
  )
  const sortedOrders = Object.values(intercomRefunds)
    .filter(o => o.Category === 'Reward')
    .sort((a, b) => {
      if (a.CreatedAt > b.CreatedAt) return -1
      if (a.CreatedAt < b.CreatedAt) return 1
      return 0
    })

  // calculate lifetime intercom rewards
  let intercomRewards = 0
  sortedOrders.forEach(o => {
    if (
      o.Product.RewardProps?.RewardType === 'INTERCOM' &&
      o.State === 'Complete'
    ) {
      intercomRewards += o.Fulfillments[0].Payment?.Amount || 0
    }
  })

  useEffect(() => {
    if (playerId) dispatch(syncIntercomRefunds(playerId))

    return (): void => {
      dispatch(cancelIntercomRefundsSync())
    }
  }, [playerId, dispatch])

  return (
    <div className={styles['refund-details']}>
      <div className={styles.title}>Refund Details</div>

      <div className={`${styles['list-item']} ${styles.first}`}>
        <div className={styles.key}>CASH/GEM REFUNDS</div>
        <div className={styles.value}>{`₹${intercomRewards}`}</div>
      </div>

      <div className={styles['list-item']}>
        <div className={styles.key}>PASS REFUNDS</div>
        <div className={styles.value}>-</div>
      </div>
    </div>
  )
}

export default RefundDetails
