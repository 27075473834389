import { call, put, takeLatest } from 'redux-saga/effects'
import { DocumentData } from '@firebase/firestore-types'
import { message } from 'antd'
import rsf from '../../../../firebase'
import { Profile } from '../../../../reducers/watson/player/profile/profile.types'
import {
  setLoadingProfile,
  storeProfile,
  resetPlayer,
} from '../../../../actions/watson/player/profile/profile'
import { GetProfile } from '../../../../actions/watson/player/profile/profile.types'
import { GET_PROFILE } from '../../../../actions/watson/player/profile/ActionTypes'

function* getProfile(
  action: GetProfile
): Generator<object, void, DocumentData> {
  // reset player reducers
  yield put(resetPlayer())

  yield put(setLoadingProfile())

  const { playerId, history, messageKey } = action.payload

  const snapshot: DocumentData = yield call(
    rsf.firestore.getDocument,
    `players/${playerId}/private/profile`
    // `players/${playerId}`
  )

  const profile: Profile = { ...snapshot.data() }

  if (!profile.PlayerId) {
    const error = new Error(
      `User "${playerId}" not found! Please enter correct details.`
    )
    yield put(storeProfile(error))

    if (messageKey)
      message.error({
        content: error.message,
        key: messageKey,
      })
  } else {
    yield put(storeProfile(profile))
    const { pathname } = history.location
    history.push(
      `/watson/player-details/${playerId}${
        pathname.includes('kyc') ? `?from=${pathname}` : ''
      }`
    )

    if (messageKey)
      message.success({
        content: `Loaded profile for "${playerId}"`,
        key: messageKey,
      })
  }
}

export default function* watchGetProfile(): Generator {
  yield takeLatest(GET_PROFILE, getProfile)
}
