import {
  SetLoadingPayoutProfile,
  SyncPayoutProfile,
  CancelPayoutProfileSync,
  StorePayoutProfile,
} from './payoutProfile.types'
import { SET_LOADING, SYNC, CANCEL_SYNC, STORE } from './ActionTypes'
import { PayoutProfile } from '../../../../reducers/watson/player/payoutProfile/payoutProfile.types'

export const setLoadingPayoutProfile = (): SetLoadingPayoutProfile => ({
  type: SET_LOADING,
  payload: {},
})

export const syncPayoutProfile = (playerId: string): SyncPayoutProfile => ({
  type: SYNC,
  payload: { playerId },
})

export const cancelPayoutProfileSync = (): CancelPayoutProfileSync => ({
  type: CANCEL_SYNC,
  payload: {},
})

export const storePayoutProfile = (
  payoutProfile: PayoutProfile
): StorePayoutProfile => ({
  type: STORE,
  payload: payoutProfile instanceof Error ? payoutProfile : { payoutProfile },
  ...(payoutProfile instanceof Error && { error: true }),
})
