import React from 'react'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual } from 'react-redux'
import styles from './PlayerKyc.module.css'
import { AppState } from '../../../../../reducers/reducers'
import Row from './Row'

const selectKyc = createSelector(
  (state: AppState) => state.watson.player.kyc,
  kyc => kyc
)

const Kyc: React.FC = () => {
  const { orders } = useSelector(selectKyc, shallowEqual)
  const panOrder = orders
    .filter(order => order.Product.KycDocumentType === 'PAN')
    .sort((o1, o2) => {
      if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
      if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
      return 0
    })[0]
  const otherOrder = orders
    .filter(order => order.Product.KycDocumentType !== 'PAN')
    .sort((o1, o2) => {
      if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
      if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
      return 0
    })[0]

  return (
    <div id={styles.kyc}>
      <div className={styles.head} />
      <div className={styles.head}>DOCUMENT TYPE</div>
      <div className={styles.head}>USER UPLOAD COUNT</div>
      <div className={styles.head}>DEFAULT UPLOAD LIMIT (A)</div>
      <div className={styles.head}>WILD UPLOAD LIMIT (B)</div>
      <div className={styles.head}>TOTAL UPLOAD LIMIT (A + B)</div>
      <div className={styles.head}>STATUS</div>

      {panOrder && <Row order={panOrder} />}
      {otherOrder && <Row order={otherOrder} />}

      {orders.length === 0 && (
        <div className={styles['no-kyc']}>Kyc Not Uploaded!</div>
      )}
    </div>
  )
}

export default Kyc
