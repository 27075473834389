import {
  LoadingPasses,
  SyncPasses,
  CancelPassesSync,
  StorePasses,
} from './passes.types'
import {
  LOADING_PASSES,
  SYNC_PASSES,
  CANCEL_PASSES_SYNC,
  STORE_PASSES,
} from './ActionTypes'
import { PassDetails } from '../../../../reducers/watson/player/passes/passes.types'

export const loadingPasses = (): LoadingPasses => ({
  type: LOADING_PASSES,
  payload: {},
})

export const syncPasses = (playerId: string): SyncPasses => ({
  type: SYNC_PASSES,
  payload: { playerId },
})

export const cancelPassesSync = (): CancelPassesSync => ({
  type: CANCEL_PASSES_SYNC,
  payload: {},
})

export const storePasses = (passes: PassDetails[] | Error): StorePasses => ({
  type: STORE_PASSES,
  payload: passes instanceof Error ? passes : { passes },
  ...(passes instanceof Error && { error: true }),
})
