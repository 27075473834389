import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useDetectScrollBottom from '../../../hooks/useDetectScrollBottom'
import { getOrders } from '../../../actions/watson/deposit/deposit'
import { OrderState } from '../../../reducers/watson/deposit/deposit.types'

const usePagination = (orderState: OrderState, filtered: boolean): void => {
  const dispatch = useDispatch()
  const isAtBottom = useDetectScrollBottom(50)
  const [dispatchedGetOrders, setDispatchedGetOrders] = useState(false)

  useEffect(() => {
    if (
      isAtBottom &&
      !dispatchedGetOrders &&
      // all pending orders already synced
      orderState !== 'Processing' &&
      !filtered
    ) {
      dispatch(getOrders(orderState))
      setDispatchedGetOrders(true)
    } else if (!isAtBottom) {
      setDispatchedGetOrders(false)
    }
  }, [dispatch, isAtBottom, dispatchedGetOrders, orderState, filtered])
}

export default usePagination
