import React, { MouseEvent, useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { message, Button, Spin, Tag } from 'antd'
import styles from './OrdersTable.module.css'
import { OrderState } from '../../../reducers/watson/deposit/deposit.types'
import { getProfile } from '../../../actions/watson/player/profile/profile'
import usePagination from './usePagination'
import FirestoreFilter, {
  Filter,
  SelectedFilters,
} from '../../../components/FirestoreFilter/FirestoreFilter'
import { filterOrders } from '../../../actions/watson/deposit/deposit'
import { AppState } from '../../../reducers/reducers'

interface Props {
  selectedOrderState: OrderState
}

const stateMap: { [key in OrderState]: string } = {
  Processing: 'Pending',
  Complete: 'Accepted',
  Failed: 'Rejected',
}

const OrdersTable: React.FC<Props> = props => {
  const { selectedOrderState } = props
  const { meta, orders, filteredOrders } = useSelector(
    (state: AppState) => state.watson.deposit
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const messageKey = 'profileLoading'
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({})

  const dispalyOrders =
    Object.keys(selectedFilters).length > 0
      ? filteredOrders
      : Object.values(orders)

  usePagination(selectedOrderState, Object.keys(selectedFilters).length > 0)

  const filters: Filter[] = [
    {
      field: 'PlayerId',
      title: 'Player ID',
      filterType: 'input',
    },
    {
      field: 'CreatedAt',
      title: 'Date',
      filterType: 'dateRange',
    },
  ]

  const applyFilters = (sf: SelectedFilters): void => {
    setSelectedFilters(sf)
  }

  const toggleFilterVisibility = (): void => {
    setFiltersVisible(!filtersVisible)
  }

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      dispatch(filterOrders(selectedFilters))
    }
  }, [selectedFilters, dispatch])

  return (
    <Spin
      tip={`Loading ${stateMap[selectedOrderState]} deposit orders...`}
      spinning={meta[selectedOrderState].loading}
    >
      <div id={styles['orders-table']}>
        <div className={styles.header}>
          <div className={styles.title}>
            {`${stateMap[selectedOrderState]} Deposit Orders`}
          </div>
          <Button type="link" size="large" onClick={toggleFilterVisibility}>
            {!filtersVisible ? <>View Filter</> : <>Hide Filter</>}
          </Button>
        </div>

        {filtersVisible && (
          <div className={styles.filters}>
            <FirestoreFilter
              filters={filters}
              applyFilter={applyFilters}
              selectedFilter={selectedFilters}
            />
          </div>
        )}

        {Object.keys(selectedFilters).length > 0 && (
          <div className={styles['applied-filters']}>
            {Object.keys(selectedFilters).map(key => {
              return (
                <Tag
                  key={key}
                  color="#2db7f5"
                  closable
                  onClose={(): void => {
                    const sf = { ...selectedFilters }
                    Reflect.deleteProperty(sf, key)
                    setSelectedFilters(sf)
                  }}
                >
                  <span className={styles['filter-key']}>
                    {filters.find(f => f.field === key)?.title || key}
                  </span>
                  <span>: </span>
                  <span className={styles['filter-value']}>
                    {`${
                      typeof selectedFilters[key] === 'string'
                        ? selectedFilters[key]
                        : `${moment(selectedFilters[key][0]).format(
                            'll'
                          )} to ${moment(selectedFilters[key][1]).format('ll')}`
                    }`}
                  </span>
                </Tag>
              )
            })}
          </div>
        )}

        <div className={`${styles.head} ${styles.first}`}>PLAYER ID</div>
        <div className={styles.head}>ORDER ID</div>
        <div className={styles.head}>PgTxnID</div>
        <div className={styles.head}>Time</div>
        <div className={styles.head}>PG</div>
        <div className={styles.head}>Delay</div>
        <div className={styles.head}>Refund Status</div>

        {dispalyOrders
          .filter(o => o.State === selectedOrderState)
          .sort((o1, o2) => {
            if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
            if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
            return 0
          })
          .map(o => {
            return (
              <React.Fragment key={o.id}>
                <div className={styles.value}>
                  <Button
                    className={styles['player-btn']}
                    type="link"
                    onClick={(e: MouseEvent<HTMLElement>): void => {
                      const pid = e.currentTarget.innerText
                      dispatch(getProfile(pid, history, messageKey))

                      message.loading({
                        content: `Loading profile for "${pid}"`,
                        key: messageKey,
                      })
                    }}
                  >
                    {o.PlayerId}
                  </Button>
                </div>
                <div className={styles.value}>{o.id}</div>
                <div className={styles.value}>
                  {o.Payments == null ? '' : o.Payments[0].PgTxnID}
                </div>
                <div className={`${styles.timestamp} ${styles.value}`}>
                  <div>{moment(o.CreatedAt.seconds * 1000).format('ll')}</div>
                  <div>
                    {moment(o.CreatedAt.seconds * 1000).format('hh:mm A')}
                  </div>
                </div>
                <div className={styles.value}>
                  {o.Payments == null ? '' : o.Payments[0].PG}
                </div>
                <div className={`${styles.timestamp} ${styles.value}`}>
                  {moment(o.CreatedAt.seconds * 1000).fromNow()}
                </div>
                <div className={styles.value}>
                  {o.Refunds == null
                    ? 'N/A'
                    : `${o.Refunds[0].State} - ${o.Refunds[0].PgTxnID}`}
                </div>
              </React.Fragment>
            )
          })}

        {(meta[selectedOrderState].lastPage ||
          Object.keys(selectedFilters).length > 0) && (
          <div className={styles.lastPage}>No more orders!</div>
        )}
      </div>
    </Spin>
  )
}

export default OrdersTable
