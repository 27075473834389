import { Payout, PayoutOrdersMap, PayoutOrder } from './payout.types'
import {
  STORE_ORDERS,
  SET_LOADING_PAYOUT_ORDERS,
  STORE_FILTERED_ORDERS,
} from '../../../actions/watson/payout/ActionTypes'
import {
  PayoutActions,
  StoreOrders,
  StoreOrdersPayload,
  SetLoadingPayoutOrders,
  StoreFilteredOrders,
  StoreFilteredOrdersPayload,
} from '../../../actions/watson/payout/payout.types'

const initialState: Payout = {
  orders: {},
  error: {},
  meta: {
    Processing: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    Complete: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    Failed: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
  },
  filteredOrders: [],
}

export const getMapFromArray = (
  arr: PayoutOrder[],
  ordersMap: PayoutOrdersMap = {}
): PayoutOrdersMap => {
  return arr.reduce((om: PayoutOrdersMap, item) => {
    const map = om
    map[item.id] = item

    return map
  }, ordersMap)
}

const payoutReducer = (state = initialState, action: PayoutActions): Payout => {
  switch (action.type) {
    case SET_LOADING_PAYOUT_ORDERS: {
      const { orderState } = (action as SetLoadingPayoutOrders).payload

      return {
        ...state,
        meta: {
          ...state.meta,
          [orderState]: {
            ...state.meta[orderState],
            loading: true,
          },
        },
      }
    }

    case STORE_ORDERS: {
      const { payload, error, meta } = action as StoreOrders
      const { orderState, lastPage, lastDoc } = meta

      if (!error) {
        const { orders } = payload as StoreOrdersPayload
        let ordersMap: PayoutOrdersMap = state.orders
        if (orderState === 'Processing') {
          ordersMap = Object.values(state.orders).reduce(
            (prevValue: PayoutOrdersMap, order): PayoutOrdersMap => {
              const om = prevValue
              if (order.State !== 'Processing') {
                om[order.id] = order
              }
              return om
            },
            {}
          )
        }

        return {
          ...state,
          orders: getMapFromArray(orders, { ...ordersMap }),
          meta: {
            ...state.meta,
            [orderState]: {
              ...state.meta[orderState],
              loading: false,
              lastPage,
              lastDoc,
            },
          },
        }
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          [orderState]: {
            ...state.meta[orderState],
            loading: false,
          },
        },
      }
    }

    case STORE_FILTERED_ORDERS: {
      const { payload, error } = action as StoreFilteredOrders

      if (!error) {
        const { filteredOrders } = payload as StoreFilteredOrdersPayload

        return {
          ...state,
          filteredOrders,
          meta: {
            ...state.meta,
            Processing: {
              ...state.meta.Processing,
              loading: false,
            },
            Complete: { ...state.meta.Complete, loading: false },
            Failed: { ...state.meta.Failed, loading: false },
          },
        }
      }

      return state
    }

    default:
      return state
  }
}

export default payoutReducer
