import { SetLoadingAddress, GetAddress, StoreAddress } from './address.types'
import { SET_LOADING_ADDRESS, GET_ADDRESS, STORE_ADDRESS } from './ActionTypes'
import { Address } from '../../../../reducers/watson/player/address/address.types'

export const setLoadingAddress = (): SetLoadingAddress => ({
  type: SET_LOADING_ADDRESS,
  payload: {},
})

export const getAddress = (playerId: string): GetAddress => ({
  type: GET_ADDRESS,
  payload: { playerId },
})

export const storeAddress = (addresses: Address[] | Error): StoreAddress => ({
  type: STORE_ADDRESS,
  payload: addresses instanceof Error ? addresses : { addresses },
  ...(addresses instanceof Error && { error: true }),
})
