import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './CfSession.module.css'
import Sidebar from './Sidebar'
import OrdersTable from './OrdersTable'
import { AppState } from '../../../reducers/reducers'
import { CfSessionState } from '../../../reducers/watson/cfSession/cfSession.types'
import useSyncCfSessions from './useSyncCfSessions'
import { cancelSessionsSync } from '../../../actions/watson/cfSession/cfSession'

const CfSession: React.FC = () => {
  const [selectedSessionState, setSelectedSessionState] =
    useState<CfSessionState>('Active')

  const { sessions } = useSelector((state: AppState) => state.watson.cf)
  const pendingSessionsCount = Object.values(sessions).reduce(
    (count: number, o) => count + (o.State === 'Active' ? 1 : 0),
    0
  )

  const dispatch = useDispatch()
  useSyncCfSessions()

  const pause = () => {
    dispatch(cancelSessionsSync())
  }
  return (
    <div id={styles.payout} className="loader-container">
      <Sidebar
        selectedSessionState={selectedSessionState}
        setSelectedSessionState={setSelectedSessionState}
        pendingSessionsCount={pendingSessionsCount}
      />
      <OrdersTable
        selectedSessionState={selectedSessionState}
        pauseSync={pause}
      />
    </div>
  )
}

export default CfSession
