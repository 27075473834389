import React from 'react'

import styles from './Content.module.css'
import menuItems from '../menuItems'

interface Props {
  selectedMenuItem: string
}

const Content: React.FC<Props> = props => {
  const { selectedMenuItem } = props
  const Component = menuItems[selectedMenuItem].component

  return (
    <div id={styles.content}>
      {Component ? (
        <Component />
      ) : (
        <div className={styles['coming-soon']}>
          {`${menuItems[selectedMenuItem].title} is coming soon`}
        </div>
      )}
    </div>
  )
}

export default Content
