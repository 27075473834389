import React from 'react'
import CompleteIcon from '../../../../../assets/success.svg'
import PendingIcon from '../../../../../assets/exclamatory.svg'
import FailedIcon from '../../../../../assets/cancelled.svg'

interface Props {
  state: string
}

const stateIcon: { [index: string]: string } = {
  Complete: CompleteIcon,
  Pending: PendingIcon,
  Processing: PendingIcon,
  Failed: FailedIcon,
  Manual_Supervision: PendingIcon,
}

const OrderState: React.FC<Props> = ({ state }) => {
  return (
    <>
      <img
        src={stateIcon[state]}
        alt="state icon"
        style={{ marginRight: '4px' }}
      />
      {state.replace('_', ' ')}
    </>
  )
}

export default OrderState
