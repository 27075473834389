import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'antd'
import { createSelector } from 'reselect'
import { shallowEqual, useSelector } from 'react-redux'
import { DocumentSnapshot } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import styles from './PlayerSummary.module.css'
import GeneralDetails from './GeneralDetails/GeneralDetails'
import Wallets from './Wallets/Wallets'
import { AppState } from '../../../../../reducers/reducers'
import {
  FraudProfile,
  Profile,
} from '../../../../../reducers/watson/player/profile/profile.types'
import Campaign from './Campaigns/Campaigns'
import Referrals from './Referrals/Referrals'
import PlatformDetails from './PlatformDetails/PlatformDetails'
// import RecentActivity from './RecentActivity'
import TopPlayedGames from './TopPlayedGames/TopPlayedGames'
import AllActivity from '../AllActivity/AllActivity'
import RefundDetails from './RefundDetails/RefundDetails'
import BlockingDetails from './BlockingDetails/BlockingDetails'

const selectProfile = createSelector(
  (state: AppState) => state.watson.player.profile,
  profile => profile
)

const PlayerSummary: React.FC = () => {
  const playerProfile = useSelector(selectProfile, shallowEqual)
  const { profile } = playerProfile
  const [loginBlocked, setLoginBlocked] = useState(false)
  const [platformBlocked, setPlatformBlocked] = useState(false)
  const [locationBlocked, setLocationBlocked] = useState(false)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [blockReason, setBlockReason] = useState('')
  const [fraudProfile, setFraudProfile] = useState<FraudProfile | null>(null)

  useEffect(() => {
    const setPlatformStatus = async () => {
      try {
        const snap: DocumentSnapshot = await firebase
          .firestore()
          .collection(`players/${profile?.PlayerId}/private`)
          .doc('fraudProfile')
          .get()
        const data: any = snap.data()
        if (!!data.Comments && data.Comments.length > 0) {
          setBlockReason(data.Comments[0])
        }
        // if user is platform blocked, withdrawal will also be blocked
        // display that reason
        if (data.WithdrawalBlocked) {
          const comment = data.WithDrawalProps.Comment
          // WithDrawalProps.Reason is deprecated, handling for backward compatibility
          let reason = data.WithDrawalProps.Reason || 'Reason: Unknown'
          if (comment) {
            reason = `Reason: ${comment.Title}, Description: ${comment.Description}, Remark: ${comment.Remark}`
          }
          setBlockReason(reason)
        }
        setFraudProfile(data)
        setPlatformBlocked(
          data.GameplayBlocked &&
            data.DepositBlocked &&
            data.WithdrawalBlocked &&
            data.MoonshotBlocked &&
            data.RewardsBlocked &&
            !data.IsWhiteListed
        )
      } catch (error) {
        setPlatformBlocked(false)
      }
    }

    setPlatformStatus()
  }, [profile, platformBlocked])

  useEffect(() => {
    setLocationBlocked(!!profile?.RequestedFromBannedPoliticalRegion)
  }, [profile])

  return (
    <div id={styles['player-summary']} className="player-summary">
      {platformBlocked && (
        <div className={styles['block-banner']}>
          <p className={styles['block-banner-text']}>
            User is currently Platform Blocked
          </p>
          <button
            onClick={() => {
              setShowReasonModal(true)
            }}
            className={styles['block-banner-button']}
            type="button"
          >
            View Reason
          </button>
        </div>
      )}
      <Card className={`${styles['general-details']} ${styles.card}`}>
        <GeneralDetails
          profile={profile as Profile}
          loginBlocked={loginBlocked}
          setLoginBlocked={setLoginBlocked}
          platformBlocked={platformBlocked}
          setPlatformBlocked={setPlatformBlocked}
          setBlockReason={setBlockReason}
        />
      </Card>
      <Card className={`${styles.wallets} ${styles.card}`}>
        <Wallets playerId={(profile as Profile).PlayerId} />
      </Card>
      {(loginBlocked ||
        fraudProfile?.GameplayBlocked ||
        fraudProfile?.DepositBlocked ||
        fraudProfile?.WithdrawalBlocked ||
        fraudProfile?.MoonshotBlocked ||
        fraudProfile?.RewardsBlocked ||
        locationBlocked) && (
        <Card className={`${styles['blocking-details']} ${styles.card}`}>
          <BlockingDetails
            fraudProfile={fraudProfile as FraudProfile}
            isLoginBlocked={loginBlocked}
            isPlatformBlocked={platformBlocked}
            isLocationBlocked={locationBlocked}
          />
        </Card>
      )}
      <Card className={`${styles['refund-details']} ${styles.card}`}>
        <RefundDetails />
      </Card>
      <Card className={`${styles.referrals} ${styles.card}`}>
        <Referrals count={5} />
      </Card>
      <Card className={`${styles.campaign} ${styles.card}`}>
        <Campaign />
      </Card>
      <Card className={`${styles['platform-details']} ${styles.card}`}>
        <PlatformDetails />
      </Card>
      <Card className={`${styles['recent-activity']} ${styles.card}`}>
        {/* <RecentActivity /> */}
        <AllActivity count={5} title="Recent Activity Log" />
      </Card>
      <Card className={`${styles['top-5-games']} ${styles.card}`}>
        <TopPlayedGames count={5} />
      </Card>
      <Modal
        title="Platform Block Reason"
        visible={showReasonModal}
        onOk={(): void => {
          setShowReasonModal(false)
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>{blockReason}</p>
      </Modal>
    </div>
  )
}

export default PlayerSummary
