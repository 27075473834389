import React, { useState } from 'react'
import { Button, message } from 'antd'
import { useDispatch } from 'react-redux'
import styles from './CFSessions.module.css'
import { CFSession } from '../../../../../reducers/watson/player/orders/orders.types'
import MoreIcon from '../../../../../assets/more.svg'
import LessIcon from '../../../../../assets/less.svg'
import CompleteIcon from '../../../../../assets/success.svg'
import FailedIcon from '../../../../../assets/cancelled.svg'
import Details from './Details'
import { cashoutCFSession } from './api'
import { getCfSessions } from '../../../../../actions/watson/player/orders/orders'

interface Props {
  cfSession: CFSession
  playerId: string
}

const Row: React.FC<Props> = props => {
  const { cfSession, playerId } = props
  const [expanded, setExpanded] = useState(false)
  const [cashingOut, setCashingOut] = useState(false)
  const dispatch = useDispatch()

  const cashout = async (): Promise<void> => {
    setCashingOut(true)
    const err = await cashoutCFSession(cfSession.session_id)
    setCashingOut(false)

    if (!err) {
      message.success(`Cashed out ${cfSession.session_id} session`)
      dispatch(getCfSessions(playerId))
    } else {
      message.error(err)
    }
  }

  return (
    <>
      <div className={styles.td}>
        {!expanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setExpanded(true)}
          />
        )}
        {expanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setExpanded(false)}
          />
        )}
      </div>
      <div className={styles.td}>{cfSession.session_id}</div>
      <div className={styles.td}>
        {!cfSession.is_cashed_out ? (
          <Button type="primary" loading={cashingOut} onClick={cashout}>
            Cashout
          </Button>
        ) : null}
      </div>
      <div className={styles.td}>{cfSession.money_in.cashin_orders[0]}</div>
      <div className={styles.td}>{`₹${cfSession.money_in.buy_in_amount}`}</div>
      <div className={styles.td}>
        <img
          src={cfSession.is_cashed_out ? CompleteIcon : FailedIcon}
          alt="cashed out status icon"
          style={{ marginRight: '4px' }}
        />
      </div>

      {expanded && <Details cfSession={cfSession} />}
    </>
  )
}

export default Row
