import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './PlayerKyc.module.css'
import {
  KycOrder,
  KycDocumentData,
} from '../../../../../reducers/watson/kyc/kyc.types'
import MoreIcon from '../../../../../assets/more.svg'
import LessIcon from '../../../../../assets/less.svg'
import CompleteIcon from '../../../../../assets/success.svg'
import ManualSupervisionIcon from '../../../../../assets/exclamatory.svg'
import FailedIcon from '../../../../../assets/cancelled.svg'
import Details from './Details'
import { getKycDetails } from '../../../../../actions/watson/player/kyc/kyc'
import { AppState } from '../../../../../reducers/reducers'
import { KycDocumentType } from '../../../../../actions/watson/player/kyc/kyc.types'
import WildUpload from './WildUpload'

interface Props {
  order: KycOrder
}

const stateIcon: { [index: string]: string } = {
  Complete: CompleteIcon,
  Manual_Supervision: ManualSupervisionIcon,
  Failed: FailedIcon,
}

const Row: React.FC<Props> = props => {
  const { order } = props
  const documentType: KycDocumentType =
    order?.Product.KycDocumentType === 'PAN' ? 'pan' : 'other'
  const kycDetails = useSelector(
    (state: AppState) =>
      state.watson.player.kyc.kycDocumentDetails[documentType]
  )
  const error = useSelector(
    (state: AppState) =>
      state.watson.player.kyc.error.kycDocumentDetails[documentType]
  )
  const dispatch = useDispatch()
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  useEffect(() => {
    if (order.id) dispatch(getKycDetails(order.id, documentType))
  }, [dispatch, order, documentType])

  const userUploadCount =
    kycDetails?.upload_count_info?.[
      documentType === 'pan' ? 'pan' : 'other_documents'
    ].user_upload_count
  const defaultUploadLimit = parseInt(
    kycDetails?.upload_count_info?.[
      documentType === 'pan' ? 'pan' : 'other_documents'
    ].default_uploads_given || '0',
    10
  )
  const wildUploadLimit = parseInt(
    kycDetails?.upload_count_info?.[
      documentType === 'pan' ? 'pan' : 'other_documents'
    ].wild_uploads_given || '0',
    10
  )
  const total = defaultUploadLimit + wildUploadLimit

  return (
    <>
      <div className={styles.cell}>
        {!detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setDetailsExpanded(true)}
          />
        )}
        {detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setDetailsExpanded(false)}
          />
        )}
      </div>
      <div className={styles.cell}>{order.Product.KycDocumentType}</div>
      <div className={styles.cell}>{userUploadCount}</div>
      <div className={styles.cell}>{defaultUploadLimit}</div>
      <WildUpload
        playerId={order.PlayerId}
        documentType={documentType}
        kycDetails={kycDetails as KycDocumentData}
      />
      <div className={styles.cell}>{total}</div>
      <div className={`${styles.cell} ${styles[order.State]}`}>
        <img
          src={stateIcon[order.State]}
          alt="state icon"
          style={{ marginRight: '4px' }}
        />
        {order.State.replace('_', ' ')}
      </div>

      {detailsExpanded && kycDetails && (
        <div className={styles.details}>
          <Details
            kycDetails={kycDetails}
            orderId={order.id}
            documentType={order.Product.KycDocumentType}
          />
        </div>
      )}

      {error && detailsExpanded && (
        <div className={styles['kyc-details-error']}>{error.message}</div>
      )}
    </>
  )
}

export default Row
