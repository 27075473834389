import React, { useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Link, useRouteMatch } from 'react-router-dom'
import { Popover, Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import styles from './Navbar.module.css'
import { AppState } from '../../../reducers/reducers'
import DownArrow from '../../../assets/down.svg'
import { logoutRequest } from '../../../actions/auth/auth'
import getAccessToken from '../../../helpers/access-token'

const selectUser = createSelector(
  (state: AppState) => state.auth.user,
  user => user
)

const Navbar: React.FC = () => {
  const user = useSelector(selectUser, shallowEqual)
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [copied, setCopied] = useState(false)

  const MenuItems = (
    <div className={styles.MenuItems}>
      <Button
        type="link"
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={async e => {
          e.stopPropagation()
          e.preventDefault()

          const authToken = await getAccessToken()

          navigator.clipboard
            .writeText(authToken)
            .then(() => {
              setCopied(true)
              setTimeout(() => {
                setCopied(false)
              }, 3000)
            })
            .catch(err => {
              console.error('Error copying to clipboard', err)
            })
        }}
      >
        <span>Copy Auth Token</span>

        <span style={{ paddingLeft: '8px', color: 'green', width: '24px' }}>
          {copied ? <CheckOutlined /> : null}
        </span>
      </Button>
      <Button
        type="link"
        onClick={(): void => {
          dispatch(logoutRequest())
        }}
      >
        Logout
      </Button>
    </div>
  )

  return (
    <div id={styles.navbar}>
      <div className={styles.left}>
        {!match.isExact && <Link to="/watson">Home</Link>}
      </div>
      <div className={styles.right}>
        <Popover content={MenuItems} trigger="click" placement="bottomRight">
          <div className={styles.user}>
            <img
              src={user?.photoURL}
              alt="user display"
              className={styles['user-dp']}
            />
            {user?.email}
            <img src={DownArrow} alt="down" className={styles['down-arrow']} />
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default Navbar
