import React, { useState } from 'react'
import { Button, Popconfirm, message } from 'antd'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import styles from './PlayerKyc.module.css'
import { KycDocumentType } from '../../../../../actions/watson/player/kyc/kyc.types'
import { consoleApiBaseUrl } from '../../../../../config'
import { storeKycDetails } from '../../../../../actions/watson/player/kyc/kyc'
import { KycDocumentData } from '../../../../../reducers/watson/kyc/kyc.types'
import getAccessToken from '../../../../../helpers/access-token'

interface Props {
  documentType: KycDocumentType
  playerId: string
  kycDetails: KycDocumentData
}

const WildUpload: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const { documentType, playerId, kycDetails } = props
  const [incrementingLimit, setIncrementingLimit] = useState(false)
  const docKey = documentType === 'pan' ? 'pan' : 'other_documents'

  const incrementLimit = async (): Promise<void> => {
    setIncrementingLimit(true)

    const apiEndpoint = `${consoleApiBaseUrl}/twirp/pb.Watson/ProvideWildUploads`

    await axios(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${await getAccessToken()}`,
      },
      data: {
        playerId,
        pan: documentType === 'pan' ? 1 : 0,
        other_document: documentType === 'other' ? 1 : 0,
      },
    })
      .then(response => {
        dispatch(
          storeKycDetails({ ...kycDetails, ...response.data }, documentType)
        )
        message.success('Incremented wild upload limit')
      })
      .catch(error => {
        console.error(error)
        message.error('Error incrementing wild upload limit')
      })

    setIncrementingLimit(false)
  }

  return (
    <div className={styles['wild-upload']}>
      <div>{kycDetails?.upload_count_info?.[docKey].wild_uploads_given}</div>
      <Popconfirm
        title="Are you sure you want to increment wild upload limit?"
        onConfirm={incrementLimit}
        okText="Yes"
        cancelText="No"
      >
        <Button type="link" size="small" loading={incrementingLimit}>
          Add Limit
        </Button>
      </Popconfirm>
    </div>
  )
}

export default WildUpload
