import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useDetectScrollBottom from '../../../hooks/useDetectScrollBottom'
import { getSessions } from '../../../actions/watson/cfSession/cfSession'
import { CfSessionState } from '../../../reducers/watson/cfSession/cfSession.types'

const usePagination = (
  sessionState: CfSessionState,
  filtered: boolean
): void => {
  const dispatch = useDispatch()
  const isAtBottom = useDetectScrollBottom(50)
  const [dispatchedGetSessions, setDispatchedGetSessions] = useState(false)

  useEffect(() => {
    if (
      isAtBottom &&
      !dispatchedGetSessions &&
      // all pending orders already synced
      sessionState !== 'Active' &&
      !filtered
    ) {
      dispatch(getSessions(sessionState))
      setDispatchedGetSessions(true)
    } else if (!isAtBottom) {
      setDispatchedGetSessions(false)
    }
  }, [dispatch, isAtBottom, dispatchedGetSessions, sessionState, filtered])
}

export default usePagination
