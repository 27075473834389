import axios from 'axios'
import { consoleApiBaseUrl } from '../../../../../config'
import getAccessToken from '../../../../../helpers/access-token'

// eslint-disable-next-line import/prefer-default-export
export const cashoutCFSession = async (
  cfSessionId: string
): Promise<string> => {
  const apiEndpoint = `${consoleApiBaseUrl}/twirp/pb.WatsonCfManager/CloseActiveCfSession`
  const data = {
    session_ref: cfSessionId,
  }

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(response => {
      return ''
    })
    .catch(err => {
      console.error(err)
      return 'Error cashing out session'
    })
}
