const prefix = '@@watson/@@cfSession'

export const SET_LOADING_CF_SESSIONS = `${prefix}/SET_LOADING_CF_SESSIONS`

export const SYNC_SESSIONS = `${prefix}/SYNC_SESSIONS`
export const CANCEL_SESSIONS_SYNC = `${prefix}/CANCEL_SESSIONS_SYNC`

export const STORE_SESSIONS = `${prefix}/STORE_SESSIONS`
// export const RESET_ORDERS = `${prefix}/RESET_ORDERS`
export const GET_SESSIONS = `${prefix}/GET_SESSIONS`

export const FILTER_SESSIONS = `${prefix}/FILTER_SESSIONS`
export const STORE_FILTERED_SESSIONS = `${prefix}/STORE_FILTERED_SESSIONS`
