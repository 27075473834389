import {
  LOADING_REFERRALS,
  SYNC_REFERRALS,
  CANCEL_REFERRALS_SYNC,
  STORE_REFERRALS,
} from './ActionTypes'
import {
  SyncReferrals,
  CancelReferralsSync,
  StoreReferrals,
  LoadingReferrals,
} from './referrals.types'
import { Referral } from '../../../../reducers/watson/player/referrals/referrals.types'

export const loadingReferrals = (): LoadingReferrals => ({
  type: LOADING_REFERRALS,
  payload: {},
})

export const syncReferrals = (playerId: string): SyncReferrals => ({
  type: SYNC_REFERRALS,
  payload: { playerId },
})

export const cancelReferralsSync = (): CancelReferralsSync => ({
  type: CANCEL_REFERRALS_SYNC,
  payload: {},
})

export const storeReferrals = (referrals: Referral[]): StoreReferrals => ({
  type: STORE_REFERRALS,
  payload: referrals instanceof Error ? referrals : { referrals },
  ...(referrals instanceof Error && { error: true }),
})
