import React from 'react'
import styles from './StaggeredRewads.module.css'
import StaggeredTemplateList from './StaggeredTemplateList'

const StaggeredRewards: React.FC = () => {
  return (
    <div id={styles.staggeredRewards} className="loader-container">
      <StaggeredTemplateList />
    </div>
  )
}

export default StaggeredRewards
