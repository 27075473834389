import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useDetectScrollBottom from '../../../../../hooks/useDetectScrollBottom'
import { getAllOrders } from '../../../../../actions/watson/player/orders/orders'
import { Meta } from '../../../../../reducers/watson/player/orders/orders.types'

const usePagination = (playerId: string, meta: Meta): void => {
  const dispatch = useDispatch()
  const isAtBottom = useDetectScrollBottom(50)
  const [dispatchedGetOrders, setDispatchedGetOrders] = useState(false)

  useEffect(() => {
    if (isAtBottom && !meta.loading && !meta.lastPage && !dispatchedGetOrders) {
      dispatch(getAllOrders(playerId as string, 'allOrders'))
      setDispatchedGetOrders(true)
    } else if (!isAtBottom) {
      setDispatchedGetOrders(false)
    }
  }, [dispatch, isAtBottom, playerId, meta, dispatchedGetOrders])
}

export default usePagination
