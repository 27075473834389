import { ProfileReducer } from './profile.types'
import {
  ProfileAction,
  StoreProfile,
  StoreProfilePayload,
} from '../../../../actions/watson/player/profile/profile.types'
import {
  SET_LOADING_PROFILE,
  STORE_PROFILE,
  RESET_PLAYER,
} from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: ProfileReducer = {
  profile: null,
  error: null,
  meta: {
    loading: false,
  },
}

const profileReducer = (
  state = initialState,
  action: ProfileAction
): ProfileReducer => {
  switch (action.type) {
    case SET_LOADING_PROFILE: {
      return {
        profile: null,
        error: null,
        meta: { loading: true },
      }
    }

    case STORE_PROFILE: {
      const { payload, error } = action as StoreProfile

      if (!error) {
        const { profile } = payload as StoreProfilePayload
        return {
          ...state,
          profile,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        ...state,
        profile: null,
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default profileReducer
