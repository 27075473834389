import Axios from 'axios'
import getAccessToken from '../../../../../../helpers/access-token'

const getReferralRewardCap = async (playerId: string): Promise<number> => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/consoleapi.pb.SocialReferral/PlayerReferralRewardCap`
  const data = {
    player_id: playerId,
  }

  return Axios(url, {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(response => {
      const { amount = 0 } = response?.data
      return amount
    })
    .catch(err => {
      console.error(err)
      return 0
    })
}

export default getReferralRewardCap
