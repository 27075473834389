import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Popconfirm, Select } from "antd";
import { createSelector } from "reselect";
import { shallowEqual, useSelector } from "react-redux";
import styles from "./BlockFlagReward.module.css";
import { AppState } from "../../../../../../../reducers/reducers";
import { blockPlatformActions, blockPlayer, playerStatus, unbBockPlatformActions, unblockPlayer } from "./api";
import ProcessCustomerSupportReward from "../../../ProcessCustomerSupportReward/ProcessCustomerSupportReward";
import styles2 from "../../../Collusion/CollusionRow.module.css";
import { BlockReasonDescriptionMapping } from "../../../Collusion/api";

interface Props {
  playerId: string
  loginBlocked: boolean
  setLoginBlocked: Function
  platformBlocked: boolean
  setPlatformBlocked: Function
  setBlockReason: Function
}

const selectUser = createSelector(
  (state: AppState) => state.auth,
  auth => auth
)

const BlockFlagReward: React.FC<Props> = props => {
  const {
    playerId,
    loginBlocked,
    setLoginBlocked,
    platformBlocked: propPlatformBlocked,
    setBlockReason,
  } = props
  const { accessToken } = useSelector(selectUser, shallowEqual)
  const { user } = useSelector((state: AppState) => state.auth)
  const [loading, setLoading] = useState(false)
  const [rewardModalVisible, setRewardModalVisible] = useState(false)
  const [platformBlocked, setPlatformBlocked] = useState(false)
  const [showBlockConfirmationModal, setShowBlockConfirmationModal] =
    useState(false)
  const [reason, setReason] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const { Option } = Select

  useEffect(() => {
    setPlatformBlocked(propPlatformBlocked)
  }, [propPlatformBlocked])

  const block = async (): Promise<void> => {
    setLoading(true)
    const status = await blockPlayer(playerId)
    if (status) {
      setLoginBlocked(true)
      message.success('blocked player')
    } else {
      message.error('error blocking player')
    }
    setLoading(false)
  }

  useEffect(() => {
    const setPlayerStatus = async () => {
      const status = await playerStatus(playerId)
      if (status === 'enabled') {
        setLoginBlocked(false)
      } else {
        setLoginBlocked(true)
      }
    }
    setPlayerStatus()
  }, [setLoginBlocked, playerId])

  const unblock = async (): Promise<void> => {
    setLoading(true)
    const status = await unblockPlayer(playerId)
    if (status) {
      setLoginBlocked(false)
      message.success('unblocked player')
    } else {
      message.error('error unblocking player')
    }
    setLoading(false)
  }

  const blockUserActions = async (): Promise<void> => {
    setLoading(true)
    setShowBlockConfirmationModal(false)
    const status = await blockPlatformActions(
      playerId,
      accessToken || '',
      {
        // withdrawal: true,
        // deposit: true,
        // gameplay: true,
        // moonshot: true,
        // rewards: true,
        account: true,
      },
      {
        title,
        description,
        remark: reason,
      },
      user?.email || '',
      ''
    )
    if (status) {
      message.success('blocked player')
      props.setPlatformBlocked(true)
      setBlockReason(reason)
      setReason('')
    } else {
      message.error('error blocking player')
    }
    setLoading(false)
  }

  const unBlockUserActions = async (): Promise<void> => {
    setLoading(true)
    const status = await unbBockPlatformActions(
      playerId,
      accessToken || '',
      {
        // withdrawal: true,
        // deposit: true,
        // gameplay: true,
        // moonshot: true,
        // rewards: true,
        account: true,
      },
      user?.email || '',
      ''
    )
    if (status) {
      props.setPlatformBlocked(false)
      message.success('unblocked player')
    } else {
      message.error('error unblocking player')
    }
    setLoading(false)
  }

  return (
    <div id={styles['block-and-flag']}>
      {loginBlocked ? (
        <PopupBlockButton
          onConfirm={unblock}
          loading={loading}
          popupTitle="Are you sure you want to unblock this player from platform?"
          buttonName="Login Unblock"
          blocked={loginBlocked}
        />
      ) : (
        <PopupBlockButton
          onConfirm={block}
          loading={loading}
          popupTitle="Are you sure you want to block this player from platform?"
          buttonName="Login Block"
          blocked={loginBlocked}
        />
      )}

      {platformBlocked ? (
        <PopupBlockButton
          onConfirm={unBlockUserActions}
          loading={loading}
          popupTitle="Are you sure you want to unblock this player from platform?"
          buttonName="Platform UnBlock"
          blocked={platformBlocked}
        />
      ) : (
        <Button
          type="primary"
          className={styles['block-btn']}
          danger
          loading={loading}
          onClick={() => {
            setShowBlockConfirmationModal(true)
          }}
        >
          Platform Block
        </Button>
      )}

      <Button disabled className={styles['flag-btn']}>
        Flag
      </Button>
      <Button
        type="primary"
        className={styles['reward-btn']}
        onClick={(): void => {
          setRewardModalVisible(true)
        }}
      >
        Reward
      </Button>

      <ProcessCustomerSupportReward
        rewardType="GENERAL"
        visible={rewardModalVisible}
        setRewardModalVisible={setRewardModalVisible}
      />

      <Modal
        title="Platform Block Confirmation"
        visible={showBlockConfirmationModal}
        onOk={blockUserActions}
        width={600}
        okButtonProps={{ disabled: title === '' || description === '' }}
        onCancel={(): void => {
          setShowBlockConfirmationModal(false)
        }}
      >
        <div className={styles2.modalH}>Reason</div>
        <Select
          className={styles2.selectTitle}
          onChange={(value: string) => {
            setTitle(value)
            setDescription('')
          }}
        >
          {Object.keys(BlockReasonDescriptionMapping).map(a => (
            <Option key={a} value={a}>
              {a}
            </Option>
          ))}
        </Select>
        {!title && <div style={{ color: 'red' }}>Required!</div>}
        <div className={`${styles2.modalH} ${styles2.sectionDivider}`}>
          Description
        </div>
        <Select
          className={styles2.selectDesc}
          disabled={!title}
          onChange={(value: string) => setDescription(value)}
        >
          {BlockReasonDescriptionMapping[title]?.map(a => (
            <Option key={a} value={a}>
              {a}
            </Option>
          ))}
        </Select>
        {!description && <div style={{ color: 'red' }}>Required!</div>}
        <div className={`${styles2.modalH} ${styles2.sectionDivider}`}>
          Remark
        </div>
        <Input.TextArea
          placeholder="Remark"
          value={reason}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setReason(e.currentTarget.value)
          }}
        />
      </Modal>
    </div>
  )
}

interface PopupProps {
  onConfirm: () => Promise<void>
  loading: boolean
  popupTitle: string
  buttonName: string
  blocked: boolean
}

const PopupBlockButton: React.FC<PopupProps> = ({
  onConfirm,
  loading,
  blocked,
  popupTitle,
  buttonName,
}: PopupProps) => {
  return (
    <Popconfirm
      title={popupTitle}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="No"
    >
      <Button
        type="primary"
        danger={!blocked}
        className={styles['block-btn']}
        loading={loading}
      >
        {buttonName}
      </Button>
    </Popconfirm>
  )
}

export default BlockFlagReward
