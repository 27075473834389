import { combineReducers } from 'redux'

import profile from './profile/profile'
import wallets from './wallets/wallets'
import kyc from './kyc/kyc'
import orders from './orders/orders'
import payoutProfile from './payoutProfile/payoutProfile'
import passes from './passes/passes'
import referrals from './referrals/referrals'
import gameProfiles from './gameProfiles/gameProfiles'
import passTransactions from './passTransactions/passTransactions'
import address from './address/address'
import timeline from './timeline/timeline'

const player = combineReducers({
  profile,
  wallets,
  kyc,
  orders,
  payoutProfile,
  passes,
  referrals,
  gameProfiles,
  passTransactions,
  address,
  timeline,
})

export default player

export type Player = ReturnType<typeof player>
