import { PlayerKycReducer } from './kyc.types'
import {
  PlayerKycActions,
  StoreOrders,
  StoreKycDetails,
  StoreKycDetailsPayload,
} from '../../../../actions/watson/player/kyc/kyc.types'
import {
  SET_LOADING_ORDERS,
  STORE_ORDERS,
  STORE_KYC_DETAILS,
} from '../../../../actions/watson/player/kyc/ActionTypes'
import { StoreOrdersPayload } from '../../../../actions/watson/kyc/kyc.types'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: PlayerKycReducer = {
  orders: [],
  kycDocumentDetails: {},
  error: {
    orders: null,
    kycDocumentDetails: {
      pan: null,
      other: null,
    },
  },
  meta: {
    loadingOrders: true,
    kycDocumentDetails: {
      loadingPan: false,
      loadingOther: false,
    },
  },
}

const playerKycReducer = (
  state = initialState,
  action: PlayerKycActions
): PlayerKycReducer => {
  switch (action.type) {
    case SET_LOADING_ORDERS: {
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          loadingOrders: true,
        },
      }
    }

    case STORE_ORDERS: {
      const { payload, error } = action as StoreOrders

      if (!error) {
        const { orders } = payload as StoreOrdersPayload

        return {
          ...state,
          orders,
          meta: {
            ...state.meta,
            loadingOrders: false,
          },
        }
      }

      return {
        ...initialState,
        error: {
          ...initialState.error,
          orders: payload as Error,
        },
      }
    }

    case STORE_KYC_DETAILS: {
      const { payload, error, meta } = action as StoreKycDetails

      if (!error) {
        const { kycDetails } = payload as StoreKycDetailsPayload

        return {
          ...state,
          kycDocumentDetails: {
            ...state.kycDocumentDetails,
            [meta.documentType]: kycDetails,
          },
        }
      }

      return {
        ...state,
        error: {
          ...state.error,
          kycDocumentDetails: {
            ...state.error.kycDocumentDetails,
            [meta.documentType]: payload as Error,
          },
        },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default playerKycReducer
