import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import firebase from 'firebase/app'
import { Button } from 'antd'
import styles from './Collusion.module.css'
import { CollusionCase } from '../../../../../types/Collusion.types'
import CollusionRow from './CollusionRow'
import { AppState } from '../../../../../reducers/reducers'
import { getCaseHistory } from './api'
import { FraudProfile } from '../../../../../types/FraudProfile.types'

const Collusion: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [cases, setCases] = useState<CollusionCase[]>([])
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const [fraudProfile, setFraudProfile] = useState<FraudProfile | null>(null)
  const [error, setError] = useState('')

  const fetchCaseHistory = useCallback(async () => {
    if (playerId) {
      setLoading(true)
      setError('')

      const { cases: c, error: e } = await getCaseHistory(playerId)
      setCases(c || [])
      setError(e || '')

      setLoading(false)
    }
  }, [playerId])

  useEffect(() => {
    fetchCaseHistory()
  }, [fetchCaseHistory])

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/players/${playerId}/private/fraudProfile`)
      .onSnapshot(doc => {
        setFraudProfile(doc.data() as FraudProfile)
      })

    return (): void => {
      unsubscribe()
    }
  }, [playerId])

  return (
    <div id={styles.Collusion}>
      <div className={styles.title}>
        <span>Collusion Cases</span>
      </div>

      <div className={styles.currentStatus}>
        <div>
          {`AccountBlocked: ${fraudProfile?.AccountBlocked || 'false'}`}
        </div>
        <div>
          {`DepositBlocked: ${fraudProfile?.DepositBlocked || 'false'}`}
        </div>
        <div>
          {`GameplayBlocked: ${fraudProfile?.GameplayBlocked || 'false'}`}
        </div>
        <div>
          {`MoonshotBlocked: ${fraudProfile?.MoonshotBlocked || 'false'}`}
        </div>
        <div>
          {`RewardsBlocked: ${fraudProfile?.RewardsBlocked || 'false'}`}
        </div>
        <div>
          {`WithdrawalBlocked: ${fraudProfile?.WithdrawalBlocked || 'false'}`}
        </div>
      </div>

      <div className={styles.head}>CASE ID</div>
      <div className={styles.head}>CASE TYPE</div>
      <div className={styles.head}>ROLE</div>
      <div className={styles.head}>CASE DATE</div>
      <div className={styles.head}>STATUS</div>
      <div className={styles.head}>ACTION</div>

      {cases.map(c => {
        return (
          <CollusionRow
            collusionCase={c}
            key={c.case_ref}
            refresh={fetchCaseHistory}
          />
        )
      })}

      {cases.length === 0 && !loading && (
        <div className={styles.noCases}>
          {!error ? <>No Cases</> : null}

          {error ? (
            <>
              <div>{error}</div>
              <Button
                type="primary"
                onClick={fetchCaseHistory}
                loading={loading}
              >
                Retry
              </Button>
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default Collusion
