import React, { useState, useEffect, useCallback } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { DownOutlined, DownloadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import moment from 'moment'
import styles from './CSVUpload.module.css'
import UploadPopup from './UploadPopup'
import Timestamp from '../../../../components/Timestamp/Timestamp'

const cashTemplate = `
name,coupon_code,title,subtitle,icon_url,details,budget_type,budget_value,p_max_attempt_per_day,p_max_attempt_overall,p_subsequent_use_interval_minutes,t1,t2,t3,t4,min_amount,max_amount,reward_type,percent_value,max_reward_amount,destination,should_show,show_after,audience_ids,audience_correlation,hide_offer_on_screens
dummyoffertest4,TESTCOUP14,Dummy Offer V4,subtitle,abcd.png,"DD1,DD2",INR,20000,1,1,999999,7/1/2021 4:00:00 PM,7/1/2021 4:30:00 PM,15/01/2021 11:59:59 pm,16/01/2021 12:30:00 am,1000,2000,CASH,10,200,INR_DEPOSIT,true,07-01-2021 4:30:00 pm,"test_audience,bot_game_played",AND,""
dummyoffertest5,TESTCOUP15,Dummy Offer V5,<money>Mera Subtitle naya waala INR 5</money> <money>Mera Subtitle naya waala INR 5</money> <money>Mera Subtitle naya waala INR 5</money> <money>Mera Subtitle naya waala INR 5</money>,abcd.png,"DD1,DD2",Usage,2000,1,1,999999,7/1/2021 4:00:00 PM,7/1/2021 4:30:00 PM,15/01/2021 11:59:59 pm,16/01/2021 12:30:00 am,1000,2000,CASH,10,200,INR_DEPOSIT,true,07-01-2021 4:30:00 pm,"test_audience,bot_game_played",OR,""
`

const staggeredTemplate = `
name,coupon_code,title,subtitle,icon_url,details,budget_type,budget_value,p_max_attempt_per_day,p_max_attempt_overall,p_subsequent_use_interval_minutes,t1,t2,t3,t4,min_amount,max_amount,reward_type,staggered_reward_template_id,should_show,show_after,audience_ids,audience_correlation,hide_offer_on_screens
dummyoffertest6,TESTCOUP16,Dummy Offer V6,subtitle,abcd.png,"DD1,DD2",INR,20000,1,1,999999,11/1/2021 4:00:00 PM,11/1/2021 4:30:00 PM,15/01/2021 11:59:59 pm,16/01/2021 12:30:00 am,1000,2000,STAGGERED,100_30x30_22nd_Dec,true,11-01-2021 4:30:00 pm,"test_audience,bot_game_played",AND,""
`

const TemplatesMenu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`data:application/octet-stream,${encodeURIComponent(
          cashTemplate
        )}`}
        download="cash-template.csv"
      >
        Cash Template
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`data:application/octet-stream,${encodeURIComponent(
          staggeredTemplate
        )}`}
        download="staggered-template.csv"
      >
        Staggered Reward Template
      </a>
    </Menu.Item>
  </Menu>
)

const CSVUpload: React.FC = () => {
  const [offers, setOffers] = useState<string[][]>([])
  const [filename, setFilename] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const hide = (): void => setShowPopup(false)

  const updateFiles = useCallback(() => {
    try {
      let files = localStorage.getItem('allCsvFiles')
      if (files) {
        files = JSON.parse(files)

        if (files) setUploadedFiles(files as any)
        else setUploadedFiles([])
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    updateFiles()
  }, [updateFiles])

  return (
    <div id={styles.CSVUpload}>
      <div className={styles.header}>
        <div className={styles.title}>CSV Uploads</div>

        <div className={styles.cta}>
          <Dropdown overlay={TemplatesMenu}>
            <Button
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
              type="link"
            >
              Download Templates
              <DownOutlined />
            </Button>
          </Dropdown>

          <input
            type="file"
            accept=".csv"
            placeholder="Upload CSV"
            onChange={e => {
              const f = e?.target?.files?.[0]

              if (f) {
                setFilename(f.name)
                setFile(f)
                setShowPopup(false)

                Papa.parse(f, {
                  delimiter: ',',
                  skipEmptyLines: true,
                  complete: result => {
                    setOffers(result.data as string[][])
                    setShowPopup(true)

                    setTimeout(() => {
                      e.target.value = ''
                    }, 3000)
                  },
                })
              }
            }}
          />
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.th}>file name</div>
        <div className={styles.th}>number of offers</div>
        <div className={styles.th}>uploaded by</div>
        <div className={styles.th}>uploaded on</div>
        <div className={styles.th}>quick actions</div>

        {uploadedFiles.map(f => {
          let details: any
          try {
            details = JSON.parse(localStorage.getItem(f) || '')
          } catch (err) {
            console.error(err)
          }

          return (
            <React.Fragment key={f}>
              <div className={styles.td}>{details.filename}</div>
              <div className={styles.td}>{details.offersCount}</div>
              <div className={styles.td}>{details.uploadedBy}</div>
              <div className={styles.td}>
                <Timestamp timestamp={moment(details.date)} />
              </div>
              <div className={styles.td}>
                <a
                  href={`data:application/octet-stream,${details.content}`}
                  download={details.filename}
                >
                  <DownloadOutlined className={styles.download} />
                </a>
              </div>
            </React.Fragment>
          )
        })}
      </div>

      {showPopup ? (
        <UploadPopup
          offers={offers}
          hide={hide}
          filename={filename}
          file={file}
          updateFiles={updateFiles}
        />
      ) : null}
    </div>
  )
}

export default CSVUpload
