import { TimelineReducer } from './timeline.types'
import {
  SET_LOADING_TIMELINE,
  STORE_TIMELINE,
} from '../../../../actions/watson/player/timeline/ActionTypes'
import {
  StoreTimeline,
  TimelineAction,
  StoreTimelinePayload,
} from '../../../../actions/watson/player/timeline/timeline.types'

const initialState: TimelineReducer = {
  timeline: [],
  error: null,
  meta: { loading: false },
}

const timelineReducer = (
  state = initialState,
  action: TimelineAction
): TimelineReducer => {
  switch (action.type) {
    case SET_LOADING_TIMELINE:
      return {
        timeline: [],
        error: null,
        meta: { loading: true },
      }

    case STORE_TIMELINE: {
      const { payload, error } = action as StoreTimeline

      if (!error) {
        const { timeline } = payload as StoreTimelinePayload

        return {
          timeline,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        timeline: [],
        error: payload as Error,
        meta: { loading: false },
      }
    }
    default:
      return state
  }
}

export default timelineReducer
