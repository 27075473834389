import axios from 'axios'
import { User } from '../../../reducers/auth/auth.types'
import { consoleApiBaseUrl } from '../../../config'
import getAccessToken from '../../../helpers/access-token'
import { AUDIENCE_ATTRIBUTE_TYPES } from './constants'

interface Formvalues {
  [index: string]: any // string | string[] | boolean | number | undefined
}

interface ApiReturnValue {
  success: boolean
  error: string
}

const baseUrl = `${consoleApiBaseUrl}/twirp/pb.Audience`

export const createAudience = async (
  values: Formvalues,
  user: User
): Promise<ApiReturnValue> => {
  const apiEndpoint = `${baseUrl}/CreateAudience`

  /* eslint-disable */
  const {
    name,
    description,
    attributes: attrs,
    creation_intent,
    attribute_correlation,
  } = values
  /* eslint-enable */

  function getOperator(a: any) {
    switch (a.type) {
      case 'NUMBER':
        return 'In'
      case 'TIME':
        return 'In'
      case 'BOOLEAN':
        return 'Equals'
      default:
        return a.operator.replace(' ', '')
    }
  }

  const attributes = attrs?.map((a: any) => ({
    id: a.id,
    type: a.type,
    value: {
      number_min: parseInt(a.number_min, 10) || 0,
      number_max: parseInt(a.number_max, 10) || 0,
      str_value: a.str_value,
      boolean_value: a.boolean_value,
      time_min: a.time_min,
      time_max: a.time_max,
      duration_min: a.duration_min,
      duration_max: a.duration_max,
    },
    category: AUDIENCE_ATTRIBUTE_TYPES.indexOf(a.category),
    GameName: a.GameName,
    PeriodName: a.PeriodName,
    GameCategoryName: a.GameCategoryName,
    operator: a.operator || getOperator(a),
  }))

  const body = {
    name,
    description,
    creation_intent: parseInt(creation_intent, 10),
    attribute_correlation: parseInt(attribute_correlation, 10),
    attributes,
    is_disabled: false,
    created_by: user.email,
  }

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: body,
  })
    .then(() => {
      return {
        success: true,
        error: '',
      }
    })
    .catch(errorResponse => {
      console.error(errorResponse)
      const error = errorResponse.response.data?.msg
      return {
        success: false,
        error,
      }
    })
}

export const getAttributes = async (): Promise<{
  attributesData: Record<string, any>
  error: string
}> => {
  const apiEndpoint = `${baseUrl}/GetAttributesInfo`
  const body = {}

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: body,
  })
    .then(response => {
      return {
        attributesData: response.data,
        error: '',
      }
    })
    .catch(errorResponse => {
      console.error(errorResponse)
      const error = errorResponse.response.data?.msg
      return {
        attributesData: {},
        error,
      }
    })
}
