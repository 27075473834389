import React, { useState } from 'react'
import { Input, Button } from 'antd'
import { useSelector } from 'react-redux'
import styles from './CollusionGroup.module.css'
import addOrRemoveCollusionGroup from './api'
import { AppState } from '../../../reducers/reducers'

const { TextArea } = Input

interface Message {
  index: number
  error: boolean
  message: string
}

const CollusionGroup: React.FC = () => {
  const [groups, setGroups] = useState<string[][]>([])
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [messages, setMessages] = useState<Message[]>([])
  const email = useSelector((state: AppState) => state.auth.user?.email)

  const callApi = (addingGroup: boolean) => async (): Promise<void> => {
    if (addingGroup) setAdding(true)
    else setRemoving(true)

    const msgs: Message[] = []
    const promises = groups.map((group, i) => {
      return addOrRemoveCollusionGroup(
        addingGroup ? '/AddCollusionGroup' : '/RemoveCollusionGroup',
        group,
        email || ''
      ).then(err => {
        if (err) {
          msgs.push({
            index: i,
            error: true,
            message: err,
          })
        } else {
          const s = addingGroup ? 'Added!' : 'Removed!'
          msgs.push({
            index: i,
            error: false,
            message: s,
          })
        }
      })
    })

    await Promise.all(promises)
    setMessages(msgs)
    if (addingGroup) setAdding(false)
    else setRemoving(false)
  }

  return (
    <div id={styles.CollusionGroup}>
      <div className={styles.title}>Add / Remove Collusion Group</div>

      <div
        style={{
          color: 'rgb(247 111 111)',
          margin: '32px 0 4px',
          textAlign: 'left',
        }}
      >
        Keep player ids separated by space. Each line is a separate collusion
        group.
        <br />
        group1: player_id1, player_id2
        <br />
        group2: player_id3, player_id4
      </div>
      <TextArea
        rows={10}
        allowClear
        className={styles.list}
        placeholder={`eg.\nplayer_id1 player_id2\nplayer_id3 player_id4`}
        onChange={e => {
          const { value } = e.target
          const groupStrings = value.split('\n').filter(v => v)
          const grps = groupStrings.reduce((v: string[][], g) => {
            return [...v, g.split(' ').filter(pid => pid)]
          }, [])
          setGroups(grps)
        }}
      />

      <div className={styles.cta}>
        {messages
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((m, i) => {
            return (
              <div
                key={`${m.message}-${i + 1}`}
                className={`${m.error ? styles.error : styles.success}`}
              >
                {`Group ${m.index + 1}: ${m.message}`}
              </div>
            )
          })}
        <Button
          type="primary"
          style={{ marginRight: '32px' }}
          onClick={callApi(true)}
          loading={adding}
          disabled={removing}
        >
          Add
        </Button>

        <Button
          type="primary"
          danger
          onClick={callApi(false)}
          loading={removing}
          disabled={adding}
        >
          Remove
        </Button>
      </div>
    </div>
  )
}

export default CollusionGroup
