import { fork, take, cancel, takeLatest, put } from 'redux-saga/effects'
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types'
import { Task } from 'redux-saga'

import rsf from '../../../../firebase'
import { Wallets } from '../../../../reducers/watson/player/wallets/wallets.types'
import {
  CANCEL_WALLETS_SYNC,
  SYNC_WALLETS,
} from '../../../../actions/watson/player/wallets/ActionTypes'
import {
  SyncWallets,
  StoreWallets,
} from '../../../../actions/watson/player/wallets/wallets.types'
import {
  storeWallets,
  setLoadingWallets,
} from '../../../../actions/watson/player/wallets/wallets'

const getStoreWalletsAction = (snapshot: QuerySnapshot): StoreWallets => {
  const wallets: Wallets = {}
  snapshot.forEach((wallet: DocumentData) => {
    const w = wallet.data()
    w.id = wallet.id

    if (w.Type !== 'Winnings' && w.Type !== 'Deposit') {
      return
    }

    const type = w.Type === 'Winnings' ? 'Winnings' : 'Deposit'
    wallets[type] = w
  })

  return storeWallets(wallets)
}

function* syncWallets(action: SyncWallets): Generator {
  yield put(setLoadingWallets())

  const { playerId } = action.payload

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    `players/${playerId}/wallets`,
    {
      successActionCreator: getStoreWalletsAction,
    }
  )

  yield take(CANCEL_WALLETS_SYNC)
  yield cancel(task as Task)
}

export default function* watchSyncWallets(): Generator {
  yield takeLatest(SYNC_WALLETS, syncWallets)
}
