import React from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

import './App.css'
import store from './store'

import Login from './components/Login/Login'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
// import Dashboards from './components/Dashboards'
import Watson from './watson/Watson'

const App: React.FC = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/">
              <Redirect to="/watson" />
            </Route>
            <ProtectedRoute path="/watson" component={Watson} />
          </Switch>
        </Router>
      </Provider>
    </div>
  )
}

export default hot(App)
