const prefix = '@@watson/@@player/@@orders'

export const SET_LOADING_ALL_ORDERS = `${prefix}/SET_LOADING_ALL_ORDERS`
export const SET_LOADING_REWARDS = `${prefix}/SET_LOADING_REWARDS`

export const SYNC_ALL_ORDERS = `${prefix}/SYNC_ALL_ORDERS`
export const CANCEL_ALL_ORDERS_SYNC = `${prefix}/CANCEL_ALL_ORDERS_SYNC`
export const GET_ALL_ORDERS = `${prefix}/GET_ALL_ORDERS`
export const STORE_ORDERS = `${prefix}/STORE_ORDERS`

export const SYNC_REWARDS = `${prefix}/SYNC_REWARDS`
export const CANCEL_REWARDS_SYNC = `${prefix}/CANCEL_REWARDS_SYNC`
export const STORE_REWARDS = `${prefix}/STORE_REWARDS`

export const SYNC_INTERCOM_REFUNDS = `${prefix}/SYNC_INTERCOM_REFUNDS`
export const CANCEL_INTERCOM_REFUNDS_SYNC = `${prefix}/CANCEL_INTERCOM_REFUNDS_SYNC`
export const STORE_INTERCOM_REFUNDS = `${prefix}/STORE_INTERCOM_REFUNDS`

export const GET_CF_SESSIONS = `${prefix}/GET_CF_SESSIONS`
export const STORE_CF_SESSIONS = `${prefix}/STORE_CF_SESSIONS`
