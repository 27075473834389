import { combineReducers } from 'redux'
import payout from './payout/payout'
import kyc from './kyc/kyc'
import player from './player/player'
import cf from './cfSession/cfSession'
import deposit from './deposit/deposit'

const watson = combineReducers({
  payout,
  kyc,
  player,
  cf,
  deposit,
})

export default watson

export type Watson = ReturnType<typeof watson>
