import React from 'react'
import styles from '../InstantContestJoin.module.css'
import useSyncFirestoreDocument from '../../../../../../hooks/useSyncFirestoreDocument'
import { StaggeredReward } from '../../../../../../types/StaggeredReward.types'
import StaggeredPreview from '../../../../StaggeredRewards/StaggeredPreview'

interface Props {
  templateId: string
}

const StaggeredDetails: React.FC<Props> = ({ templateId }) => {
  const template = useSyncFirestoreDocument<StaggeredReward | null>(
    `taskTemplates/${templateId}`
  )
  if (template)
    return (
      <div className={styles.staggeredDetails}>
        <StaggeredPreview staggeredReward={template} showTitle={false} />
      </div>
    )
  return null
}

export default StaggeredDetails
