import { DocumentData } from '@firebase/firestore-types'
import {
  SyncSessions,
  CancelSessionsSync,
  StoreSessions,
  GetSessions,
  SetLoadingCfSessions,
  FilterSessions,
  StoreFilteredSessions,
} from './cfSession.types'
import {
  SYNC_SESSIONS,
  CANCEL_SESSIONS_SYNC,
  STORE_SESSIONS,
  GET_SESSIONS,
  SET_LOADING_CF_SESSIONS,
  FILTER_SESSIONS,
  STORE_FILTERED_SESSIONS,
} from './ActionTypes'
import {
  CfSessionState,
  CfSession,
} from '../../../reducers/watson/cfSession/cfSession.types'
import { SelectedFilters } from '../../../components/FirestoreFilter/FirestoreFilter'

export const setLoadingCfSessions = (
  sessionState: CfSessionState
): SetLoadingCfSessions => ({
  type: SET_LOADING_CF_SESSIONS,
  payload: {
    sessionState,
  },
})

export const syncSessions = (): SyncSessions => ({
  type: SYNC_SESSIONS,
  payload: {},
})

export const cancelSessionsSync = (): CancelSessionsSync => ({
  type: CANCEL_SESSIONS_SYNC,
  payload: {},
})

export const storeSessions = ({
  sessions,
  sessionState,
  lastDoc,
  lastPage,
}: {
  sessions: CfSession[]
  sessionState: CfSessionState
  lastDoc: DocumentData | null
  lastPage: boolean
}): StoreSessions => ({
  type: STORE_SESSIONS,
  payload: {
    sessions,
  },
  meta: {
    sessionState,
    lastDoc,
    lastPage,
  },
})

export const getSessions = (sessionState: CfSessionState): GetSessions => ({
  type: GET_SESSIONS,
  payload: { sessionState },
})

export const filterSessions = (
  selectedFilters: SelectedFilters
): FilterSessions => ({
  type: FILTER_SESSIONS,
  payload: { selectedFilters },
})

export const storeFilteredSessions = (
  filteredSessions: CfSession[] | Error
): StoreFilteredSessions => ({
  type: STORE_FILTERED_SESSIONS,
  payload:
    filteredSessions instanceof Error ? filteredSessions : { filteredSessions },
  ...(filteredSessions instanceof Error && { error: true }),
})
