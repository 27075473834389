import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './GeneralDetails.module.css'
import BlockFlagReward from './BlockFlagReward/BlockFlagReward'
import PersonalDetails from './PersonalDetails/PersonalDetails'
import { Profile } from '../../../../../../reducers/watson/player/profile/profile.types'
import AppDetails from './AppDetails/AppDetails'
import { getAddress } from '../../../../../../actions/watson/player/address/address'
import { AppState } from '../../../../../../reducers/reducers'
import LocationIcon from '../../../../../../assets/location.svg'
import getPlayerDpUrl from '../../../../../../helpers/fb-photo-url'

interface Props {
  profile: Profile
  loginBlocked: boolean
  setLoginBlocked: Function
  platformBlocked: boolean
  setPlatformBlocked: Function
  setBlockReason: Function
}

const GeneralDetails: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const {
    profile,
    loginBlocked,
    setLoginBlocked,
    platformBlocked,
    setPlatformBlocked,
    setBlockReason,
  } = props
  const { addresses } = useSelector(
    (state: AppState) => state.watson.player.address
  )

  useEffect(() => {
    if (profile?.PlayerId) dispatch(getAddress(profile.PlayerId))
  }, [profile, dispatch])

  return (
    <div id={styles['general-details']}>
      <div className={styles['player-dp']}>
        <img
          src={getPlayerDpUrl(profile.PhotoURL)}
          alt="player dp"
          className={styles.photo}
        />
      </div>
      <div className={styles.name}>{profile.DisplayName}</div>
      <div className={styles.address}>
        {addresses[0] && (
          <>
            <img
              src={LocationIcon}
              alt="location"
              className={styles.location}
            />
            <span>{`${addresses[0]?.State}, ${addresses[0]?.Country}`}</span>
          </>
        )}
      </div>
      <BlockFlagReward
        playerId={profile.PlayerId}
        loginBlocked={loginBlocked}
        setLoginBlocked={setLoginBlocked}
        platformBlocked={platformBlocked}
        setPlatformBlocked={setPlatformBlocked}
        setBlockReason={setBlockReason}
      />
      <div className={styles.details}>
        <PersonalDetails profile={profile} />
        <AppDetails profile={profile} />
      </div>
    </div>
  )
}

export default GeneralDetails
