import {
  SET_LOGIN_IN_PROGRESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  SET_LOGOUT_IN_PROGRESS,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_ACCESS_TOKEN,
} from './ActionTypes'
import {
  LoginRequest,
  SetLoginInProgress,
  LoginSucess,
  LoginError,
  LogoutRequest,
  SetLogoutInProgress,
  LogoutSuccess,
  LogoutError,
  SetAccessToken,
} from './auth.types'
import { User } from '../../reducers/auth/auth.types'

export const loginRequest = (): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload: {},
})

export const setLoginInProgress = (
  isLoginInProgress: boolean
): SetLoginInProgress => ({
  type: SET_LOGIN_IN_PROGRESS,
  payload: {
    isLoginInProgress,
  },
})

export const loginSuccess = (user: User): LoginSucess => ({
  type: LOGIN_SUCCESS,
  payload: { user },
})

export const loginError = (error: Error): LoginError => ({
  type: LOGIN_ERROR,
  payload: error,
  error: true,
})

export const logoutRequest = (): LogoutRequest => ({
  type: LOGOUT_REQUEST,
  payload: {},
})

export const setLogoutInProgress = (
  isLogoutInProgress: boolean
): SetLogoutInProgress => ({
  type: SET_LOGOUT_IN_PROGRESS,
  payload: {
    isLogoutInProgress,
  },
})

export const logoutSuccess = (): LogoutSuccess => ({
  type: LOGOUT_SUCCESS,
  payload: {},
})

export const logoutError = (error: Error): LogoutError => ({
  type: LOGOUT_ERROR,
  payload: error,
  error: true,
})

export const setAccessToken = (accessToken: string): SetAccessToken => ({
  type: SET_ACCESS_TOKEN,
  payload: { accessToken },
})
