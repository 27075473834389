import React, { useState } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import { Modal, Input, message } from 'antd'
import { Offer } from '../Offer'
import { unpublishOffer } from '../api'

interface Props {
  offer: Offer
}

const CurrentOfferQuickActions: React.FC<Props> = props => {
  const { offer } = props
  const [showUnpublishModal, setShowUnpublishModal] = useState(false)
  const [reason, setReason] = useState('')
  const [unpublishing, setUnpublishing] = useState(false)

  const callUnpublishOffer = async (): Promise<void> => {
    setUnpublishing(true)
    if (!reason) return

    const success = await unpublishOffer(offer.Id, reason)
    if (success) {
      message.success(`${offer.Name} unpublished`)
      setShowUnpublishModal(false)
    } else {
      message.error(`Error unpublishing ${offer.Name}`)
    }
    setUnpublishing(false)
  }

  return (
    <>
      <CloseCircleFilled
        title="unpublish"
        onClick={(): void => {
          setShowUnpublishModal(true)
        }}
      />
      <Modal
        title={`Unpublish Offer: ${offer.Name}`}
        visible={showUnpublishModal}
        onOk={callUnpublishOffer}
        okButtonProps={{ disabled: reason === '', loading: unpublishing }}
        onCancel={(): void => {
          setShowUnpublishModal(false)
        }}
      >
        <Input.TextArea
          placeholder="Unpublish reason"
          value={reason}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setReason(e.currentTarget.value)
          }}
        />
        {!reason && <div style={{ color: 'red' }}>Required!</div>}
      </Modal>
    </>
  )
}

export default CurrentOfferQuickActions
