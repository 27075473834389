import React from 'react'
import styles from './InstantContestJoin.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import GemIcon from '../../../../../assets/crystal.svg'

interface Props {
  order: Order
}

const Reward: React.FC<Props> = props => {
  const { order } = props
  const isPassReward = !(order.Fulfillments && order.Fulfillments[0].Payment)

  let Fulfillments
  if (!isPassReward) Fulfillments = order.Fulfillments.map(f => f.Payment)

  let passes
  if (isPassReward) {
    passes = order.Product.PassProps?.Passes
    passes = passes?.map((pass, i) => ({
      ...pass,
      id:
        order.Fulfillments[0].Reference?.PassTxnRefs &&
        order.Fulfillments[0].Reference?.PassTxnRefs[i],
    }))
  }

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>More Details</div>

        <div className={styles.key}>REWARD TYPE</div>
        <div className={styles.value}>
          {order.Product.RewardProps?.RewardType}
        </div>

        <div className={styles.key}>INITIATOR ID</div>
        <div className={styles.value}>
          {order.Product.RewardProps?.InitiatorId || '-'}
        </div>

        <div className={styles.key}>REASON</div>
        <div className={styles.value}>
          {order.Product.RewardProps?.Reason || '-'}
        </div>

        <div className={styles.key}>PARENT ORDER ID</div>
        <div className={styles.value}>
          {order.Product.RewardProps?.ParentOrderRef || '-'}
        </div>
      </div>

      {!isPassReward && (
        <div className={styles.card}>
          <div className={styles.title}>Wallet Distribution</div>

          <div className={styles.wallets}>
            <div className={styles.th}>WALLET BALANCE</div>
            <div className={styles.th}>amount</div>
            {Fulfillments?.map(wallet => {
              return (
                <React.Fragment key={wallet?.Destination}>
                  <div className={styles.td}>{wallet?.Destination}</div>
                  <div className={styles.td}>
                    {wallet?.Currency === 'INR' ? (
                      '₹'
                    ) : (
                      <img src={GemIcon} alt="gem icon" className="gem" />
                    )}
                    {` ${wallet?.Amount}`}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      )}

      {isPassReward && (
        <div className={styles.card}>
          <div className={styles.title}>Pass Breakdown</div>

          <div className={styles.passes}>
            <div className={styles.th}>PASS TYPE</div>
            <div className={styles.th}>MAX ENTRY FEE</div>
            <div className={styles.th}>MIN ENTRY FEE</div>
            <div className={styles.th}>PERCENT RECOVERY</div>

            {passes?.map(pass => {
              return (
                <React.Fragment key={pass.id}>
                  <div className={styles.td}>{pass.Category}</div>
                  <div className={styles.td}>{pass.MaxEntryFeeValue}</div>
                  <div className={styles.td}>{pass.MinEntryFeeValue}</div>
                  <div className={styles.td}>{pass.PercentRecovery}</div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default Reward
