import {
  SetLoadingWallets,
  SyncWallets,
  StoreWallets,
  CancelWalletsSync,
} from './wallets.types'
import {
  SET_LOADING_WALLETS,
  SYNC_WALLETS,
  STORE_WALLETS,
  CANCEL_WALLETS_SYNC,
} from './ActionTypes'
import { Wallets } from '../../../../reducers/watson/player/wallets/wallets.types'

export const setLoadingWallets = (): SetLoadingWallets => ({
  type: SET_LOADING_WALLETS,
  payload: {},
})

export const syncWallets = (playerId: string): SyncWallets => ({
  type: SYNC_WALLETS,
  payload: { playerId },
})

export const cancelWalletsSync = (): CancelWalletsSync => ({
  type: CANCEL_WALLETS_SYNC,
  payload: {},
})

export const storeWallets = (wallets: Wallets | Error): StoreWallets => ({
  type: STORE_WALLETS,
  payload: wallets instanceof Error ? wallets : { wallets },
  ...(wallets instanceof Error && { error: true }),
})
