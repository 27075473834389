import { takeLatest, put, fork, cancel, take } from 'redux-saga/effects'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import firebase from 'firebase/app'
import {
  loadingPasses,
  storePasses,
} from '../../../../actions/watson/player/passes/passes'
import { SyncPasses } from '../../../../actions/watson/player/passes/passes.types'
import {
  SYNC_PASSES,
  CANCEL_PASSES_SYNC,
} from '../../../../actions/watson/player/passes/ActionTypes'
import rsf from '../../../../firebase'
import { PassDetails } from '../../../../reducers/watson/player/passes/passes.types'

function* syncPasses(action: SyncPasses): Generator {
  yield put(loadingPasses())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/private/encompass/passes`)
    // .where('State', '==', 'Unused')
    .orderBy('ExpiryAt', 'desc')

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const passes: PassDetails[] = []

        snapshot.forEach((doc: DocumentData) => {
          passes.push(doc.data())
        })

        return storePasses(passes)
      },
    }
  )

  yield take(CANCEL_PASSES_SYNC)
  yield cancel(task as Task)
}

export default function* watchPasses(): Generator {
  yield takeLatest(SYNC_PASSES, syncPasses)
}
