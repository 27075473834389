import React, { ReactNode } from 'react'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { Spin } from 'antd'
import styles from './ProtectedRoute.module.css'
import { AppState } from '../../reducers/reducers'

type Props = { component: React.FC; path: string }

const selectAuth = createSelector(
  (state: AppState) => state.auth,
  auth => auth
)

const ProtectedRoute: React.FC<Props> = ({ component, path }) => {
  const Component = component
  const { meta, user } = useSelector(selectAuth, shallowEqual)
  const isAuthenticated = user !== null
  const { isLoginInProgress } = meta

  const redirectIfNotAuthenticated = (): ReactNode => {
    return isAuthenticated ? (
      <Component />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  }

  return (
    <Route
      path={path}
      render={
        (): ReactNode =>
          isLoginInProgress ? (
            <div className={styles['authenticating-div']}>
              <Spin tip="authenticating..." />
            </div>
          ) : (
            redirectIfNotAuthenticated()
          )
        // eslint-disable-next-line
      }
    />
  )
}

export default ProtectedRoute
