export type DEPOSIT_OFFER_STATE =
  | 'DRAFT'
  | 'ACTIVE'
  | 'INACTIVE'
  | 'BUDGET_EXCEEDED'
  | 'UNPUBLISHED'
  | 'CLOSING'
  | 'VISIBLE'

const menuItems: {
  [index: string]: {
    title: string
    pageTitle: string
    IsUnpublished?: boolean
    state?: DEPOSIT_OFFER_STATE[]
  }
} = {
  'current-offers': {
    title: 'Current Offers',
    pageTitle: 'Current Deposit Offers',
    IsUnpublished: false,
    state: ['ACTIVE', 'CLOSING', 'VISIBLE'],
  },
  'upcoming-offers': {
    title: 'Upcoming Offers',
    pageTitle: 'Upcoming Deposit Offers',
    IsUnpublished: false,
    state: ['DRAFT', 'VISIBLE'],
  },
  'expired-offers': {
    title: 'Expired Offers',
    pageTitle: 'Expired Deposit Offers',
    IsUnpublished: true,
    state: ['INACTIVE', 'BUDGET_EXCEEDED', 'UNPUBLISHED'],
  },
  'csv-upload': {
    title: 'CSV Uploads',
    pageTitle: 'CSV Uploads',
    IsUnpublished: false,
    state: [],
  },
}

export default menuItems
