import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import styles from './OffersList.module.css'
// import dummyOffers from '../dummy-offers'
import OfferRow from './OfferRow'
import CreateOffer from '../CreateOffer/CreateOffer'
import menuItems from '../Sidebar/menuitems'
import { Offer, OfferUsageMap } from '../Offer'

interface Props {
  selectedMenuitem: string
}

const collectionRef = firebase.firestore().collection('offers')

const OffersList: React.FC<Props> = props => {
  const { selectedMenuitem } = props
  const [createOffer, setCreateOffer] = useState(false)
  const [offers, setOffers] = useState<Offer[]>([])
  const [offerUsage, setOfferUsage] = useState<OfferUsageMap>({})

  const toggleCreateOffer = (): void => {
    setCreateOffer(!createOffer)
  }

  const getOfferUsageDetails = async (ofrs: Offer[]): Promise<void> => {
    const usage = await Promise.all(
      ofrs.map(o =>
        firebase
          .firestore()
          .doc(`offers/${o.Id}/details/usage`)
          .get()
          .then(doc => {
            return {
              OfferId: o.Id,
              ...doc.data(),
            }
          })
      )
    )
    const usageMap = usage.reduce((um, u) => {
      return {
        ...um,
        [u.OfferId]: u,
      }
    }, {})
    setOfferUsage(usageMap)
  }

  useEffect(() => {
    const unsubscribe = collectionRef
      .where('State', 'in', menuItems[selectedMenuitem].state)
      .where('ProductType', '==', 'DEPOSIT')
      .onSnapshot(snapshot => {
        const ofrs: Offer[] = []
        snapshot.forEach((doc: DocumentData) => {
          ofrs.push(doc.data())
        })
        setOffers(ofrs)
        ;(window as any).offers = ofrs

        getOfferUsageDetails(ofrs)
      })

    return (): void => {
      unsubscribe()
    }
  }, [selectedMenuitem])

  return (
    <>
      {!createOffer && (
        <div id={styles['offers-list']}>
          <div className={styles.header}>
            <div className={styles.title}>
              {menuItems[selectedMenuitem].pageTitle}
            </div>
            {selectedMenuitem === Object.keys(menuItems)[0] && (
              <Button size="large" type="primary" onClick={toggleCreateOffer}>
                Create Deposit Offer
              </Button>
            )}
          </div>

          <div />
          <div className={`${styles.head} ${styles.first}`}>NAME</div>
          <div className={styles.head}>COUPON CODE</div>
          <div className={styles.head}>TITLE</div>
          <div className={styles.head}>TOTAL BUDGET</div>
          <div className={styles.head}>USED BUDGET</div>
          <div className={styles.head}>START TIME</div>
          <div className={styles.head}>END TIME</div>
          {menuItems[selectedMenuitem].IsUnpublished ? (
            <div className={styles.head}>STATE</div>
          ) : (
            <div className={`${styles.head} ${styles.last}`}>QUICK ACTIONS</div>
          )}

          {/* {dummyOffers.map((offer) => { */}
          {offers.map(offer => {
            return (
              <OfferRow key={offer.Id} offer={offer} offerUsage={offerUsage} />
            )
          })}

          {offers.length === 0 && (
            <div className={styles['no-offers']}>No Offers</div>
          )}
        </div>
      )}

      {createOffer && <CreateOffer toggleCreateOffer={toggleCreateOffer} />}
    </>
  )
}

export default OffersList
