import React from 'react'
import moment from 'moment'
import styles from './OfferDetails.module.css'
import Timestamp from '../../../../components/Timestamp/Timestamp'
import { Attribute } from '../Offer'

interface Props {
  attribute: Attribute
}

const AttributeDetails: React.FC<Props> = props => {
  const { attribute } = props

  const getOperator = (attribute: any) => {
    if (!!attribute.Operator) {
      return attribute.Operator
    }

    switch (attribute.AttributeType) {
      case 'STRING':
        return 'Equals'
      case 'BOOLEAN':
        return 'Equals'
      case 'TIME':
        return 'In'
      case 'NUMBER':
        return 'In'
      default:
        return ''
    }
  }

  return (
    <div style={{ marginRight: '32px' }}>
      <div className={styles.key}>ATTRIBUTE</div>
      <div className={styles.value}>{attribute.AttributeId}</div>

      <div className={styles.key}>ATTRIBUTE TYPE</div>
      <div className={styles.value}>{attribute.AttributeType}</div>

      <div className={styles.key}>ATTRIBUTE OPERATOR</div>
      <div className={styles.value}>{getOperator(attribute)}</div>

      {attribute.AttributeType === 'NUMBER' && (
        <>
          <div className={styles.key}>MIN VALUE</div>
          <div className={styles.value}>{attribute.ValueRange.NumberMin}</div>

          <div className={styles.key}>MAX VALUE</div>
          <div className={styles.value}>{attribute.ValueRange.NumberMax}</div>
        </>
      )}

      {attribute.AttributeType === 'STRING' && (
        <>
          <div className={styles.key}>STRING VALUE</div>
          <div className={styles.value}>{attribute.ValueRange.StringValue}</div>
        </>
      )}

      {attribute.AttributeType === 'TIME' && (
        <>
          <div className={styles.key}>MIN TIME</div>
          <div className={styles.value}>
            {attribute.ValueRange.TimeMin ? (
              <Timestamp
                timestamp={moment(attribute.ValueRange.TimeMin.seconds * 1000)}
              />
            ) : (
              <>-</>
            )}
          </div>

          <div className={styles.key}>MAX TIME</div>
          <div className={styles.value}>
            {attribute.ValueRange.TimeMax ? (
              <Timestamp
                timestamp={moment(attribute.ValueRange.TimeMax.seconds * 1000)}
              />
            ) : (
              <>-</>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default AttributeDetails
