import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import styles from './Watson.module.css'
import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import Kyc from './components/Kyc/Kyc'
import PlayerProfiles from './components/PlayerProfiles/PlayerProfiles'
import DepositOffers from './components/DepositOffers/DepositOffers'
import AudienceService from './components/AudienceService/AudienceService'
import Payout from './components/Payout/Payout'
import CfSession from './components/CfSession/CfSession'
import Deposit from './components/Deposit/Deposit'
import CollusionGroup from './components/CollusionGroup/CollusionGroup'
import StaggeredRewards from './components/StaggeredRewards/StaggeredRewards'

const Watson: React.FC = () => {
  const match = useRouteMatch()

  return (
    <div id={styles.watson}>
      <Navbar />
      <Switch>
        <Route exact path={match.path} component={Home} />
        <Route exact path={`${match.path}/kyc`} component={Kyc} />
        <Route
          exact
          path={`${match.path}/deposit-offers`}
          component={DepositOffers}
        />
        <Route
          exact
          path={`${match.path}/staggered-rewards`}
          component={StaggeredRewards}
        />
        <Route
          exact
          path={`${match.path}/player-details/:playerId`}
          component={PlayerProfiles}
        />
        <Route
          exact
          path={`${match.path}/audience-service`}
          component={AudienceService}
        />
        <Route exact path={`${match.path}/payout-monitor`} component={Payout} />
        <Route exact path={`${match.path}/cf-monitor`} component={CfSession} />
        <Route
          exact
          path={`${match.path}/deposit-monitor`}
          component={Deposit}
        />
        <Route
          exact
          path={`${match.path}/collusion-group`}
          component={CollusionGroup}
        />
      </Switch>
    </div>
  )
}

export default Watson
