import React from 'react'
import moment from 'moment'
import styles from './OfferDetails.module.css'
import { Offer } from '../Offer'
import Timestamp from '../../../../components/Timestamp/Timestamp'
import AudienceDetails from './AudienceDetails'
import getBudgetTypeAndValue from './get-budget-type-and-value'
import useAudience from '../useAudience'
import Separator from '../../../../components/Separator/Separator'
import formatINR from '../../../../helpers/format-inr'

interface Props {
  offer: Offer
}

const OfferDetails: React.FC<Props> = props => {
  const { offer } = props
  const audience = useAudience()

  const { budgetType, budgetValue } = getBudgetTypeAndValue(offer)

  return (
    <div className={styles['offer-details']}>
      <div className={styles.card}>
        <div className={styles.title}>OFFER DETAILS</div>

        <div className={styles.key}>OFFER NAME</div>
        <div className={styles.value}>{offer.Name}</div>

        <div className={styles.key}>TITLE</div>
        <div className={styles.value}>{offer.Title}</div>

        <div className={styles.key}>COUPON CODE</div>
        <div className={styles.value}>{offer.CouponCode}</div>

        <div className={styles.key}>SUBTITLE</div>
        <div className={styles.value}>{offer.Subtitle}</div>

        <div className={styles.key}>ICON</div>
        <div className={styles.value}>-</div>

        <div className={styles.key}>DETAILS</div>
        {offer.Details.map(d => {
          return (
            <div className={styles.value} key={d}>
              {d}
            </div>
          )
        })}
        {offer.Details.length === 0 && <div className={styles.value}>-</div>}

        <div className={styles.key}>OFFER TYPE</div>
        <div className={styles.value}>{offer.Type}</div>

        <div className={styles.key}>BUDGET TYPE</div>
        <div className={styles.value}>{budgetType}</div>

        <div className={styles.key}>BUDGET VALUE</div>
        <div className={styles.value}>{formatINR(budgetValue)}</div>

        <div className={styles.key}>MAX ATTEMPTS PER DAY PER PLAYER</div>
        <div className={styles.value}>
          {offer.PlayerUsageLimit.MaxAttemptPerDay}
        </div>

        <div className={styles.key}>MAX ATTEMPTS OVERALL PER PLAYER</div>
        <div className={styles.value}>
          {offer.PlayerUsageLimit.MaxAttemptOverall}
        </div>

        <div className={styles.key}>
          PLAYER SUBSEQUENT USE INTERVAL (MINUTES)
        </div>
        <div className={styles.value}>
          {`${offer.PlayerUsageLimit.SubsequentUseMinInterval} minutes`}
        </div>

        <div className={styles.key}>T1 - OFFER VISIBLE TIME</div>
        <div className={styles.value}>
          <Timestamp timestamp={moment(offer.T1.seconds * 1000)} />
        </div>

        <div className={styles.key}>T2 - OFFER ACTIVE TIME</div>
        <div className={styles.value}>
          <Timestamp timestamp={moment(offer.T2.seconds * 1000)} />
        </div>

        <div className={styles.key}>T3 - OFFER CLOSING TIME</div>
        <div className={styles.value}>
          <Timestamp timestamp={moment(offer.T3.seconds * 1000)} />
        </div>

        <div className={styles.key}>T4 - OFFER UNPUBLISH TIME</div>
        <div className={styles.value}>
          <Timestamp timestamp={moment(offer.T4.seconds * 1000)} />
        </div>

        <div className={styles.key}>OFFER TRIGGER MIN AMOUNT</div>
        <div className={styles.value}>
          {`₹ ${formatINR(offer.Trigger.MinAmount)}`}
        </div>

        <div className={styles.key}>OFFER TRIGGER MAX AMOUNT</div>
        <div className={styles.value}>
          {`₹ ${formatINR(offer.Trigger.MaxAmount)}`}
        </div>

        <div className={styles.key}>REWARD TYPE</div>
        <div className={styles.value}>
          {offer.Rewards[0].Type === 'DYNAMIC' ? 'CASH' : offer.Rewards[0].Type}
        </div>

        {/* <div className={styles.key}>PRODUCT ID</div>
        <div className={styles.value}>-</div> */}
        {offer.Rewards[0].Type === 'DYNAMIC' ||
        offer.Rewards[0].Type === 'CASH' ? (
          <>
            <div className={styles.key}>PERCENT VALUE</div>
            <div className={styles.value}>
              {offer.Rewards[0].Dynamic?.PercentValue ||
                offer.Rewards[0].Cash?.PercentValue}
            </div>

            <div className={styles.key}>MAX AMOUNT</div>
            <div className={styles.value}>
              {formatINR(
                offer.Rewards[0].Dynamic?.MaximumAmount ||
                  offer.Rewards[0].Cash?.MaximumAmount
              )}
            </div>

            <div className={styles.key}>REWARD DESTINATION</div>
            <div className={styles.value}>
              {offer.Rewards[0].Dynamic?.Destination ||
                offer.Rewards[0].Cash?.Destination}
            </div>
          </>
        ) : null}

        {offer.Rewards[0].Type === 'STAGGERED' ? (
          <>
            <div className={styles.key}>STAGGERED REWARD ID</div>
            <div className={styles.value}>
              {offer.Rewards[0].Staggered.TemplateId}
            </div>
          </>
        ) : null}

        <div className={styles.key}>SHOW VALIDITY TEXT</div>
        <div className={styles.value}>
          {offer.OfferValidityTextConfig.ShouldShow ? 'YES' : 'NO'}
        </div>

        {offer.OfferValidityTextConfig.ShouldShow && (
          <>
            <div className={styles.key}>SHOW VALIDITY AFTER</div>
            <div className={styles.value}>
              <Timestamp
                timestamp={moment(
                  offer.OfferValidityTextConfig.ShouldShowAfterTime.seconds *
                    1000
                )}
              />
            </div>
          </>
        )}

        {offer.OfferHiddenScreens && (
          <>
            <div className={styles.key}>HIDE OFFER ON SCREENS</div>
            {Object.keys(offer.OfferHiddenScreens)
              .filter(k => offer.OfferHiddenScreens[k])
              .map(k => {
                return (
                  <div key={k} className={styles.value}>
                    {k}
                  </div>
                )
              })}
          </>
        )}

        <div className={styles.key}>CREATED BY</div>
        <div className={styles.value}>{offer.CreatedBy}</div>
      </div>

      <div className={styles.card}>
        <div className={styles.title}>AUDIENCE DETAILS</div>
        {audience.length > 0 &&
          offer.AudienceIds.map((id, i) => {
            return (
              <React.Fragment key={id}>
                <AudienceDetails
                  audience={audience.find(a => a.Id === id) || audience[0]}
                />
                {i === offer.AudienceIds.length - 1 ? null : <Separator />}
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}

export default OfferDetails
