import React from 'react'
import { Moment } from 'moment'

interface Props {
  timestamp: Moment
}

const Timestamp: React.FC<Props> = props => {
  const { timestamp } = props

  return (
    <>
      <div>{timestamp.format('ll')}</div>
      <div>{timestamp.format('hh:mm A')}</div>
    </>
  )
}

export default Timestamp
