import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './Deposit.module.css'
import Sidebar from './Sidebar'
import OrdersTable from './OrdersTable'
import { AppState } from '../../../reducers/reducers'
import { OrderState } from '../../../reducers/watson/deposit/deposit.types'
import useSyncDepositOrders from './useSyncDepositOrders'

const Deposit: React.FC = () => {
  const [selectedOrderState, setSelectedOrderState] =
    useState<OrderState>('Processing')

  const { orders } = useSelector((state: AppState) => state.watson.deposit)
  const pendingOrdersCount = Object.values(orders).reduce(
    (count: number, o) => count + (o.State === 'Processing' ? 1 : 0),
    0
  )
  useSyncDepositOrders()

  return (
    <div id={styles.deposit} className="loader-container">
      <Sidebar
        selectedOrderState={selectedOrderState}
        setSelectedOrderState={setSelectedOrderState}
        pendingOrdersCount={pendingOrdersCount}
      />
      <OrdersTable selectedOrderState={selectedOrderState} />
    </div>
  )
}

export default Deposit
