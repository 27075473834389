import React, { useState } from 'react'
import styles from './DepositOffers.module.css'
import Sidebar from './Sidebar/Sidebar'
import menuItems from './Sidebar/menuitems'
import OffersList from './OffersList/OffersList'
import CSVUpload from './CSVUpload/CSVUpload'

const DepositOffers: React.FC = () => {
  const items = Object.keys(menuItems)
  const [selectedMenuitem, setSelectedMenuitem] = useState(items[0])

  return (
    <div id={styles['deposit-offers']}>
      <Sidebar
        selectedMenuItem={selectedMenuitem}
        setSelectedMenuItem={setSelectedMenuitem}
      />

      {selectedMenuitem !== items[items.length - 1] ? (
        <OffersList selectedMenuitem={selectedMenuitem} />
      ) : null}

      {selectedMenuitem === items[items.length - 1] ? <CSVUpload /> : null}
    </div>
  )
}

export default DepositOffers
