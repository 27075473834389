import axios from 'axios'
import { consoleApiBaseUrl } from '../../../config'
import getAccessToken from '../../../helpers/access-token'

const addOrRemoveCollusionGroup = async (
  api: string,
  playerIds: string[],
  email: string
): Promise<string> => {
  const endpoint = `${consoleApiBaseUrl}/twirp/fraud.FraudEnforcer${api}`
  const data = {
    cluster: {
      player_ids: playerIds,
    },
    enforcer_name: email,
    ticket_id: '',
    evidence_map: {},
  }

  return axios(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(() => {
      return ''
    })
    .catch(err => {
      const msg = err?.response?.data?.msg
      return msg || 'Internal Error'
    })
}

export default addOrRemoveCollusionGroup
