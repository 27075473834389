import { takeLatest, take, put, fork, cancel } from 'redux-saga/effects'
import { DocumentData } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import { SyncPayoutProfile } from '../../../../actions/watson/player/payoutProfile/payoutProfile.types'
import {
  SYNC,
  CANCEL_SYNC,
  STORE,
} from '../../../../actions/watson/player/payoutProfile/ActionTypes'
import rsf from '../../../../firebase'
import { setLoadingPayoutProfile } from '../../../../actions/watson/player/payoutProfile/payoutProfile'

function* syncPayoutProfile(action: SyncPayoutProfile): Generator {
  yield put(setLoadingPayoutProfile())

  const { playerId } = action.payload

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncDocument,
    `players/${playerId}/private/payoutProfile`,
    {
      successActionCreator: (doc: DocumentData) => {
        return {
          type: STORE,
          payload: {
            payoutProfile: doc.data(),
          },
        }
      },
    }
  )

  yield take(CANCEL_SYNC)
  yield cancel(task as Task)
}

export default function* watchPayoutProfile(): Generator {
  yield takeLatest(SYNC, syncPayoutProfile)
}
