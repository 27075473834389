import { Auth } from './auth.types'
import {
  AuthAction,
  SetLoginInProgress,
  LoginSucess,
  LoginError,
  SetLogoutInProgress,
  LogoutError,
  SetAccessToken,
} from '../../actions/auth/auth.types'
import {
  SET_LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_LOGOUT_IN_PROGRESS,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_ACCESS_TOKEN,
} from '../../actions/auth/ActionTypes'

export const initialState: Auth = {
  user: null,
  accessToken: null,
  refreshToken: null,

  error: {
    login: null,
    logout: null,
  },

  meta: {
    isLoginInProgress: false,
    isLogoutInProgress: false,
  },
}

export default (state: Auth = initialState, action: AuthAction): Auth => {
  switch (action.type) {
    case SET_LOGIN_IN_PROGRESS: {
      const { isLoginInProgress } = (action as SetLoginInProgress).payload

      return {
        ...state,
        meta: {
          ...state.meta,
          isLoginInProgress,
        },
      }
    }

    case LOGIN_SUCCESS: {
      const { user } = (action as LoginSucess).payload

      return {
        user,
        // TODO: Fix tokens
        accessToken: user.xa || user.stsTokenManager?.accessToken || null,
        refreshToken: user.refreshToken || null,

        error: {
          login: null,
          logout: null,
        },

        meta: {
          isLoginInProgress: false,
          isLogoutInProgress: false,
        },
      }
    }

    case LOGIN_ERROR: {
      const error = (action as LoginError).payload

      return {
        ...state,
        error: {
          ...state.error,
          login: error,
        },
        meta: {
          ...state.meta,
          isLoginInProgress: false,
        },
      }
    }

    case SET_LOGOUT_IN_PROGRESS: {
      const { isLogoutInProgress } = (action as SetLogoutInProgress).payload

      return {
        ...state,
        meta: {
          ...state.meta,
          isLogoutInProgress,
        },
      }
    }

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    case LOGOUT_ERROR: {
      const error = (action as LogoutError).payload

      return {
        ...state,
        error: {
          ...state.error,
          logout: error,
        },
        meta: {
          ...state.meta,
          isLogoutInProgress: false,
        },
      }
    }

    case SET_ACCESS_TOKEN: {
      const { accessToken } = (action as SetAccessToken).payload

      return {
        ...state,
        accessToken,
      }
    }

    default:
      return state
  }
}
