import { takeLatest, put, fork, cancel, take } from 'redux-saga/effects'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import firebase from 'firebase/app'
import rsf from '../../../../firebase'
import {
  storeGameProfiles,
  loadingGameProfiles,
} from '../../../../actions/watson/player/gameProfiles/gameProfiles'
import {
  CANCEL_GAME_PROFILES_SYNC,
  SYNC_GAME_PROFILES,
} from '../../../../actions/watson/player/gameProfiles/ActionTypes'
import { SyncGameProfiles } from '../../../../actions/watson/player/gameProfiles/gameProfiles.types'
import { GameProfile } from '../../../../reducers/watson/player/gameProfiles/gameProfiles.types'

function* syncGameProfiles(action: SyncGameProfiles): Generator {
  yield put(loadingGameProfiles())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/gameProfiles`)

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const gameProfiles: GameProfile[] = []

        snapshot.forEach((doc: DocumentData) => {
          gameProfiles.push(doc.data())
        })

        return storeGameProfiles(gameProfiles)
      },
    }
  )

  yield take(CANCEL_GAME_PROFILES_SYNC)
  yield cancel(task as Task)
}

export default function* watchGameProfiles(): Generator {
  yield takeLatest(SYNC_GAME_PROFILES, syncGameProfiles)
}
