import firebase from 'firebase/app'

const getAccessToken = async (): Promise<string> => {
  const tokenResult = await firebase.auth().currentUser?.getIdTokenResult()
  return tokenResult?.token || ''
}

export default getAccessToken

export const getUserEmail = (): string => {
  const email = firebase.auth().currentUser?.email
  return email || ''
}
