import { useState, useEffect } from 'react'
import firebase from 'firebase/app'

const document = firebase.firestore().collection('config').doc('depositOffers')

const useConfig = () => {
  const [details, setDetails] = useState<string[]>([])
  const [attributeType, setAttributeType] = useState({})

  useEffect(() => {
    document.onSnapshot(
      docSnapshot => {
        const d = docSnapshot.get('Details')
        const at = docSnapshot.get('AttributeType')

        setDetails(d)
        setAttributeType(at)
      },
      err => {
        console.error(`Encountered error: ${err}`)
      }
    )
  }, [])

  return { details, attributeType }
}

export default useConfig
