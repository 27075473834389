const prefix = `@@auth`

export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`
export const SET_LOGIN_IN_PROGRESS = `${prefix}/SET_LOGIN_IN_PROGRESS`
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`
export const LOGIN_ERROR = `${prefix}/LOGIN_ERROR`

export const LOGOUT_REQUEST = `${prefix}/LOGOUT_REQUEST`
export const SET_LOGOUT_IN_PROGRESS = `${prefix}/SET_LOGOUT_IN_PROGRESS`
export const LOGOUT_SUCCESS = `${prefix}/LOGOUT_SUCCESS`
export const LOGOUT_ERROR = `${prefix}/LOGOUT_ERROR`

export const SET_ACCESS_TOKEN = `${prefix}/SET_ACCESS_TOKEN`
