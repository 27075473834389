import React, { Fragment } from 'react'
import styles from './StaggeredPreview.module.css'
import { StaggeredReward, Subtask } from '../../../types/StaggeredReward.types'

interface Props {
  staggeredReward: StaggeredReward | undefined
  showTitle?: boolean
  showTotal?: boolean
}

const StaggeredPreview: React.FC<Props> = ({
  staggeredReward,
  showTitle = true,
  showTotal = true,
}) => {
  if (!staggeredReward) return null

  const subTasks = staggeredReward.StaggeredProps?.Subtasks

  const getSubtaskRewardAmount = (s: Subtask): number =>
    s.RewardInfo?.FixedRewardProps?.RewardsProducts[0].CashRewardProps.Amount

  return (
    <div className={styles.StaggeredPreview}>
      {showTitle ? (
        <div className={styles['stp-title']}>
          {`${staggeredReward.TemplateId} Details`}
        </div>
      ) : null}

      <div className={styles.table}>
        <div className={styles.th}>Day</div>
        <div className={styles.th}>User Action To Perform</div>
        <div className={styles.th}>Reward Wallet</div>
        <div className={`${styles.th} ${styles.amount}`}>Reward Amount</div>
        <div className={styles.separator} />

        {subTasks?.map((s, i) => {
          let Destination = ''
          let Amount = 0
          if (
            s.RewardInfo?.FixedRewardProps?.RewardsProducts[0]?.Type === 'CASH'
          ) {
            ;({ Destination } =
              s.RewardInfo?.FixedRewardProps?.RewardsProducts[0].CashRewardProps)
            Amount = getSubtaskRewardAmount(s)
          }

          return (
            <Fragment key={`i-${i + 1}`}>
              <div className={styles.td}>{i + 1}</div>
              <div className={styles.td}>{s.Subtitle}</div>
              <div className={styles.td}>{Destination}</div>
              <div className={`${styles.td} ${styles.amount}`}>{Amount}</div>
              <div className={styles.separator} />
            </Fragment>
          )
        })}
      </div>

      {showTotal && (
        <div className={styles.total}>
          <span className={styles['total-text']}>Total</span>
          {subTasks.reduce(
            (sum: number, s) => sum + getSubtaskRewardAmount(s),
            0
          )}
        </div>
      )}
    </div>
  )
}

export default StaggeredPreview
