export const CreationIntent = ['GLOBAL', 'DEPOSIT_OFFERS']

export const AttributeCorrelation = ['AND', 'OR']

export const AUDIENCE_ATTRIBUTE_TYPES = [
  'global_attributes',
  'game_specific_attributes',
  'period_specific_attributes',
  'game_and_period_specific_attributes',
  'game_category_specific_attributes',
]

export const TIME_DURATION_TYPE = ['Exact', 'Relative']

export const MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE = 'MaxSimultaneousSessions'
