import {
  fork,
  take,
  cancel,
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects'
import { Task } from 'redux-saga'
import firebase from 'firebase/app'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'

import rsf from '../../../firebase'
import {
  CANCEL_ORDERS_SYNC,
  GET_ORDERS,
  FILTER_ORDERS,
  SYNC_ORDERS,
} from '../../../actions/watson/kyc/ActionTypes'
import { KycOrder, OrderState } from '../../../reducers/watson/kyc/kyc.types'
import {
  StoreOrders,
  GetOrders,
  FilterOrders,
} from '../../../actions/watson/kyc/kyc.types'
import {
  storeOrders,
  setLoadingKycOrders,
  storeFilteredOrders,
} from '../../../actions/watson/kyc/kyc'
import { AppState } from '../../../reducers/reducers'

const defaultPageSize = 25

const formatOrders = (
  snapshot: QuerySnapshot
): {
  orders: KycOrder[]
  lastDoc: DocumentData | null
} => {
  const orders: KycOrder[] = []
  let lastDoc: DocumentData | null = null

  snapshot.forEach((doc: DocumentData) => {
    const order = doc.data()
    order.id = doc.id
    orders.push(order as KycOrder)

    lastDoc = doc
  })

  return { orders, lastDoc }
}

const createStoreOrdersAction =
  (orderState: OrderState) =>
  (snapshot: QuerySnapshot): StoreOrders => {
    const { orders, lastDoc } = formatOrders(snapshot)

    return storeOrders({
      orders,
      orderState,
      lastDoc,
      lastPage:
        orders.length < defaultPageSize || orderState === 'Manual_Supervision',
    })
  }

function* sync(orderState: OrderState, pageSize?: number): Generator {
  yield put(setLoadingKycOrders(orderState))

  let query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')
    .where('State', '==', orderState)
    // sort it by UpdatedAt else accepted and rejected will not be sorted
    // index is not created on UpdatedAt, so sorting by CreatedAt for now
    // .orderBy('UpdatedAt', 'desc')
    .orderBy('CreatedAt', 'desc')

  if (pageSize) query = query.limit(pageSize)

  // eslint-disable-next-line
  // @ts-ignore
  yield call(rsf.firestore.syncCollection, query, {
    successActionCreator: createStoreOrdersAction(orderState),
  })
}

function* getOrders(action: GetOrders): Generator {
  const { orderState } = action.payload

  yield put(setLoadingKycOrders(orderState))

  const lastDoc = yield select(
    (state: AppState) => state.watson.kyc.meta[orderState].lastDoc
  )

  let query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')
    .where('State', '==', orderState)
    // sort it by UpdatedAt else accepted and rejected will not be sorted
    // index is not created on UpdatedAt, so sorting by CreatedAt for now
    // .orderBy('UpdatedAt', 'desc')
    .orderBy('CreatedAt', 'desc')
    .limit(defaultPageSize)

  if (lastDoc) query = query.startAfter(lastDoc)

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(rsf.firestore.getCollection, query)

  yield put(createStoreOrdersAction(orderState)(snapshot as QuerySnapshot))
}

function* syncOrders(): Generator {
  yield take(SYNC_ORDERS)

  const pendingOrdersTask = yield fork(sync, 'Manual_Supervision')
  const acceptedOrdersTask = yield fork(sync, 'Complete', defaultPageSize)
  const rejectedOrdersTask = yield fork(sync, 'Failed', defaultPageSize)

  yield take(CANCEL_ORDERS_SYNC)

  yield cancel(pendingOrdersTask as Task)
  yield cancel(acceptedOrdersTask as Task)
  yield cancel(rejectedOrdersTask as Task)
}

function* watchGetOrders(): Generator {
  yield takeLatest(GET_ORDERS, getOrders)
}

function* filterOrders(action: FilterOrders): Generator {
  const { selectedFilters } = action.payload

  yield put(setLoadingKycOrders('Complete'))
  yield put(setLoadingKycOrders('Failed'))
  yield put(setLoadingKycOrders('Manual_Supervision'))

  let query = firebase
    .firestore()
    .collection('central_orders')
    .where('Category', '==', 'Kyc')

  Object.keys(selectedFilters).forEach(key => {
    if (typeof selectedFilters[key] === 'string') {
      query = query.where(key, '==', selectedFilters[key])
    } else {
      query = query.where(
        key,
        '>=',
        new Date(selectedFilters[key][0].startOf('day').format())
      )
      query = query.where(
        key,
        '<=',
        new Date(selectedFilters[key][1].endOf('day').format())
      )
    }
  })

  // so we don't fetch everything by mistake
  query = query.limit(100)

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(rsf.firestore.getCollection, query)

  const { orders } = formatOrders(snapshot as QuerySnapshot)
  yield put(storeFilteredOrders(orders))
}

function* watchFilterOrders(): Generator {
  yield takeLatest(FILTER_ORDERS, filterOrders)
}

export default function* watchKycOrders(): Generator {
  yield fork(syncOrders)
  yield fork(watchGetOrders)
  yield fork(watchFilterOrders)
}
