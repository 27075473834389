import React, { useState } from 'react'
import moment from 'moment'
import styles from './OfferRow.module.css'
import MoreIcon from '../../../../assets/more.svg'
import LessIcon from '../../../../assets/less.svg'
import Timestamp from '../../../../components/Timestamp/Timestamp'
import CurrentOfferQuickActions from '../QuickActions/CurrentOfferQuickActions'
import OfferDetails from './OfferDetails'
import { Offer, OfferUsageMap } from '../Offer'
import getBudgetTypeAndValue from './get-budget-type-and-value'
import formatINR from '../../../../helpers/format-inr'

interface Props {
  offer: Offer
  offerUsage: OfferUsageMap
}

const Row: React.FC<Props> = ({ offer, offerUsage }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const { budgetType, budgetValue } = getBudgetTypeAndValue(offer)

  return (
    <>
      <div className={styles.value}>
        {!detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setDetailsExpanded(true)}
          />
        )}
        {detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setDetailsExpanded(false)}
          />
        )}
      </div>
      <div className={styles.value}>{offer.Name}</div>
      <div className={styles.value}>{offer.CouponCode}</div>
      <div className={styles.value}>{offer.Title}</div>
      <div className={styles.value}>
        {`${budgetType}: ${formatINR(budgetValue)}`}
      </div>
      <div className={styles.value}>
        {offerUsage &&
          offerUsage[offer.Id] &&
          offerUsage[offer.Id].BudgetTracker &&
          formatINR(
            offerUsage[offer.Id].BudgetTracker[
              budgetType === 'Usage' ? 'UsageCount' : budgetType
            ]
          )}
      </div>
      <div className={styles.value}>
        <Timestamp timestamp={moment(offer.T2.seconds * 1000)} />
      </div>
      <div className={styles.value}>
        <Timestamp timestamp={moment(offer.T3.seconds * 1000)} />
      </div>
      {offer.State === 'INACTIVE' ? (
        <div className={styles.value}>{offer.State}</div>
      ) : (
        <div className={styles['quick-actions']}>
          {offer.State === 'ACTIVE' && (
            <CurrentOfferQuickActions offer={offer} />
          )}
        </div>
      )}

      {detailsExpanded && <OfferDetails offer={offer} />}
    </>
  )
}

export default Row
