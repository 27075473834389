import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import { Audience } from './Offer'

const collectionRef = firebase.firestore().collection('audience')

const useAudience = (): Audience[] => {
  const [audience, setAudience] = useState<Audience[]>([])

  useEffect(() => {
    const unsubscribe = collectionRef.onSnapshot(snapshot => {
      const aud: Audience[] = []
      snapshot.forEach((doc: DocumentData) => {
        aud.push(doc.data())
      })
      setAudience(aud)
    })

    return (): void => {
      unsubscribe()
    }
  }, [])

  return audience
}

export default useAudience
