import React from 'react'
import styles from './AppDetails.module.css'
import { Profile } from '../../../../../../../reducers/watson/player/profile/profile.types'

interface Props {
  profile: Profile
}

const AppDetails: React.FC<Props> = props => {
  const { profile } = props

  return (
    <div className={styles['app-details']}>
      <div className={styles.title}>App Details</div>
      <div className={styles['player-id']}>
        <div className={styles.key}>PLAYER ID</div>
        <div className={styles.value}>{profile.PlayerId}</div>
      </div>
      <div className={styles['player-type']}>
        <div className={styles.key}>PLAYER TYPE</div>
        <div className={styles.value}>-</div>
      </div>
      <div className={styles['referred-by']}>
        <div className={styles.key}>REFERRED BY</div>
        <div className={styles.value}>-</div>
      </div>
      <div className={styles['app-version']}>
        <div className={styles.key}>APP VERSION</div>
        <div className={styles.value}>-</div>
      </div>
      <div className={styles['android-version']}>
        <div className={styles.key}>ANDROID VERSION</div>
        <div className={styles.value}>-</div>
      </div>
      <div className={styles['device-version']}>
        <div className={styles.key}>DEVICE VERSION</div>
        <div className={styles.value}>-</div>
      </div>
      <div className={styles['fraud-histoy']}>
        <div className={styles.key}>FRAUD HISTORY</div>
        <div className={styles.value}>-</div>
      </div>
    </div>
  )
}

export default AppDetails
