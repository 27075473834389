import React, { useState } from 'react'
import Viewer from 'react-viewer'
import styles from './Details.module.css'
import { KycDocumentData } from '../../../../../reducers/watson/kyc/kyc.types'
import CompleteIcon from '../../../../../assets/success.svg'
import FailedIcon from '../../../../../assets/cancelled.svg'
import DetailsByUser from './DetailsByUser'

interface Props {
  kycDetails: KycDocumentData
  orderId: string
  documentType: string
}

const Details: React.FC<Props> = props => {
  const { kycDetails, orderId, documentType } = props

  const [isViewerVisible, setIsViewerVisible] = useState(false)
  const [viewerActiveIndex, setViewerActiveIndex] = useState(0)
  const images = [{ src: kycDetails.file_urls?.front || '', alt: 'Front' }]
  if (kycDetails.file_urls?.back) {
    images.push({
      src: kycDetails.file_urls.back || '',
      alt: 'Back',
    })
  }

  const openViewer = (ai: number): void => {
    setViewerActiveIndex(ai)
    setIsViewerVisible(true)
  }

  return (
    <div id={styles.details}>
      <div className={styles.title}>Check Status</div>
      <div className={styles.checks}>
        <div className={styles.head}>CHECK NAME</div>
        <div className={styles.head}>STATUS</div>
        <div className={styles.head}>REASON</div>

        {kycDetails.checks_details?.map(check => {
          return (
            <React.Fragment key={check.check_name}>
              <div className={styles.body}>{check.check_name}</div>
              <div
                className={`${styles.body} ${
                  check.status === 'PASSED' ? styles.passed : styles.failed
                }`}
              >
                <img
                  src={check.status === 'PASSED' ? CompleteIcon : FailedIcon}
                  alt="state icon"
                  style={{ marginRight: '4px' }}
                />
                {check.status}
              </div>
              <div className={styles.body}>{check.failing_reason || '-'}</div>
            </React.Fragment>
          )
        })}
      </div>

      <div className={styles.title}>Uploaded Document</div>
      <div className={styles.images}>
        <div className={styles['image-container']}>
          <div>Front</div>
          <button
            type="button"
            onClick={(): void => openViewer(0)}
            className={styles['img-btn']}
          >
            <img
              src={kycDetails.file_urls?.front}
              alt="document front"
              className={styles['document-image']}
            />
          </button>
        </div>
        {kycDetails.file_urls?.back && (
          <div className={styles['image-container']}>
            <div>Back</div>
            <button
              type="button"
              onClick={(): void => openViewer(1)}
              className={styles['img-btn']}
            >
              <img
                src={kycDetails.file_urls?.back}
                alt="document back"
                className={styles['document-image']}
              />
            </button>
          </div>
        )}

        {kycDetails.file_urls && (
          <Viewer
            visible={isViewerVisible}
            activeIndex={viewerActiveIndex}
            rotatable
            zoomable
            images={images}
            onClose={(): void => setIsViewerVisible(false)}
            minScale={1}
          />
        )}
      </div>

      <div className={styles['details-by']}>
        <div className={styles['details-by-ocr']}>
          <div className={styles.title}>Details by the OCR</div>

          {kycDetails.details_by_ocr &&
            Object.keys(kycDetails.details_by_ocr).map(key => {
              return (
                <div key={key}>
                  <div className={styles.key}>{key.replace(/_/g, ' ')}</div>
                  <div className={styles.value}>
                    {kycDetails.details_by_ocr &&
                      kycDetails.details_by_ocr[key]}
                  </div>
                </div>
              )
            })}
        </div>

        <DetailsByUser
          kycDetails={kycDetails}
          orderId={orderId}
          documentType={documentType}
        />
      </div>
    </div>
  )
}

export default Details
