import {
  cancel,
  cancelled,
  put,
  takeEvery,
  all,
  call,
  take,
  fork,
} from 'redux-saga/effects'
import firebase from 'firebase/app'
import 'firebase/auth'

import rsf from '../../firebase'
import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../../actions/auth/ActionTypes'
import * as actions from '../../actions/auth/auth'

const authProvider = new firebase.auth.GoogleAuthProvider().addScope('email')

const GETMEGA_DOMAIN = 'getmega.com'

export function* logoutSaga(): Generator {
  try {
    yield call(rsf.auth.signOut)
    yield put(actions.logoutSuccess())
  } catch (error) {
    yield put(actions.logoutError(error))
  }
}

export function* getUser(
  idToken: string
): Generator<Record<string, any>, void, any> {
  let credential
  try {
    credential = firebase.auth.GoogleAuthProvider.credential(idToken)

    const user = yield call(rsf.auth.signInWithCredential, credential)

    // cancel login if email id is not from getmega.com
    if (user.additionalUserInfo.profile.hd !== GETMEGA_DOMAIN) {
      yield cancel(user)
    }

    // update email if email not present at root level
    if (!user.user.email) {
      yield call(rsf.auth.updateEmail, user.additionalUserInfo.profile.email)
    }

    yield put(actions.loginSuccess(user.user))
  } catch (error) {
    yield put(actions.loginError(error))
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga)
    }
  }
}

export function* getIdToken(provider: {
  providerId: string
}): Generator<Record<string, any>, void, any> {
  try {
    const data = yield call(rsf.auth.signInWithPopup, provider)
    yield call(getUser, data.idToken)
  } catch (error) {
    yield put(actions.loginError(error))
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga)
    }
  }
}

function* loginSaga(): Generator {
  yield fork(getIdToken, authProvider)
}

/**
 * check if user is logged in when reloading page
 */
export function* checkIfLoggedInSaga(): Generator<
  Record<string, any>,
  void,
  any
> {
  yield put(actions.setLoginInProgress(true))

  const channel = yield call(rsf.auth.channel)

  while (true) {
    const { user } = yield take(channel)

    if (
      user?.email?.endsWith(GETMEGA_DOMAIN) ||
      user?.providerData[0]?.email?.endsWith(GETMEGA_DOMAIN)
    ) {
      yield put(actions.loginSuccess(user))
    } else {
      // token not available/expired, need to login again
      yield put(actions.loginError(new Error()))
    }
  }
}

function* watchLogin(): Generator {
  yield takeEvery(LOGIN_REQUEST, loginSaga)
}

function* watchLogout(): Generator {
  yield takeEvery(LOGOUT_REQUEST, logoutSaga)
}

export default function* authSaga(): Generator {
  yield fork(checkIfLoggedInSaga)
  yield all([watchLogin(), watchLogout()])
}
