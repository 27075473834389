import React from 'react'
import styles from '../InstantContestJoin.module.css'
import useSyncFirestoreDocument from '../../../../../../hooks/useSyncFirestoreDocument'
import { Offer } from '../../../../../../types/Offer.types'
import StaggeredDetails from './StaggeredDetails'

interface Props {
  offerId: string
  orderAmount: number
}

const CouponDetails: React.FC<Props> = ({ offerId, orderAmount }) => {
  const offer = useSyncFirestoreDocument<Offer | null>(`offers/${offerId}`)
  return (
    <>
      <div className={styles.key}>COUPON CODE</div>
      <div className={styles.value}>{offer?.CouponCode}</div>

      {offer?.Rewards[0].Type === 'CASH' ? (
        <div className={styles.couponDetails}>
          <div className={styles.th}>DAY</div>
          <div className={styles.th}>USER ACTION</div>
          <div className={styles.th}>INSTRUMENT</div>
          <div className={styles.th}>VALUE</div>

          <div className={styles.td}>1</div>
          <div className={styles.td}>None</div>
          <div className={styles.td}>{offer?.Rewards[0].Cash?.Destination}</div>
          <div className={styles.td}>
            {Math.min(
              ((offer?.Rewards[0].Cash?.PercentValue || 0) * orderAmount) / 100,
              offer?.Rewards[0].Cash?.MaximumAmount || 0
            )}
          </div>
        </div>
      ) : null}

      {offer?.Rewards[0].Type === 'STAGGERED' ? (
        <StaggeredDetails
          templateId={offer?.Rewards[0].Staggered?.TemplateId || ''}
        />
      ) : null}
    </>
  )
}

export default CouponDetails
