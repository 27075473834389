import { takeLatest, put, fork, take, cancel } from 'redux-saga/effects'
import firebase from 'firebase/app'
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import rsf from '../../../../firebase'
import {
  SYNC_PASS_TRANSACTIONS,
  CANCEL_PASS_TRANSACTIONS_SYNC,
} from '../../../../actions/watson/player/passTransactions/ActionTypes'
import {
  setLoadingPassTransactions,
  storePassTransactions,
} from '../../../../actions/watson/player/passTransactions/passTransactions'
import { SyncPassTransactions } from '../../../../actions/watson/player/passTransactions/passTransactions.types'
import { PassTransaction } from '../../../../reducers/watson/player/passTransactions/passTransactions.types'

function* syncPassTransactions(action: SyncPassTransactions): Generator {
  yield put(setLoadingPassTransactions())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/private/encompass/passTransactions`)
    .orderBy('CreatedAt', 'desc')

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const passTransactions: PassTransaction[] = []

        snapshot.forEach((doc: DocumentData) => {
          passTransactions.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        return storePassTransactions(passTransactions)
      },
    }
  )

  yield take(CANCEL_PASS_TRANSACTIONS_SYNC)
  yield cancel(task as Task)
}

export default function* watchPassTransactions(): Generator {
  yield takeLatest(SYNC_PASS_TRANSACTIONS, syncPassTransactions)
}
