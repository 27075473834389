import {
  takeLatest,
  call,
  put,
  fork,
  take,
  cancel,
  all,
  select,
} from 'redux-saga/effects'
import firebase from 'firebase/app'
import axios from 'axios'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import rsf from '../../../../firebase'
import {
  GET_ALL_ORDERS,
  CANCEL_ALL_ORDERS_SYNC,
  SYNC_ALL_ORDERS,
  GET_CF_SESSIONS,
} from '../../../../actions/watson/player/orders/ActionTypes'
import {
  GetAllOrders,
  SyncAllOrders,
  GetCfSessions,
} from '../../../../actions/watson/player/orders/orders.types'
import {
  Order,
  OrdersReducer,
  CFSession,
  CfSessionsMeta,
} from '../../../../reducers/watson/player/orders/orders.types'
import {
  storeOrders,
  setLoadingAllOrders,
  storeCfSessions,
} from '../../../../actions/watson/player/orders/orders'
import { AppState } from '../../../../reducers/reducers'
import getAccessToken from '../../../../helpers/access-token'

const limit = 25

export const getCfSessions = async ({
  playerId,
  after,
  accessToken,
  pageNumber,
}: {
  playerId: string
  after?: string
  accessToken: string
  pageNumber?: number
}): Promise<CFSession[]> => {
  const apiEndpoint = `${process.env.REACT_APP_FETCH_URL}/twirp/pb.WatsonCfManager/GetCfSessionsList`

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: {
      player_id: playerId,
      ...(after && { start_after: after }),
      batch_size: 200,
      ...(pageNumber && { batch_number: pageNumber }),
    },
  })
    .then(response => {
      const { data } = response
      return data.sessions_list
    })
    .catch(error => {
      console.error(error)
    })
}

export function* getAllOrders(action: GetAllOrders): Generator {
  yield put(setLoadingAllOrders())

  const { playerId, category } = action.payload
  const orderState = (yield select(
    (state: AppState) => state.watson.player.orders
  )) as OrdersReducer

  let query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .orderBy('CreatedAt', 'desc')
    .limit(limit)

  if (orderState.meta[category].lastDoc) {
    query = query.startAfter(orderState.meta[category].lastDoc)
  }

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(rsf.firestore.getCollection, query)

  const accessToken = yield select((state: AppState) => state.auth.accessToken)
  const cfSessionsMeta = yield select(
    (state: AppState) => state.watson.player.orders.meta.cfSessions
  )

  if (process.env.REACT_APP_ENV !== 'staging') {
    const cfSessions = yield call(getCfSessions, {
      playerId,
      after: (cfSessionsMeta as CfSessionsMeta).lastDoc || '',
      accessToken: accessToken as string,
      pageNumber: (cfSessionsMeta as CfSessionsMeta).pageNumber,
    })
    if (cfSessions) yield put(storeCfSessions(cfSessions as CFSession[]))
  }

  const orders: Order[] = []
  let lastDoc: DocumentData | undefined
  ;(snapshot as QuerySnapshot).forEach((doc: DocumentData) => {
    orders.push({
      ...doc.data(),
      id: doc.id,
    })

    lastDoc = doc
  })

  const lastPage = orders.length < limit

  yield put(storeOrders(orders, category, lastPage, lastDoc))
}

export function* syncAllOrders(action: SyncAllOrders): Generator {
  yield put(setLoadingAllOrders())

  const { playerId, category } = action.payload
  const hasLastDoc = select(
    (state: AppState) =>
      state.watson.player.orders.meta[category].lastDoc !== null
  )

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .orderBy('CreatedAt', 'desc')
    .limit(limit)

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const orders: Order[] = []
        let lastDoc: DocumentData | undefined

        snapshot.forEach((doc: DocumentData) => {
          orders.push({
            ...doc.data(),
            id: doc.id,
          })

          lastDoc = doc
        })

        const lastPage = orders.length < limit
        if (hasLastDoc) lastDoc = undefined

        return storeOrders(orders, category, lastPage, lastDoc)
      },
    }
  )

  const accessToken = yield select((state: AppState) => state.auth.accessToken)

  if (process.env.REACT_APP_ENV !== 'staging') {
    const cfSessions = yield call(getCfSessions, {
      playerId,
      after: '',
      accessToken: accessToken as string,
    })
    if (cfSessions) yield put(storeCfSessions(cfSessions as CFSession[]))
  }

  yield take(CANCEL_ALL_ORDERS_SYNC)
  yield cancel(task as Task)
}

export function* fetchCfSessions(action: GetCfSessions): Generator {
  const { playerId } = action.payload
  const accessToken = yield select((state: AppState) => state.auth.accessToken)

  try {
    const cfSessions = yield call(getCfSessions, {
      playerId,
      accessToken: accessToken as string,
    })
    if (cfSessions) yield put(storeCfSessions(cfSessions as CFSession[]))
  } catch (error) {
    console.error(error)
  }
}

export function* watchSyncAllOrders(): Generator {
  yield takeLatest(SYNC_ALL_ORDERS, syncAllOrders)
}

export function* watchGetAllOrders(): Generator {
  yield takeLatest(GET_ALL_ORDERS, getAllOrders)
}

export function* watchGetCfSessions(): Generator {
  yield takeLatest(GET_CF_SESSIONS, fetchCfSessions)
}

export default function* watch(): Generator {
  yield all([watchGetAllOrders(), watchSyncAllOrders(), watchGetCfSessions()])
}
