import React from 'react'
import styles from './Details.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import InstantContestJoin from './InstantContestJoin'
import Winning from './Winning'
import Withdrawal from './Withdrawal'
import Deposit from './Deposit'
import Reward from './Reward'
import CFOrder from './CFOrder'

interface Props {
  order: Order
}

const Details: React.FC<Props> = props => {
  const { order } = props
  let OrderDetailsComponent

  if (order.Category === 'Purchase' && order.Product.Type === 'ROOM_TICKET') {
    OrderDetailsComponent = InstantContestJoin
  } else if (
    order.Category === 'Purchase' &&
    order.Product.Type === 'TABLE_TICKET'
  ) {
    OrderDetailsComponent = CFOrder
  } else if (order.Category === 'Winning') {
    OrderDetailsComponent = Winning
  } else if (order.Category === 'Withdrawal') {
    OrderDetailsComponent = Withdrawal
  } else if (
    order.Category === 'Deposit' ||
    (order.Category === 'Purchase' && order.Product.Type === 'GEM_PACK')
  ) {
    OrderDetailsComponent = Deposit
  } else if (order.Category === 'Reward') {
    OrderDetailsComponent = Reward
  }

  return (
    <div className={styles['order-details']}>
      {OrderDetailsComponent ? (
        <OrderDetailsComponent order={order} />
      ) : (
        <span>Order Details</span>
      )}
    </div>
  )
}

export default Details
