import { combineReducers } from 'redux'

import { Auth } from './auth/auth.types'
import auth from './auth/auth'
import watson, { Watson } from './watson/watson'

export interface AppState {
  auth: Auth
  watson: Watson
}

const rootReducer = combineReducers({
  auth,
  watson,
})

export default rootReducer
