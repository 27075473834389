import React, { useState, ChangeEvent } from 'react'
import { Input, DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import styles from './Details.module.css'
import { KycDocumentData } from '../../../../../reducers/watson/kyc/kyc.types'
import AcceptReject from './AcceptReject'

interface Props {
  kycDetails: KycDocumentData
  orderId: string
  documentType: string
}

const DetailsByUser: React.FC<Props> = props => {
  const { kycDetails, orderId, documentType } = props
  const [editedDetails, setEditedDetails] = useState(kycDetails.details_by_user)

  return (
    <>
      <div className={styles['details-by-user']}>
        <div className={styles.title}>Details by the User</div>

        <div>
          <div className={styles.key}>NAME ON CARD</div>
          <div className={styles.value}>
            <Input
              placeholder="Name on Card"
              value={editedDetails?.name_on_card}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setEditedDetails({
                  ...editedDetails,
                  name_on_card: e.currentTarget.value,
                })
              }}
            />
          </div>
        </div>

        <div>
          <div className={styles.key}>CARD NUMBER</div>
          <div className={styles.value}>
            <Input
              placeholder="Card Number"
              value={editedDetails?.card_number}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setEditedDetails({
                  ...editedDetails,
                  card_number: e.currentTarget.value,
                })
              }}
            />
          </div>
        </div>

        <div>
          <div className={styles.key}>DATE OF BIRTH</div>
          <div className={styles.value}>
            <DatePicker
              value={moment(editedDetails?.dob)}
              format="YYYY-MM-DD"
              onChange={(date: Moment | null, dateString: string): void => {
                if (date) {
                  setEditedDetails({
                    ...editedDetails,
                    dob: dateString,
                  })
                }
              }}
            />
          </div>
        </div>
      </div>

      {editedDetails && (
        <AcceptReject
          cardDetails={editedDetails}
          orderId={orderId}
          documentType={documentType}
        />
      )}
    </>
  )
}

export default DetailsByUser
