import React, { useEffect } from 'react'
import { RightCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import { getCfSessions } from '../../../../../actions/watson/player/orders/orders'
import { AppState } from '../../../../../reducers/reducers'
import styles from './GameHistory.module.css'

const GameHistory: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const cfSessions = useSelector(
    (state: AppState) => state.watson.player.orders.cfSessions
  )

  useEffect(() => {
    if (playerId) dispatch(getCfSessions(playerId))
  }, [playerId, dispatch])

  return (
    <div id={styles.GameHistory}>
      <div className={styles.title}>
        <span>Game History</span>
      </div>

      <div className={styles.head}>SESSION ID</div>
      <div className={styles.head}>GAME</div>
      <div className={styles.head}>DATE & TIME</div>
      <div className={styles.head}>BUY IN</div>
      <div className={styles.head}>WINNINGS</div>
      <div className={styles.head}>SESSION STATUS</div>
      <div className={styles.head} />

      {Object.values(cfSessions).map(s => {
        return (
          <>
            <div className={styles.value} key={s.session_id}>
              {s.session_id}
            </div>
            <div className={classNames(styles.value, styles.Game)}>
              <div className={styles.GameIcon}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_KIT_URL}/${s.game.icon}`}
                  alt={s.game?.name}
                />
              </div>
              <div className={styles.GameName}>{s.game?.name}</div>
            </div>
            <div className={styles.value}>
              {moment(s.money_in.sit_in_time).format('Do MMM • h:mm A')}
            </div>
            <div className={styles.value}>₹{s.money_in.buy_in_amount}</div>
            <div className={styles.value}>
              {s.money_out.winnings_amount
                ? `₹${s.money_out.winnings_amount}`
                : ''}
            </div>
            <div
              className={classNames(
                styles.value,
                s.is_cashed_out ? styles.StatusComplete : styles.StatusOngoing
              )}
            >
              {s.is_cashed_out ? 'Complete' : 'Ongoing'}
            </div>
            <div className={styles.value}>
              <RightCircleFilled className={styles.RightArrow} />
            </div>
          </>
        )
      })}
    </div>
  )
}

export default GameHistory
