import React, { useState } from 'react'
import { Input, DatePicker, Select, Button } from 'antd'
import styles from './FirestoreFilter.module.css'

const { RangePicker } = DatePicker
const { Option } = Select

export interface SelectedFilters {
  [index: string]: any
}

export type Options = (string | number)[]

export interface Filter {
  field: string
  title?: string
  filterType: 'dateRange' | 'select' | 'input'
  options?: Options
}

export interface FirestoreFilterProps {
  filters: Filter[]
  // callback when apply filter is clicked
  applyFilter(selectedFilter: SelectedFilters): void
  // already applied filters
  selectedFilter?: SelectedFilters
}

const FirestoreFilter: React.FC<FirestoreFilterProps> = props => {
  const { filters, applyFilter: callback, selectedFilter: sf } = props
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    sf || {}
  )

  const cleanSelectedFilters = (): SelectedFilters => {
    const f = selectedFilters
    Object.keys(f).forEach(field => {
      if (selectedFilters[field] === null) Reflect.deleteProperty(f, field)
    })

    return f
  }

  const applyFilters = (): void => {
    callback(cleanSelectedFilters())
  }

  const clearFilters = (): void => {
    setSelectedFilters({})
    callback({})
  }

  return (
    <div className={styles['firestore-filters']}>
      <div className={styles.filters}>
        {filters.map((filter, i) => {
          return (
            <div
              key={filter.field}
              className={`${styles['filter-div']} filter-div ${
                i === filters.length - 1 ? styles.last : ''
              }`}
            >
              <div className={styles.field}>{filter.title || filter.field}</div>
              {filter.filterType === 'input' && (
                <Input
                  placeholder={`${filter.title || filter.field}`}
                  value={selectedFilters[filter.field]}
                  allowClear
                  onChange={(e): void => {
                    setSelectedFilters({
                      ...selectedFilters,
                      [filter.field]: e.currentTarget.value || null,
                    })
                  }}
                />
              )}

              {filter.filterType === 'dateRange' && (
                <RangePicker
                  format="ll"
                  value={selectedFilters[filter.field] || [null, null]}
                  onChange={(dates): void => {
                    setSelectedFilters({
                      ...selectedFilters,
                      [filter.field]: dates,
                    })
                  }}
                />
              )}

              {filter.filterType === 'select' && (
                <Select
                  value={selectedFilters[filter.field] || ''}
                  allowClear
                  onChange={(value): void => {
                    setSelectedFilters({
                      ...selectedFilters,
                      [filter.field]: value || null,
                    })
                  }}
                >
                  {filter.options?.map(o => {
                    return (
                      <Option key={o} value={o}>
                        {o}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </div>
          )
        })}
      </div>

      <div className={styles.actions}>
        <Button
          type="link"
          style={{ paddingLeft: 0 }}
          onClick={applyFilters}
          disabled={Object.keys(cleanSelectedFilters()).length === 0}
        >
          Apply
        </Button>
        <Button
          type="link"
          danger
          onClick={clearFilters}
          disabled={Object.keys(cleanSelectedFilters()).length === 0}
        >
          Clear
        </Button>
      </div>
    </div>
  )
}

export default FirestoreFilter
