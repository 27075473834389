import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { KycOrdersMap } from '../../../reducers/watson/kyc/kyc.types'
import { syncOrders } from '../../../actions/watson/kyc/kyc'

const useSyncKycOrders = (orders: KycOrdersMap): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    // if its > 0, sync is already started
    if (Object.keys(orders).length === 0) dispatch(syncOrders())
  }, [dispatch, orders])
}

export default useSyncKycOrders
