import axios from "axios";
import { consoleApiBaseUrl } from "../../../../../config";
import getAccessToken, { getUserEmail } from "../../../../../helpers/access-token";

export const getCaseHistory = async (playerId: string): Promise<any> => {
  const endpoint = `${consoleApiBaseUrl}/twirp/fraud.collusion.v1.Collusion/CaseHistory`
  const data = {
    player_id: playerId,
  }

  return axios(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(response => {
      return response.data
    })
    .catch(err => {
      const msg = err?.response?.data?.msg
      return {
        error: msg || 'Error fetching cases',
      }
    })
}

interface Action {
  withdrawal?: boolean
  deposit?: boolean
  gameplay: boolean
  rewards?: boolean
  moonshot?: boolean
  account?: boolean
}

interface Reason {
  title?: string
  description?: string
  remark?: string
}

interface BlockPlatformActionsForUserProps {
  playerId: string
  blockActions: Action
  reason: Reason
  source: string
  referenceIds: string[]
}
export const BlockPlatformActionsForUser = async ({
  playerId,
  blockActions,
  reason,
  source,
  referenceIds,
}: BlockPlatformActionsForUserProps): Promise<string> => {
  const endpoint = `${consoleApiBaseUrl}/twirp/fraud.FraudEnforcer/BlockPlatformActionsForUser`
  const data = {
    player_id: playerId,
    block_actions: blockActions,
    reason,
    source,
    enforcer: getUserEmail(),
    reference_ids: referenceIds,
  }

  return axios(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(() => {
      return ''
    })
    .catch(err => {
      const msg = err?.response?.data?.msg
      return msg || 'Error blocking'
    })
}

export const UnblockPlatformActionsForUser = async ({
  playerId,
  blockActions: unblockActions,
  reason,
  source,
  referenceIds,
}: BlockPlatformActionsForUserProps): Promise<string> => {
  const endpoint = `${consoleApiBaseUrl}/twirp/fraud.FraudEnforcer/UnBlockPlatformActionsForUsers`
  const data = {
    player_ids: [playerId],
    unblock_actions: unblockActions,
    reason,
    source,
    enforcer: getUserEmail(),
    reference_ids: referenceIds,
  }

  return axios(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then(() => {
      return ''
    })
    .catch(err => {
      const msg = err?.response?.data?.msg
      return msg || 'Error blocking'
    })
}

export const BlockReasonDescriptionMapping: { [key: string]: string[] } = {
  Alerts: [
    'Participated in atleast 3 activities signaling fraudulent behaviour',
    'High withdrawal to deposit ratio',
    'Circulation of money with user related to the same android ID, adv. ID, payout ID and referral ID',
    'Circulation of money with user from another group',
    'Losing/winning high amount from user of the same city',
  ],
  'CS Request': [
    'Suspicious Deposit',
    'Customer requested to discontinue his/her account',
    'Received complaint against the player',
  ],
  'Auto blocked': [
    'Geolocation marked as fraud',
    'Multiple deposit transaction failures',
    'Rummy Card Pick up',
  ],
  'Signal triggered': [
    'Rummy Money Flow',
    'Poker Money Flow',
    'Dead Showdown poker',
    'Seeder Account',
  ],
  'Seeder Account': ['Seeder Account'],
  Other: ['Other'],
}
