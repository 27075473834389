import { AddressReducer } from './address.types'
import {
  AddressAction,
  StoreAddress,
  StoreAddressPayload,
} from '../../../../actions/watson/player/address/address.types'
import {
  SET_LOADING_ADDRESS,
  STORE_ADDRESS,
} from '../../../../actions/watson/player/address/ActionTypes'

const initialState: AddressReducer = {
  addresses: [],
  error: null,
  meta: { loading: false },
}

const addressReducer = (
  state = initialState,
  action: AddressAction
): AddressReducer => {
  switch (action.type) {
    case SET_LOADING_ADDRESS:
      return {
        addresses: [],
        error: null,
        meta: { loading: true },
      }

    case STORE_ADDRESS: {
      const { payload, error } = action as StoreAddress

      if (!error) {
        const { addresses } = payload as StoreAddressPayload

        return {
          addresses,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        addresses: [],
        error: payload as Error,
        meta: { loading: false },
      }
    }
    default:
      return state
  }
}

export default addressReducer
