import { Cf, CfSessionsMap, CfSession } from './cfSession.types'
import {
  STORE_SESSIONS,
  SET_LOADING_CF_SESSIONS,
  STORE_FILTERED_SESSIONS,
} from '../../../actions/watson/cfSession/ActionTypes'
import {
  CfActions,
  StoreSessions,
  StoreSessionsPayload,
  SetLoadingCfSessions,
  StoreFilteredSessions,
  StoreFilteredSessionsPayload,
} from '../../../actions/watson/cfSession/cfSession.types'

const initialState: Cf = {
  sessions: {},
  error: {},
  meta: {
    Active: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    CashedOut: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    Ended: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
  },
  filteredSessions: [],
}

export const getMapFromArray = (
  arr: CfSession[],
  sessionsMap: CfSessionsMap = {}
): CfSessionsMap => {
  return arr.reduce((om: CfSessionsMap, item) => {
    const map = om
    map[item.id] = item

    return map
  }, sessionsMap)
}

const cfReducer = (state = initialState, action: CfActions): Cf => {
  switch (action.type) {
    case SET_LOADING_CF_SESSIONS: {
      const { sessionState } = (action as SetLoadingCfSessions).payload

      return {
        ...state,
        meta: {
          ...state.meta,
          [sessionState]: {
            ...state.meta[sessionState],
            loading: true,
          },
        },
      }
    }

    case STORE_SESSIONS: {
      const { payload, error, meta } = action as StoreSessions
      const { sessionState, lastPage, lastDoc } = meta

      if (!error) {
        const { sessions } = payload as StoreSessionsPayload
        let sessionsMap: CfSessionsMap = state.sessions
        if (sessionState === 'Active') {
          sessionsMap = Object.values(state.sessions).reduce(
            (prevValue: CfSessionsMap, session): CfSessionsMap => {
              const om = prevValue
              if (session.State !== 'Active') {
                om[session.id] = session
              }
              return om
            },
            {}
          )
        }

        return {
          ...state,
          sessions: getMapFromArray(sessions, { ...sessionsMap }),
          meta: {
            ...state.meta,
            [sessionState]: {
              ...state.meta[sessionState],
              loading: false,
              lastPage,
              lastDoc,
            },
          },
        }
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          [sessionState]: {
            ...state.meta[sessionState],
            loading: false,
          },
        },
      }
    }

    case STORE_FILTERED_SESSIONS: {
      const { payload, error } = action as StoreFilteredSessions

      if (!error) {
        const { filteredSessions } = payload as StoreFilteredSessionsPayload

        return {
          ...state,
          filteredSessions,
          meta: {
            ...state.meta,
            Active: {
              ...state.meta.Active,
              loading: false,
            },
            CashedOut: { ...state.meta.CashedOut, loading: false },
            Ended: { ...state.meta.Ended, loading: false },
          },
        }
      }

      return state
    }

    default:
      return state
  }
}

export default cfReducer
