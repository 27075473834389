import React, { useState } from 'react'
import styles from './StaggeredRewardTemplateRow.module.css'
import MoreIcon from '../../../assets/more.svg'
import LessIcon from '../../../assets/less.svg'
import { StaggeredReward, Subtask } from '../../../types/StaggeredReward.types'
import StaggeredDetails from './StaggeredDetails'

interface Props {
  template: StaggeredReward
}

const StaggeredRewardTemplateRow: React.FC<Props> = ({ template }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  const subTasks = template.StaggeredProps?.Subtasks

  const getSubtaskRewardAmount = (s: Subtask): number =>
    s.RewardInfo?.FixedRewardProps?.RewardsProducts[0].CashRewardProps.Amount

  return (
    <>
      <div className={styles.value}>
        {!detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setDetailsExpanded(true)}
          />
        )}
        {detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setDetailsExpanded(false)}
          />
        )}
      </div>
      <div className={styles.value}>{template.TemplateId}</div>
      <div className={styles.value}>
        {subTasks.reduce(
          (sum: number, s) => sum + getSubtaskRewardAmount(s),
          0
        )}
      </div>
      <div className={styles.value}>
        {template.TenureDetails?.TenureInMinutes / (60 * 24)}
      </div>

      {detailsExpanded && <StaggeredDetails staggeredReward={template} />}
    </>
  )
}

export default StaggeredRewardTemplateRow
