import React, { KeyboardEvent } from 'react'

import styles from './Sidebar.module.css'
import { CfSessionState } from '../../../reducers/watson/cfSession/cfSession.types'

interface Props {
  selectedSessionState: CfSessionState
  setSelectedSessionState(sos: CfSessionState): void
  pendingSessionsCount: number
}

const Sidebar: React.FC<Props> = props => {
  const {
    selectedSessionState,
    setSelectedSessionState,
    pendingSessionsCount,
  } = props

  const getClassName = (sessionState: CfSessionState): string =>
    `${styles['menu-item']} ${
      selectedSessionState === sessionState ? styles.selected : ''
    }`

  return (
    <div id={styles.sidebar}>
      <div
        className={getClassName('Active')}
        onClick={(): void => setSelectedSessionState('Active')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedSessionState('Active')
          }
        }}
      >
        {`Active (${pendingSessionsCount})`}
      </div>
      <div
        className={getClassName('CashedOut')}
        onClick={(): void => setSelectedSessionState('CashedOut')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedSessionState('CashedOut')
          }
        }}
      >
        Cashed Out
      </div>
      <div
        className={getClassName('Ended')}
        onClick={(): void => setSelectedSessionState('Ended')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedSessionState('Ended')
          }
        }}
      >
        Ended
      </div>
    </div>
  )
}

export default Sidebar
