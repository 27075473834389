import { PayoutProfileReducer } from './payoutProfile.types'
import {
  PayoutProfileAction,
  StorePayoutProfilePayload,
  StorePayoutProfile,
} from '../../../../actions/watson/player/payoutProfile/payoutProfile.types'
import {
  SET_LOADING,
  STORE,
} from '../../../../actions/watson/player/payoutProfile/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: PayoutProfileReducer = {
  payoutProfile: null,
  error: null,
  meta: {
    loading: false,
  },
}

const payoutProfileReducer = (
  state = initialState,
  action: PayoutProfileAction
): PayoutProfileReducer => {
  switch (action.type) {
    case SET_LOADING:
      return {
        payoutProfile: null,
        error: null,
        meta: { loading: true },
      }

    case STORE: {
      const { payload, error } = action as StorePayoutProfile

      if (!error) {
        const { payoutProfile } = payload as StorePayoutProfilePayload

        return {
          payoutProfile,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        payoutProfile: null,
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default payoutProfileReducer
