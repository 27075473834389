import React from 'react'
import styles from './StaggeredTemplateList.module.css'
import useStaggeredTemplates from '../DepositOffers/useStaggeredTemplates'
import TemplateRow from './StaggeredRewardTemplateRow'

const StaggeredTemplateList: React.FC = () => {
  const staggeredTemplates = useStaggeredTemplates()

  return (
    <div id={styles.rewardsList}>
      <div className={styles.header}>
        <div className={styles.title}>Staggered Rewards</div>
      </div>

      <div />
      <div className={`${styles.head} ${styles.first}`}>NAME</div>
      <div className={styles.head}>Total Reward Amount</div>
      <div className={styles.head}>Duration</div>

      {staggeredTemplates.map(template => {
        return <TemplateRow key={template.TemplateId} template={template} />
      })}

      {staggeredTemplates.length === 0 && (
        <div className={styles.noRewards}>No Offers</div>
      )}
    </div>
  )
}

export default StaggeredTemplateList
