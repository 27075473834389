import React, { useEffect } from 'react'
import { Button } from 'antd'
import { GoogleCircleFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styles from './Login.module.css'
import { AppState } from '../../reducers/reducers'
import { loginRequest } from '../../actions/auth/auth'

const Login: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { auth } = useSelector((state: AppState) => state)
  const isAuthenticated = auth.user !== null
  const { isLoginInProgress } = auth.meta
  const { login: loginError } = auth.error

  useEffect(() => {
    // go to dashboards if already logged in
    if (isAuthenticated) history.push('/')
  })

  const login = (): void => {
    dispatch(loginRequest())
  }

  return (
    <div id={styles.login}>
      <h2>Welcome to Watson</h2>
      <div>
        <Button
          type="primary"
          icon={<GoogleCircleFilled />}
          onClick={login}
          loading={isLoginInProgress}
        >
          Login with Google
        </Button>
      </div>
      {loginError ? (
        <div className={styles.loginError}>{loginError.message}</div>
      ) : null}
    </div>
  )
}

export default Login
