import { all } from 'redux-saga/effects'

import profile from './profile/profile'
import wallets from './wallets/wallets'
import kyc from './kyc/kyc'
import orders from './orders/orders'
import payoutProfile from './payoutProfile/payoutProfile'
import passes from './passes/passes'
import referrls from './referrals/referrals'
import gameProfiles from './gameProfiles/gameProfiles'
import passTransactions from './passTransactions/passTransactions'
import address from './address/address'
import timeline from './timeline/timeline'

export default function* watchPlayer(): Generator {
  yield all([
    profile(),
    wallets(),
    kyc(),
    orders(),
    payoutProfile(),
    passes(),
    referrls(),
    gameProfiles(),
    passTransactions(),
    address(),
    timeline(),
  ])
}
