import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../AllTransactions/AllTransactions.module.css'
import Row from '../AllTransactions/Row'
import { AppState } from '../../../../../reducers/reducers'
import { getAllOrders } from '../../../../../actions/watson/player/orders/orders'
import filter from '../WinningWallet/filter'
import usePagination from '../AllTransactions/usePagination'

const DepositWallet: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { meta, orders } = useSelector(
    (state: AppState) => state.watson.player.orders
  )
  const sortedOrders = Object.values(orders)
    .filter(filter('User_Internal_Deposit_Cash'))
    .sort((a, b) => {
      if (a.CreatedAt > b.CreatedAt) return -1
      if (a.CreatedAt < b.CreatedAt) return 1
      return 0
    })

  useEffect(() => {
    if (playerId) dispatch(getAllOrders(playerId, 'allOrders'))
  }, [playerId, dispatch])

  usePagination(playerId as string, meta.allOrders)

  return (
    <Spin
      tip="Loading Deposit Wallet Transactions"
      spinning={meta.allOrders.loading}
    >
      <div id={styles['all-transactions']}>
        <div className={styles.title}>DEPOSIT WALLET TRANSACTIONS</div>

        <div className={`${styles.head} ${styles.first}`} />
        <div className={styles.head}>NAME</div>
        <div className={styles.head}>FORMAT</div>
        <div className={styles.head}>AMOUNT</div>
        <div className={styles.head}>REFUNDED</div>
        <div className={styles.head}>ORDER ID</div>
        <div className={styles.head}>DATE & TIME</div>
        <div className={`${styles.head} ${styles.last}`}>STATUS</div>

        {sortedOrders.map(order => {
          return <Row order={order} orders={sortedOrders} key={order.id} />
        })}

        {sortedOrders.length === 0 && !meta.allOrders.loading && (
          <div className={styles['no-transactions']}>No Transactions</div>
        )}

        {meta.allOrders.lastPage && (
          <div className={styles.lastPage}>No more orders!</div>
        )}
      </div>
    </Spin>
  )
}

export default DepositWallet
