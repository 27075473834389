import React, { useState, ChangeEvent } from 'react'
import { Select, Button, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import styles from './Details.module.css'
import { DetailsByUser } from '../../../../../reducers/watson/kyc/kyc.types'
import { storeKycDetails } from '../../../../../actions/watson/player/kyc/kyc'
import getAccessToken from '../../../../../helpers/access-token'

interface Props {
  cardDetails: DetailsByUser
  orderId: string
  documentType: string
}

const { Option } = Select

const rejectionReasons: {
  [index: string]: string
} = {
  INVALID_IMAGE: 'Invalid Image',
  INFO_MISMATCH: 'Info Mismatch',
  DIFFERENT_USER_DOCUMENT: 'Different User Document',
  MINOR_USER: 'Minor User',
}

const AcceptReject: React.FC<Props> = props => {
  const [status, setStatus] = useState('Reject')
  const [rejectionReason, setRejectionReason] = useState(
    Object.keys(rejectionReasons)[0]
  )
  const [comment, setComment] = useState('')
  const { cardDetails, orderId, documentType } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const updateKycStatus = async (): Promise<void> => {
    setLoading(true)
    const apiEndpoint = `${process.env.REACT_APP_FETCH_URL}/twirp/pb.Watson/UpdateDocumentStatus`
    const body = {
      order_id: orderId,
      is_verified: status === 'Accept',
      verification_comment: comment,
      user_status_text: rejectionReason,
      card_details: cardDetails,
    }

    await axios(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${await getAccessToken()}`,
      },
      data: body,
    })
      .then(response => {
        const { data } = response
        dispatch(
          storeKycDetails(data, documentType === 'PAN' ? 'pan' : 'other')
        )

        message.success('Updated document status')
      })
      .catch(error => {
        console.error(error)

        message.error(`Error: ${error.response.data?.msg}`)
      })
    setLoading(false)
  }

  return (
    <div className={styles['accept-reject']}>
      <div>
        <Select
          value={status}
          onChange={(value: string): void => setStatus(value)}
          className={styles.select}
        >
          <Option value="Accept">Accept</Option>
          <Option value="Reject">Reject</Option>
        </Select>
      </div>
      {status === 'Reject' && (
        <div>
          <div>Reason</div>
          <Select
            value={rejectionReason}
            onChange={(value: string): void => setRejectionReason(value)}
            className={styles.select}
          >
            {Object.keys(rejectionReasons).map(key => {
              return (
                <Option key={key} value={key}>
                  {rejectionReasons[key]}
                </Option>
              )
            })}
          </Select>
        </div>
      )}

      <div>
        <div>Comment</div>
        <TextArea
          placeholder="Autosize height with minimum and maximum number of lines"
          autoSize={{ minRows: 2, maxRows: 6 }}
          value={comment}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
            setComment(e.currentTarget.value)
          }}
        />
      </div>

      <div>
        <Button
          type="primary"
          onClick={(): Promise<void> => updateKycStatus()}
          loading={loading}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default AcceptReject
