import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  syncSessions,
  cancelSessionsSync,
} from '../../../actions/watson/cfSession/cfSession'

const useSyncCfSessions = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(syncSessions())

    return (): void => {
      dispatch(cancelSessionsSync())
    }
  }, [dispatch])
}

export default useSyncCfSessions
