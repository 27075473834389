import React from 'react'
import { Card, Button } from 'antd'
import { useSelector, shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'
import { Link, useRouteMatch } from 'react-router-dom'

import styles from './Cards.module.css'

import NewIndicator from '../../../assets/home screen indicator.svg'
import { AppState } from '../../../reducers/reducers'

const selectPendingKycOrders = createSelector(
  (state: AppState) => state.watson.kyc.orders,
  orders =>
    Object.values(orders).filter(order => order.State === 'Manual_Supervision')
)

const Cards: React.FC = () => {
  const pendingOrders = useSelector(selectPendingKycOrders, shallowEqual)
  const pendingOrdersCount = pendingOrders.length
  const match = useRouteMatch()

  return (
    <div className={styles.cards}>
      <Card className={styles.card}>
        <div className={styles.kyc}>
          <div className={styles.text}>
            <div className={styles['main-text']}>KYC Documents</div>
            <div className={styles['sub-text']}>
              {pendingOrdersCount > 0 ? (
                <>
                  {`${pendingOrdersCount} `}
                  documents added
                </>
              ) : (
                <>No new documents</>
              )}
            </div>
          </div>
          <div className={styles['btn-div']}>
            <Link to={`${match.path}/kyc`}>
              <Button type="link" className={styles.btn}>
                View More
              </Button>
            </Link>
          </div>
          {pendingOrdersCount > 0 && (
            <img
              className={styles['new-indicator']}
              src={NewIndicator}
              alt="new document indicator"
            />
          )}
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['offers-admin']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Deposit Offers</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Link to={`${match.path}/deposit-offers`}>
              <Button type="link" className={styles.btn}>
                View More
              </Button>
            </Link>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['offers-admin']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Staggered Rewards</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Link to={`${match.path}/staggered-rewards`}>
              <Button type="link" className={styles.btn}>
                View More
              </Button>
            </Link>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['offers-admin']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Monitoring</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['multiple-btns-div']}>
            <div className={styles['btn-div']}>
              <Link to={`${match.path}/payout-monitor`}>
                <Button type="link" className={styles.btn}>
                  Payouts
                </Button>
              </Link>
            </div>
            <div className={styles['btn-div']}>
              <Link to={`${match.path}/deposit-monitor`}>
                <Button type="link" className={styles.btn}>
                  Deposits
                </Button>
              </Link>
            </div>
            <div className={styles['btn-div']}>
              <Link to={`${match.path}/cf-monitor`}>
                <Button type="link" className={styles.btn}>
                  Cash Format
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['offers-admin']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Audience Service</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Link to={`${match.path}/audience-service`}>
              <Button type="link" className={styles.btn}>
                View More
              </Button>
            </Link>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles.users}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Collusion Group</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Link to={`${match.path}/collusion-group`}>
              <Button type="link" className={styles.btn}>
                View More
              </Button>
            </Link>
          </div>
        </div>
      </Card>

      {/* <Card className={styles.card}>
        <div className={styles.users}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Users</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Button type="link" className={styles.btn} disabled>
              View More
            </Button>
          </div>
        </div>
      </Card>
      <Card className={styles.card}>
        <div className={styles['game-schedule']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Game Schedule</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Button type="link" className={styles.btn} disabled>
              View More
            </Button>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['player-profiles']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>Player Profiles</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Button type="link" className={styles.btn} disabled>
              View More
            </Button>
          </div>
        </div>
      </Card>

      <Card className={styles.card}>
        <div className={styles['mtt-admin']}>
          <div className={styles.text}>
            <div className={styles['main-text']}>MTT Live - Admin</div>
            <div className={styles['sub-text']} />
          </div>
          <div className={styles['btn-div']}>
            <Button type="link" className={styles.btn} disabled>
              View More
            </Button>
          </div>
        </div>
      </Card> */}
    </div>
  )
}

export default Cards
