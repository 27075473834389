import { OrdersReducer, Order, OrdersMap, CFSession } from './orders.types'
import {
  OrdersActions,
  StoreOrders,
  StoreOrdersPayload,
  StoreIntercomRefunds,
  StoreIntercomRefundsPayload,
  StoreRewards,
  StoreRewardsPayload,
  StoreCFSessions,
  StoreCFSessionsPayload,
} from '../../../../actions/watson/player/orders/orders.types'
import {
  STORE_ORDERS,
  SET_LOADING_ALL_ORDERS,
  SET_LOADING_REWARDS,
  STORE_INTERCOM_REFUNDS,
  STORE_REWARDS,
  STORE_CF_SESSIONS,
} from '../../../../actions/watson/player/orders/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: OrdersReducer = {
  orders: {},
  intercomRefunds: [],
  rewards: [],
  error: {
    allOrders: null,
    rewards: null,
    intercomRefunds: null,
  },
  meta: {
    allOrders: {
      loading: false,
      lastDoc: null,
      lastPage: false,
    },
    rewards: {
      loading: false,
      lastDoc: null,
      lastPage: true,
    },
    cfSessions: {
      lastDoc: null,
      pageNumber: 1,
    },
  },
  cfSessions: {},
}

export const getMapFromArray = (
  arr: Order[],
  ordersMap: OrdersMap = {}
): OrdersMap => {
  return arr.reduce((om: OrdersMap, item) => {
    const map = om
    map[item.id] = item

    return map
  }, ordersMap)
}

const ordersReducer = (
  state = initialState,
  action: OrdersActions
): OrdersReducer => {
  switch (action.type) {
    case SET_LOADING_ALL_ORDERS:
      return {
        ...state,
        error: {
          ...state.error,
          allOrders: null,
        },
        meta: {
          ...state.meta,
          allOrders: {
            ...state.meta.allOrders,
            loading: true,
          },
        },
      }

    case SET_LOADING_REWARDS:
      return {
        ...state,
        error: {
          ...state.error,
          rewards: null,
        },
        meta: {
          ...state.meta,
          rewards: { ...state.meta.rewards, loading: true },
        },
      }

    case STORE_ORDERS: {
      const { payload, error, meta } = action as StoreOrders
      const { category } = meta

      if (!error) {
        const { orders } = payload as StoreOrdersPayload
        const { lastDoc, lastPage } = meta

        return {
          ...state,
          orders: getMapFromArray(orders, { ...state.orders }),
          error: {
            ...state.error,
            [category]: null,
          },
          meta: {
            ...state.meta,
            [category]: {
              ...state.meta[category],
              loading: false,
              lastPage,
              ...(lastDoc && { lastDoc }),
            },
          },
        }
      }

      return {
        ...state,
        error: {
          ...state.error,
          [category]: payload as Error,
        },
        meta: {
          ...state.meta,
          [category]: {
            ...state.meta[category],
            loading: false,
          },
        },
      }
    }

    case RESET_PLAYER:
      return initialState

    case STORE_REWARDS: {
      const { payload, error } = action as StoreRewards

      if (!error) {
        const { orders } = payload as StoreRewardsPayload

        return {
          ...state,
          rewards: orders,
          meta: {
            ...state.meta,
            rewards: {
              ...state.meta.rewards,
              loading: false,
            },
          },
        }
      }

      return {
        ...state,
        error: {
          ...state.error,
          rewards: payload as Error,
        },
      }
    }

    case STORE_INTERCOM_REFUNDS: {
      const { payload, error } = action as StoreIntercomRefunds

      if (!error) {
        const { orders } = payload as StoreIntercomRefundsPayload

        return {
          ...state,
          intercomRefunds: orders,
        }
      }

      return {
        ...state,
      }
    }

    case STORE_CF_SESSIONS: {
      const { payload, error } = action as StoreCFSessions

      if (!error) {
        const { cfSessions } = payload as StoreCFSessionsPayload

        return {
          ...state,
          cfSessions: {
            ...state.cfSessions,
            ...cfSessions.reduce((cfs: { [index: string]: CFSession }, s) => {
              return {
                ...cfs,
                [s.session_id]: s,
              }
            }, {}),
          },
          meta: {
            ...state.meta,
            cfSessions: {
              pageNumber: state.meta.cfSessions.pageNumber + 1,
              lastDoc:
                (cfSessions.length > 0 &&
                  cfSessions[cfSessions.length - 1].session_id) ||
                state.meta.cfSessions.lastDoc,
            },
          },
        }
      }

      return state
    }

    default:
      return state
  }
}

export default ordersReducer
