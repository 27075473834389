import {
  LoadingGameProfiles,
  SyncGameProfiles,
  CancelGameProfilesSync,
  StoreGameProfiles,
} from './gameProfiles.types'
import {
  LOADING_GAME_PROFILES,
  SYNC_GAME_PROFILES,
  CANCEL_GAME_PROFILES_SYNC,
  STORE_GAME_PROFILE,
} from './ActionTypes'
import { GameProfile } from '../../../../reducers/watson/player/gameProfiles/gameProfiles.types'

export const loadingGameProfiles = (): LoadingGameProfiles => ({
  type: LOADING_GAME_PROFILES,
  payload: {},
})

export const syncGameProfiles = (playerId: string): SyncGameProfiles => ({
  type: SYNC_GAME_PROFILES,
  payload: { playerId },
})

export const cancelGameProfilesSync = (): CancelGameProfilesSync => ({
  type: CANCEL_GAME_PROFILES_SYNC,
  payload: {},
})

export const storeGameProfiles = (
  gameProfiles: GameProfile[] | Error
): StoreGameProfiles => ({
  type: STORE_GAME_PROFILE,
  payload: gameProfiles instanceof Error ? gameProfiles : { gameProfiles },
  ...(gameProfiles instanceof Error && { error: true }),
})
