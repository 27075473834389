import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import moment from 'moment'
import styles from './InstantContestJoin.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import GemIcon from '../../../../../assets/crystal.svg'
import ProcessCustomerSupportReward from '../ProcessCustomerSupportReward/ProcessCustomerSupportReward'

interface Props {
  order: Order
}

const collectionRef = firebase.firestore().collection('central_orders')

const InstantContestJoin: React.FC<Props> = props => {
  const { order } = props

  const [refundDialogVisible, setRefundDialogVisible] = useState(false)
  const [showRefund, setShowRefund] = useState(false)
  const [linkedOrders, setLinkedOrders] = useState<Order[]>([])

  useEffect(() => {
    if (!refundDialogVisible) {
      collectionRef
        .where('Product.RewardProps.ParentOrderRef', '==', order.id)
        .get()
        .then(snapshot => {
          const lo: Order[] = []
          snapshot.forEach((doc: DocumentData) => {
            lo.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          setLinkedOrders(lo)

          if (lo.length === 0 && !order.Refunds && order.Payments) {
            setShowRefund(true)
          } else {
            const refundedAmount = lo.reduce((t: number, o): number => {
              return t + (o.Fulfillments[0].Payment?.Amount || 0)
            }, 0)
            const orderAmount =
              order.Payments?.reduce((t: number, p): number => {
                return t + (p.Amount || 0)
              }, 0) || 0

            setShowRefund(refundedAmount > 0 && refundedAmount < orderAmount)
          }
        })
        .catch(err => {
          console.error(err)
          setShowRefund(true)
        })
    }
  }, [order, refundDialogVisible])

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>More Details</div>

        <div className={styles.key}>ROOM ID</div>
        <div className={styles.value}>-</div>

        <div className={styles.key}>TOURNAMENT ID</div>
        <div className={styles.value}>-</div>
      </div>

      <div className={styles.card}>
        <div className={styles.title}>Wallet Balance</div>

        <div className={styles.wallets}>
          <div className={styles.th}>WALLET SOURCE</div>
          <div className={styles.th}>SPENT</div>

          {order.Payments?.map(wallet => {
            return (
              <React.Fragment key={wallet.Source}>
                <div className={styles.td}>{wallet.Source}</div>
                <div className={styles.td}>
                  {wallet.Currency === 'INR' ? (
                    '₹'
                  ) : (
                    <img src={GemIcon} alt="gem icon" className="gem" />
                  )}
                  {` ${wallet.Amount}`}
                </div>
              </React.Fragment>
            )
          })}
        </div>

        {showRefund && (
          <div className={styles['process-refund']}>
            <ProcessCustomerSupportReward
              rewardType="AGAINST_ORDER"
              order={order}
              visible={refundDialogVisible}
              setRewardModalVisible={setRefundDialogVisible}
            />
            <Button
              danger
              type="primary"
              onClick={(): void => {
                setRefundDialogVisible(true)
              }}
            >
              Refund
            </Button>
          </div>
        )}

        {order.Refunds && (
          <>
            <div className={`${styles.title} ${styles.refund}`}>Refund</div>

            <div className={styles.wallets}>
              <div className={styles.th}>WALLET DESTINATION</div>
              <div className={styles.th}>REFUND</div>

              {order.Refunds?.map(wallet => {
                return (
                  <React.Fragment key={wallet.Destination}>
                    <div className={styles.td}>{wallet.Destination}</div>
                    <div className={styles.td}>
                      {wallet.Currency === 'INR' ? (
                        '₹'
                      ) : (
                        <img src={GemIcon} alt="gem icon" className="gem" />
                      )}
                      {` ${wallet.Amount}`}
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </>
        )}

        {linkedOrders.length > 0 && (
          <>
            <div className={`${styles.title} ${styles['linked-rewards']}`}>
              Linked Rewards
            </div>

            <div className={styles['linked-orders']}>
              <div className={styles.th}>ORDER ID</div>
              <div className={styles.th}>AMOUNT</div>
              <div className={styles.th}>DATE</div>

              {linkedOrders.map(lo => {
                const timestamp = moment(lo.CreatedAt.seconds * 1000)

                return (
                  <React.Fragment key={lo.id}>
                    <div className={styles.td}>{lo.id}</div>
                    <div className={styles.td}>
                      {lo.Fulfillments[0].Payment?.Currency === 'INR' ? (
                        '₹'
                      ) : (
                        <img src={GemIcon} alt="gem icon" className="gem" />
                      )}
                      {` ${lo.Fulfillments[0].Payment?.Amount}`}
                    </div>
                    <div className={styles.td}>
                      <div>{timestamp.format('ll')}</div>
                      <div>{timestamp.format('hh:mm A')}</div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default InstantContestJoin
