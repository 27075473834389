import {
  fork,
  take,
  cancel,
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects'
import { Task } from 'redux-saga'
import firebase from 'firebase/app'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'

import rsf from '../../../firebase'
import {
  CANCEL_SESSIONS_SYNC,
  GET_SESSIONS,
  FILTER_SESSIONS,
  SYNC_SESSIONS,
} from '../../../actions/watson/cfSession/ActionTypes'
import {
  CfSession,
  CfSessionState,
} from '../../../reducers/watson/cfSession/cfSession.types'
import {
  StoreSessions,
  GetSessions,
  FilterSessions,
} from '../../../actions/watson/cfSession/cfSession.types'
import {
  storeSessions,
  setLoadingCfSessions,
  storeFilteredSessions,
} from '../../../actions/watson/cfSession/cfSession'
import { AppState } from '../../../reducers/reducers'

const defaultPageSize = 25

const formatSessions = (
  snapshot: QuerySnapshot
): {
  sessions: CfSession[]
  lastDoc: DocumentData | null
} => {
  const sessions: CfSession[] = []
  let lastDoc: DocumentData | null = null

  snapshot.forEach((doc: DocumentData) => {
    const session = doc.data()
    session.id = doc.id
    sessions.push(session as CfSession)

    lastDoc = doc
  })

  return { sessions, lastDoc }
}

const createStoreSessionsAction =
  (sessionState: CfSessionState) =>
  (snapshot: QuerySnapshot): StoreSessions => {
    const { sessions, lastDoc } = formatSessions(snapshot)

    return storeSessions({
      sessions,
      sessionState,
      lastDoc,
      lastPage: sessions.length < defaultPageSize || sessionState === 'Active',
    })
  }

// Todo: State

function* sync(sessionState: CfSessionState, pageSize?: number): Generator {
  yield put(setLoadingCfSessions(sessionState))

  const startDate = new Date('2020-04-01T00:00:00')
  let query = firebase
    .firestore()
    .collectionGroup('sessions')
    .where('State', '==', sessionState)
    .where('CreatedAt', '>=', startDate)
    // sort it by UpdatedAt else accepted and rejected will not be sorted
    // index is not created on UpdatedAt, so sorting by CreatedAt for now
    // .orderBy('UpdatedAt', 'desc')
    .orderBy('CreatedAt', 'desc')

  if (pageSize) query = query.limit(pageSize)

  // eslint-disable-next-line
  // @ts-ignore
  yield call(rsf.firestore.syncCollection, query, {
    successActionCreator: createStoreSessionsAction(sessionState),
  })
}

function* getSessions(action: GetSessions): Generator {
  const { sessionState } = action.payload

  yield put(setLoadingCfSessions(sessionState))

  const lastDoc = yield select(
    (state: AppState) => state.watson.cf.meta[sessionState].lastDoc
  )
  const startDate = new Date('2020-04-01T00:00:00')
  let query = firebase
    .firestore()
    .collectionGroup('sessions')
    .where('State', '==', sessionState)
    .where('CreatedAt', '>=', startDate)
    // sort it by UpdatedAt else accepted and rejected will not be sorted
    // index is not created on UpdatedAt, so sorting by CreatedAt for now
    // .sessionBy('UpdatedAt', 'desc')
    .orderBy('CreatedAt', 'desc')
    .limit(defaultPageSize)

  if (lastDoc) query = query.startAfter(lastDoc)

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(rsf.firestore.getCollection, query)

  yield put(createStoreSessionsAction(sessionState)(snapshot as QuerySnapshot))
}

function* syncSessions(): Generator {
  yield take(SYNC_SESSIONS)

  const pendingSessionsTask = yield fork(sync, 'Active')
  const acceptedSessionsTask = yield fork(sync, 'CashedOut', defaultPageSize)
  const rejectedSessionsTask = yield fork(sync, 'Ended', defaultPageSize)

  yield take(CANCEL_SESSIONS_SYNC)

  yield cancel(pendingSessionsTask as Task)
  yield cancel(acceptedSessionsTask as Task)
  yield cancel(rejectedSessionsTask as Task)
}

function* watchGetSessions(): Generator {
  yield takeLatest(GET_SESSIONS, getSessions)
}

function* filterSessions(action: FilterSessions): Generator {
  const { selectedFilters } = action.payload

  yield put(setLoadingCfSessions('CashedOut'))
  yield put(setLoadingCfSessions('Ended'))
  yield put(setLoadingCfSessions('Active'))

  let query = firebase.firestore().collectionGroup('sessions')

  Object.keys(selectedFilters).forEach(key => {
    if (typeof selectedFilters[key] === 'string') {
      query = query.where(key, '==', selectedFilters[key])
    } else {
      query = query.where(
        key,
        '>=',
        new Date(selectedFilters[key][0].startOf('day').format())
      )
      query = query.where(
        key,
        '<=',
        new Date(selectedFilters[key][1].endOf('day').format())
      )
    }
  })

  // so we don't fetch everything by mistake
  query = query.limit(100)

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(rsf.firestore.getCollection, query)

  const { sessions } = formatSessions(snapshot as QuerySnapshot)
  yield put(storeFilteredSessions(sessions))
}

function* watchFilterSessions(): Generator {
  yield takeLatest(FILTER_SESSIONS, filterSessions)
}

export default function* watchCfSessions(): Generator {
  yield fork(syncSessions)
  yield fork(watchGetSessions)
  yield fork(watchFilterSessions)
}
