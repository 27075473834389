import { MenuItems } from './menuItems.types'
import PlayerSummary from './Content/PlayerSummary/PlayerSummary'
import Kyc from './Content/PlayerKyc/PlayerKyc'
import AllTransactions from './Content/AllTransactions/AllTransactions'
import Referrals from './Content/PlayerSummary/Referrals/Referrals'
import Rewards from './Content/Rewards/Rewards'
import WinningWallet from './Content/WinningWallet/WinningWallet'
import DepositWallet from './Content/DepositWallet/DepositWallet'
import PassTransactions from './Content/PassTransactions/PassTransactions'
import AllActivity from './Content/AllActivity/AllActivity'
import CustomerSupportRewards from './Content/CustomerSupportRewards/CustomerSupportRewards'
import CFSessions from './Content/CFSessions/CFSessions'
import MoonshotLeaderboard from './Content/MoonshotLeaderboard/MoonshotLeaderboard'
import Collusion from './Content/Collusion/Collusion'
import GameHistory from './Content/GameHistory/GameHistory'

const menuItems: MenuItems = {
  'player-summary': { title: 'Player Summary', component: PlayerSummary },

  'all-transactions': { title: 'All Transactions', component: AllTransactions },
  'pass-transactions': {
    title: 'Pass Transactions',
    component: PassTransactions,
  },
  'winning-wallet': { title: 'Winning Wallet', component: WinningWallet },
  'deposit-wallet': { title: 'Deposit Wallet', component: DepositWallet },
  'bonus-wallet': { title: 'Bonus Wallet' },
  'rewards-transactions': { title: 'Rewards Transactions', component: Rewards },
  'moonshot-leaderboard': {
    title: 'Moonshot Leaderboard',
    component: MoonshotLeaderboard,
  },
  'gem-transactions': { title: 'Gem Transactions' },
  'cs-rewards': { title: 'CS Rewards', component: CustomerSupportRewards },
  'cf-sessions': { title: 'CF Sessions', component: CFSessions },
  'game-history': { title: 'Game History', component: GameHistory },
  'collusion-cases': { title: 'Collusion Cases', component: Collusion },

  'all-activity': { title: 'All Activity', component: AllActivity },
  'contests-activity': { title: 'Contests Activity' },
  'taska-activity': { title: 'Tasks Activity' },
  'xp-and-level': { title: 'XP & Level' },

  referral: { title: 'Referral', component: Referrals },
  kyc: { title: 'KYC', component: Kyc },
}

export default menuItems
