import React, { useState } from 'react'
import moment from 'moment'
import { Tag } from 'antd'
import styles from './AllTransactions.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import MoreIcon from '../../../../../assets/more.svg'
import LessIcon from '../../../../../assets/less.svg'
import Details from './Details'
import GemIcon from '../../../../../assets/crystal.svg'
import OrderState from './OrderState'
import Timestamp from '../../../../../components/Timestamp/Timestamp'

interface Props {
  order: Order
  orders: Order[]
}

const Row: React.FC<Props> = props => {
  const { order, orders } = props
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  let refunded = ''
  if (order.Category === 'Purchase') {
    if (order.Refunds) refunded = 'Yes'

    const lo = orders.filter(
      o => o.Product.RewardProps?.ParentOrderRef === order.id
    )
    const refundedAmount = lo.reduce((t: number, o): number => {
      return t + ((o.Fulfillments && o.Fulfillments[0].Payment?.Amount) || 0)
    }, 0)
    const orderAmount =
      order.Payments?.reduce((t: number, p): number => {
        return t + (p.Amount || 0)
      }, 0) || 0

    if (refundedAmount > 0 && refundedAmount < orderAmount) refunded = 'Partial'
    else if (refundedAmount === orderAmount) refunded = 'Yes'
    else if (!order.Refunds && order.State === 'Failed') refunded = 'No'
  }
  let format
  if (order.Product.Type === 'TABLE_TICKET' || order.Category === 'Refund') {
    format = 'Cash'
  }
  if (order.Product.Type === 'ROOM_TICKET') {
    format = 'Instant'
  }

  let amount = 0
  let credit = true
  let currency = ''

  if (order.Refunds) {
    const amt = order.Refunds.reduce((result, p) => {
      return result + p.Amount
    }, 0)

    credit = true
    currency = order.Refunds[0].Currency
    amount = amt
  }

  if (order.Fulfillments) {
    if (order.Fulfillments[0].Payment) {
      const Fulfillments = order.Fulfillments.map(f => f.Payment)

      const amt = Fulfillments.reduce((result, p) => {
        return result + (p?.Amount || 0)
      }, 0)

      credit = true
      currency = Fulfillments[0]?.Currency || ''
      amount = amt
    } else if (order.Fulfillments[0].Payout) {
      const Fulfillments = order.Fulfillments.map(f => f.Payout)

      const amt = Fulfillments.reduce((result, p) => {
        return result + (p?.Amount || 0)
      }, 0)

      credit = false
      currency = Fulfillments[0]?.Currency || ''
      amount = amt
    }
  }

  if (order.Payments) {
    const amt = order.Payments.reduce((result, p) => {
      return result + p.Amount
    }, 0)

    if (order.Category === 'Purchase') {
      credit = false
      currency = order.Payments[0].Currency
      amount = amt
    }

    if (
      (order.Category === 'Purchase' && order.Product.Type === 'GEM_PACK') ||
      order.Category === 'Deposit'
    ) {
      credit = true
      currency =
        (order.Fulfillments && order.Fulfillments[0]?.Payment?.Currency) ||
        'INR'
      amount = amt
    }

    if (order.Category === 'TDS') {
      credit = false
      currency = 'INR'
      amount = amt
    }
  }

  if (order.Product.Type === 'PASS_BUNDLE') {
    // eslint-disable-next-line
    // @ts-ignore
    amount = `${order.Product.PassProps.Passes.length} Passes`
    currency = ''
    credit = true
  }

  return (
    <>
      <div className={styles.value}>
        {!detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setDetailsExpanded(true)}
          />
        )}
        {detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setDetailsExpanded(false)}
          />
        )}
      </div>
      <div className={styles.value}>{order.Title}</div>
      <div className={styles.value}>{format}</div>
      <div className={`${styles.value} ${styles[credit ? 'green' : 'red']}`}>
        {order.State !== 'Pending' && (
          <>
            {credit ? '+ ' : '- '}
            {currency === 'INR' && <>₹ </>}
            {`${amount}`}
            {currency === 'GEM' && (
              <>
                {' '}
                <img src={GemIcon} alt="gem icon" className="gem" />
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.value}>
        {refunded === 'No' && <Tag color="red">{refunded}</Tag>}
        {refunded === 'Partial' && <Tag color="orange">{refunded}</Tag>}
        {refunded === 'Yes' && <Tag color="green">{refunded}</Tag>}
      </div>
      <div className={styles.value}>{order.id}</div>
      <div className={styles.value}>
        <Timestamp timestamp={moment(order.CreatedAt.seconds * 1000)} />
      </div>
      <div className={`${styles.value} ${styles[order.State]}`}>
        <OrderState state={order.State} />
      </div>
      {detailsExpanded && <Details order={order} />}
    </>
  )
}

export default Row
