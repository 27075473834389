import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Checkbox, message, Select } from 'antd'
import { LeftCircleFilled } from '@ant-design/icons'
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikProps,
  FormikValues,
  FieldArray,
} from 'formik'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styles from './CreateOffer.module.css'
import {
  BudgetType,
  OfferTimestamps,
  RewardType,
  RewardDestination,
  AudienceCorrelation,
  OfferTags,
} from './constants'
import { createOffer, getOfferScreenNames } from '../api'
import { AppState } from '../../../../reducers/reducers'
import { User } from '../../../../reducers/auth/auth.types'
import AudienceDetails from '../OffersList/AudienceDetails'
import Separator from '../../../../components/Separator/Separator'
import useConfig from '../useConfig'
import useAudience from '../useAudience'
import useStaggeredTemplates from '../useStaggeredTemplates'
import StaggeredPreview from '../../StaggeredRewards/StaggeredPreview'
import validationSchema from '../offerValidationSchema'

const { Option } = Select

interface Props {
  toggleCreateOffer(): void
}

const CreateOffer: React.FC<Props> = props => {
  const { toggleCreateOffer } = props
  const [offerScreenNames, setOfferScreenNames] = useState<string[]>([])
  const { user } = useSelector((state: AppState) => state.auth)
  const [error, setError] = useState<string>('')
  const { details } = useConfig()
  const audience = useAudience()
  const staggeredTemplates = useStaggeredTemplates()
  const initialFormValues: FormikValues = {
    name: '',
    title: '',
    subtitle: '',
    tags: [],
    ftu_title: '',
    ftu_subtitle: '',
    icon_url: '',
    details,
    offer_type: 'deposit',
    budget_type: BudgetType.offerUsage,
    budget_value: 0,
    p_max_attempt_per_day: 1,
    p_max_attempt_overall: 1,
    p_subsequent_use_interval_minutes: 0,
    t1: undefined,
    t2: undefined,
    t3: undefined,
    t4: undefined,
    min_amount: 0,
    max_amount: 0,
    reward_type: RewardType.cash,
    product_id: '',
    percent_value: 10,
    max_reward_amount: 0,
    destination: RewardDestination.depositWallet,
    staggered_reward_template_id: '',
    should_show: true,
    show_after: undefined,
    audience_ids: [],
    audience_correlation: 1,
    hide_offer_on_screens: [],
  }

  const fetchOfferScreenNames = async (): Promise<void> => {
    const { screens, error: err } = await getOfferScreenNames()

    if (!err) {
      setOfferScreenNames(screens || [])
    }
  }

  useEffect(() => {
    fetchOfferScreenNames()
  }, [])

  return (
    <div id={styles['deposit-offers']}>
      <div style={{ textAlign: 'left' }}>
        <Button
          type="link"
          onClick={toggleCreateOffer}
          icon={<LeftCircleFilled />}
          style={{ paddingLeft: 0 }}
        >
          Back to Current Deposit Offers
        </Button>
      </div>

      <div className={styles.title}>Create Deposit Offer</div>

      <div id={styles['create-offer']}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (
            values,
            { setSubmitting, resetForm }
          ): Promise<void> => {
            const { success, error: err } = await createOffer(
              values,
              user as User
            )
            setSubmitting(false)

            if (success) {
              message.success('Offer created successfully!')
              setError('')
              resetForm(initialFormValues)
            } else if (err) {
              setError(err)
              message.error(err)
            } else {
              const e = 'Error creating Offer!'
              setError(e)
              message.error(e)
            }
          }}
        >
          {(formikProps: FormikProps<FormikValues>): React.ReactElement => (
            <Form className={styles['create-offer-form']}>
              <div className={styles.subtitle}>Offer Details</div>

              <span>Name</span>
              <Field name="name" type="text" />
              <div className={styles['field-error']}>
                <ErrorMessage name="name" />
              </div>

              <span>Coupon Code</span>
              <Field name="coupon_code" type="text" />
              <div className={styles['field-error']}>
                <ErrorMessage name="coupon_code" />
              </div>

              <span>title</span>
              <Field name="title" type="text" />
              <div className={styles['field-error']}>
                <ErrorMessage name="title" />
              </div>

              <span>subtitle</span>
              <Field name="subtitle" type="text" />
              <div className={styles['field-error']}>
                <ErrorMessage name="subtitle" />
              </div>

              <span>Tags</span>
              <Select
                mode="multiple"
                onChange={(values): void => {
                  formikProps.setFieldValue('tags', values)
                }}
                value={formikProps.values.tags}
              >
                {OfferTags.map(o => {
                  return (
                    <Option key={o.tag} value={o.tag}>
                      {o.tagTitle}
                    </Option>
                  )
                })}
              </Select>
              <div className={styles['field-error']}>
                <ErrorMessage name="tags" />
              </div>

              {formikProps.values.tags.includes(OfferTags[0].tag) && (
                <>
                  <span>Ftu Title</span>
                  <Field name="ftu_title" type="text" />
                  <div className={styles['field-error']}>
                    <ErrorMessage name="ftu_title" />
                  </div>

                  <span>Ftu Subtitle</span>
                  <Field name="ftu_subtitle" type="text" />
                  <div className={styles['field-error']}>
                    <ErrorMessage name="ftu_subtitle" />
                  </div>
                </>
              )}

              <span>icon url</span>
              <Field name="icon_url" type="text" />
              <div className={styles['field-error']}>
                <ErrorMessage name="icon_url" />
              </div>

              <span>Details</span>
              <FieldArray
                name="details"
                render={(arrayHelpers): React.ReactElement => (
                  <div>
                    {formikProps.values.details &&
                      formikProps.values.details.length > 0 &&
                      formikProps.values.details.map(
                        (detail: string, index: number) => (
                          <div
                            key={detail || Math.random()}
                            className={styles['details-array']}
                          >
                            <Field name={`details.${index}`} value={detail} />
                            <Button
                              type="link"
                              onClick={(): void => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                          </div>
                        )
                      )}
                    <Button
                      className={styles['add-detail-btn']}
                      type="link"
                      onClick={(): void => arrayHelpers.push('')}
                    >
                      Add Detail
                    </Button>
                  </div>
                )}
              />
              <div className={styles['field-error']}>
                <ErrorMessage name="details" />
              </div>

              <span>offer type</span>
              <Field as="select" name="offer_type">
                <option value="deposit">Deposit</option>
              </Field>
              <div className={styles['field-error']}>
                <ErrorMessage name="offer_type" />
              </div>

              <Separator />

              <span>budget type</span>
              <Field as="select" name="budget_type">
                <option value="">--- Select Budget Type ---</option>
                {Object.keys(BudgetType).map(bt => {
                  return (
                    <option key={bt} value={BudgetType[bt]}>
                      {BudgetType[bt]}
                    </option>
                  )
                })}
              </Field>
              <div className={styles['field-error']}>
                <ErrorMessage name="budget_type" />
              </div>

              <span>budget value</span>
              <Field name="budget_value" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="budget_value" />
              </div>

              <Separator />

              <span>max usage per day per player</span>
              <Field name="p_max_attempt_per_day" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="p_max_attempt_per_day" />
              </div>

              <span>max usage overall per player</span>
              <Field name="p_max_attempt_overall" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="p_max_attempt_overall" />
              </div>

              <span>player subsequent use interval (minutes)</span>
              <Field name="p_subsequent_use_interval_minutes" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="p_subsequent_use_interval_minutes" />
              </div>

              <Separator />

              {Object.keys(OfferTimestamps).map(ot => {
                return (
                  <React.Fragment key={ot}>
                    <span>{`T${ot} - ${OfferTimestamps[ot]}`}</span>
                    <Field name={`t${ot}`}>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { setFieldValue },
                      }: FormikValues): React.ReactElement => (
                        <DatePicker
                          showTime
                          disabledDate={(current): boolean => {
                            return current < moment().startOf('day')
                          }}
                          onChange={(date): void => {
                            if (date) {
                              setFieldValue(field.name, date.format())
                              if (
                                field.name === 't1'
                                // && !formikProps.values.show_after
                              ) {
                                setFieldValue('show_after', date.format())
                              }
                            }
                          }}
                        />
                      )}
                    </Field>
                    <div className={styles['field-error']}>
                      <ErrorMessage name={`t${ot}`} />
                    </div>
                  </React.Fragment>
                )
              })}

              <Separator />

              <span>Offer Trigger Min Amount</span>
              <Field name="min_amount" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="min_amount" />
              </div>

              <span>Offer Trigger Max Amount</span>
              <Field name="max_amount" type="number" />
              <div className={styles['field-error']}>
                <ErrorMessage name="max_amount" />
              </div>

              <Separator />

              <span>reward type</span>
              <Field as="select" name="reward_type">
                {/* <option value="">--- Select Reward Type ---</option> */}
                {Object.keys(RewardType).map(rt => {
                  return (
                    <option key={rt} value={RewardType[rt]}>
                      {RewardType[rt]}
                    </option>
                  )
                })}
              </Field>
              <div className={styles['field-error']}>
                <ErrorMessage name="reward_type" />
              </div>

              {/* {formikProps.values.reward_type === 'FIXED' && (
                <>
                  <span>product id</span>
                  <Field name="product id" type="text" />
                    <div className={styles['field-error']}><ErrorMessage name="product_id" /></div>
                </>
              )} */}

              {formikProps.values.reward_type === RewardType.cash && (
                <>
                  <span>percent value</span>
                  <Field name="percent_value" type="number" />
                  <div className={styles['field-error']}>
                    <ErrorMessage name="percent_value" />
                  </div>

                  <span>max amount</span>
                  <Field name="max_reward_amount" type="number" />
                  <div className={styles['field-error']}>
                    <ErrorMessage name="max_reward_amount" />
                  </div>

                  <span>reward destination</span>
                  <Field as="select" name="destination">
                    <option value="">--- Select Reward Destination ---</option>
                    {Object.keys(RewardDestination).map(rd => {
                      return (
                        <option key={rd} value={RewardDestination[rd]}>
                          {RewardDestination[rd]}
                        </option>
                      )
                    })}
                  </Field>
                  <div className={styles['field-error']}>
                    <ErrorMessage name="destination" />
                  </div>
                </>
              )}

              {formikProps.values.reward_type === RewardType.staggered && (
                <>
                  <span>Staggered Reward Template</span>
                  <Select
                    // mode="multiple"
                    onChange={(value): void => {
                      formikProps.setFieldValue(
                        'staggered_reward_template_id',
                        value
                      )
                    }}
                    value={formikProps.values.staggered_reward_template_id}
                  >
                    {staggeredTemplates.map(st => {
                      return (
                        <Option key={st.TemplateId} value={st.TemplateId}>
                          {st.TemplateId}
                        </Option>
                      )
                    })}
                  </Select>
                  <div className={styles['field-error']}>
                    <ErrorMessage name="staggered_reward_template_id" />
                  </div>
                </>
              )}

              {formikProps.values.reward_type === RewardType.staggered &&
              formikProps.values.staggered_reward_template_id ? (
                <StaggeredPreview
                  staggeredReward={staggeredTemplates.find(
                    s =>
                      s.TemplateId ===
                      formikProps.values.staggered_reward_template_id
                  )}
                />
              ) : null}

              <Separator />

              <span />
              <Field name="should_show">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue },
                }: FormikValues): React.ReactElement => (
                  <Checkbox
                    onChange={(e: CheckboxChangeEvent): void => {
                      setFieldValue(field.name, e.target.checked)
                    }}
                    checked={field.value}
                  >
                    Show offer validity text
                  </Checkbox>
                )}
              </Field>

              {formikProps.values.should_show && (
                <>
                  <span>Show validity after</span>
                  <Field name="show_after">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue },
                    }: FormikValues): React.ReactElement => (
                      <DatePicker
                        showTime
                        disabledDate={(current): boolean => {
                          return (
                            current <
                              moment(formikProps.values.t1).startOf('day') ||
                            current > moment(formikProps.values.t4).endOf('day')
                          )
                        }}
                        onChange={(date): void => {
                          if (date) setFieldValue(field.name, date.format())
                        }}
                        value={
                          (formikProps.values.show_after &&
                            moment(formikProps.values.show_after)) ||
                          undefined
                        }
                      />
                    )}
                  </Field>
                  <div className={styles['field-error']}>
                    <ErrorMessage name="show_after" />
                  </div>
                </>
              )}

              <Separator />

              <span>Hide Offer on Screens</span>
              <Select
                mode="multiple"
                onChange={(values): void => {
                  formikProps.setFieldValue('hide_offer_on_screens', values)
                }}
              >
                {offerScreenNames.map(osn => {
                  return (
                    <Option key={osn} value={osn}>
                      {osn}
                    </Option>
                  )
                })}
              </Select>
              <div className={styles['field-error']}>
                <ErrorMessage name="hide_offer_on_screens" />
              </div>

              <Separator />

              <span>Audience Correlation</span>
              <Field as="select" name="audience_correlation">
                {AudienceCorrelation.map((ac, i) => {
                  return (
                    <option key={ac} value={i}>
                      {ac}
                    </option>
                  )
                })}
              </Field>
              <div className={styles['field-error']}>
                <ErrorMessage name="audience_correlation" />
              </div>

              <span>Audience</span>
              <Select
                mode="multiple"
                onChange={(values): void => {
                  formikProps.setFieldValue('audience_ids', values)
                }}
                value={formikProps.values.audience_ids}
              >
                {audience.map(aud => {
                  return (
                    <Option key={aud.Id} value={aud.Id}>
                      {aud.Name}
                    </Option>
                  )
                })}
              </Select>
              <div className={styles['field-error']}>
                <ErrorMessage name="audience_ids" />
              </div>

              {formikProps.values.audience_ids.length > 0 ? (
                <>
                  {formikProps.values.audience_ids.map(
                    (aid: string, i: number) => {
                      return (
                        <React.Fragment key={aid}>
                          <div className={styles['audience-details']}>
                            <AudienceDetails
                              audience={
                                audience.find(a => a.Id === aid) || audience[0]
                              }
                            />
                          </div>
                          {i ===
                          formikProps.values.audience_ids.length - 1 ? null : (
                            <Separator />
                          )}
                        </React.Fragment>
                      )
                    }
                  )}
                </>
              ) : null}

              {error && <div className={styles.error}>{`Error: ${error}`}</div>}

              <button
                type="submit"
                className={styles['create-offer-btn']}
                disabled={formikProps.isSubmitting}
              >
                Create Offer
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CreateOffer
