import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import moment from 'moment'
import styles from './AllActivity.module.css'
import { AppState } from '../../../../../reducers/reducers'
import {
  syncTimeline,
  cancelTimelineSync,
} from '../../../../../actions/watson/player/timeline/timeline'

interface Props {
  count?: number
  title?: string
}

const AllActivity: React.FC<Props> = props => {
  const { count, title } = props
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { timeline, meta } = useSelector(
    (state: AppState) => state.watson.player.timeline
  )
  let slicedTimeline = timeline
  if (count) slicedTimeline = timeline.slice(0, count)

  useEffect(() => {
    if (playerId) dispatch(syncTimeline(playerId))

    return (): void => {
      dispatch(cancelTimelineSync())
    }
  }, [dispatch, playerId])

  const cleanHtml = (html: string): string => html.replace(/(<([^>]+)>)/gi, '')

  return (
    <Spin tip="Loading All Activity" spinning={meta.loading}>
      <div id={styles.timeline}>
        <div className={styles.title}>{title || 'All Activity'}</div>

        <div className={`${styles.head} ${styles.first}`}>EVENT TYPE</div>
        <div className={styles.head}>TITLE</div>
        <div className={styles.head}>SUBTITLE</div>
        <div className={`${styles.head} ${styles.last}`}>DATE & TIME</div>

        {slicedTimeline.map(t => {
          const ts = t.CreatedAt.seconds * 1000

          return (
            <React.Fragment key={t.Id}>
              <div className={styles.value}>{t.DisplayActivity.EventType}</div>
              <div className={styles.value}>
                {cleanHtml(t.DisplayActivity.Title)}
              </div>
              <div className={styles.value}>
                {cleanHtml(t.DisplayActivity.Subtitle)}
              </div>
              <div className={styles.value}>
                <div>{moment(ts).format('ll')}</div>
                <div>{moment(ts).format('hh:mm A')}</div>
              </div>
            </React.Fragment>
          )
        })}

        {timeline.length === 0 && !meta.loading && (
          <div className={styles['no-events']}>No Events recorded</div>
        )}
      </div>
    </Spin>
  )
}

export default AllActivity
