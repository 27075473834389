import { takeLatest, put, call } from 'redux-saga/effects'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'
import rsf from '../../../../firebase'
import { GET_ADDRESS } from '../../../../actions/watson/player/address/ActionTypes'
import {
  setLoadingAddress,
  storeAddress,
} from '../../../../actions/watson/player/address/address'
import { GetAddress } from '../../../../actions/watson/player/address/address.types'
import { Address } from '../../../../reducers/watson/player/address/address.types'

function* getAddress(action: GetAddress): Generator {
  yield put(setLoadingAddress())
  const { playerId } = action.payload

  // eslint-disable-next-line
  // @ts-ignore
  const snapshot = yield call(
    rsf.firestore.getCollection,
    `players/${playerId}/address`
  )

  const addresses: Address[] = []
  ;(snapshot as QuerySnapshot).forEach((doc: DocumentData) => {
    addresses.push({
      ...doc.data(),
      id: doc.id,
    })
  })

  yield put(storeAddress(addresses))
}

export default function* watchAddress(): Generator {
  yield takeLatest(GET_ADDRESS, getAddress)
}
