import firebase from 'firebase/app'
import {
  take,
  fork,
  cancel,
  takeLatest,
  all,
  takeEvery,
  put,
  call,
} from 'redux-saga/effects'
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types'
import axios from 'axios'

import rsf from '../../../../firebase'
import {
  SyncOrders,
  GetKycDetails,
} from '../../../../actions/watson/player/kyc/kyc.types'
import {
  KycOrder,
  KycDocumentData,
} from '../../../../reducers/watson/kyc/kyc.types'
import {
  CANCEL_ORDERS_SYNC,
  SYNC_ORDERS,
  GET_KYC_DETAILS,
} from '../../../../actions/watson/player/kyc/ActionTypes'
import {
  storeOrders,
  storeKycDetails,
} from '../../../../actions/watson/player/kyc/kyc'
import getAccessToken from '../../../../helpers/access-token'

function* syncOrders(action: SyncOrders): Generator {
  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .where('Category', '==', 'Kyc')
    .where('State', 'in', ['Complete', 'Failed', 'Manual_Supervision'])
    .orderBy('CreatedAt', 'desc')

  // eslint-disable-next-line
  // @ts-ignore
  const task: Task = yield fork(rsf.firestore.syncCollection, query, {
    successActionCreator: (snapshot: QuerySnapshot) => {
      const orders: KycOrder[] = []

      snapshot.forEach((doc: DocumentData) => {
        const data = doc.data()
        data.id = doc.id
        orders.push(data)
      })

      return storeOrders(orders)
    },
  })

  yield take(CANCEL_ORDERS_SYNC)
  yield cancel(task)
}

function* getDocumentDetails(action: GetKycDetails): Generator {
  const apiEndpoint = `${process.env.REACT_APP_FETCH_URL}/twirp/pb.Watson/GetKycDocumentDetails`
  const { orderId, documentType } = action.payload
  const authToken = yield call(getAccessToken)

  const data = yield axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`,
    },
    data: { order_id: orderId },
  })
    .then(response => response.data)
    .catch(error => {
      const e = error.response.data
      return new Error(`${e.code} - ${e.msg}`)
    })

  if (data instanceof Error) yield put(storeKycDetails(data, documentType))
  else
    yield put(
      storeKycDetails(
        { ...(data as KycDocumentData), order_id: orderId },
        documentType
      )
    )
}

function* watchSync(): Generator {
  yield takeLatest(SYNC_ORDERS, syncOrders)
}

function* watchKycDetails(): Generator {
  yield takeEvery(GET_KYC_DETAILS, getDocumentDetails)
}

export default function* watchKyc(): Generator {
  yield all([watchSync(), watchKycDetails()])
}
