import { PassesReducer } from './passes.types'
import {
  PassesAction,
  StorePasses,
  StorePassesPayload,
} from '../../../../actions/watson/player/passes/passes.types'
import {
  LOADING_PASSES,
  STORE_PASSES,
} from '../../../../actions/watson/player/passes/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: PassesReducer = {
  passes: null,
  error: null,
  meta: { loading: false },
}

const passesReducer = (
  state = initialState,
  action: PassesAction
): PassesReducer => {
  switch (action.type) {
    case LOADING_PASSES:
      return {
        passes: null,
        error: null,
        meta: { loading: true },
      }

    case STORE_PASSES: {
      const { payload, error } = action as StorePasses

      if (!error) {
        const { passes } = payload as StorePassesPayload

        return {
          passes,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        passes: null,
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default passesReducer
