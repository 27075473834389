import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers/reducers'
import rootSaga from './sagas/sagas'

const initialState = {}
const enhancers: any[] = [] // eslint-disable-line @typescript-eslint/no-explicit-any
const logger = createLogger({
  collapsed: true,
})
const sagaMiddleware = createSagaMiddleware()

let middleware
if (process.env.NODE_ENV !== 'test') {
  middleware = [sagaMiddleware, logger]
} else {
  middleware = [sagaMiddleware]
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/reducers', () =>
    store.replaceReducer(rootReducer)
  )
}

export default store

sagaMiddleware.run(rootSaga)
