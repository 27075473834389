import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import styles from './Referrals.module.css'
import { AppState } from '../../../../../../reducers/reducers'
import {
  syncReferrals,
  cancelReferralsSync,
} from '../../../../../../actions/watson/player/referrals/referrals'
import getPlayerDpUrl from '../../../../../../helpers/fb-photo-url'
import { ReferralProfile } from '../../../../../../types/ReferralProfile.types'
import getReferralRewardCap from './api'
import useSyncFirestoreDocument from '../../../../../../hooks/useSyncFirestoreDocument'

interface Props {
  count?: number
}

const Referrals: React.FC<Props> = props => {
  const { count } = props
  const dispatch = useDispatch()
  const playerId =
    useSelector(
      (state: AppState) => state.watson.player.profile.profile?.PlayerId
    ) || ''
  let referrals = useSelector(
    (state: AppState) => state.watson.player.referrals.referrals
  )
  const referralProfile = useSyncFirestoreDocument<ReferralProfile | null>(
    `players/${playerId}/private/referralProfile`
  )
  const [referralRewardCap, setReferralRewardCap] = useState(0)

  if (count) {
    referrals = referrals.slice(0, count)
  }

  useEffect(() => {
    dispatch(syncReferrals(playerId))

    return (): void => {
      dispatch(cancelReferralsSync())
    }
  }, [dispatch, playerId])

  const fetchReferralRewardCap = useCallback(async () => {
    if (playerId) {
      const amount = await getReferralRewardCap(playerId)
      setReferralRewardCap(amount)
    }
  }, [playerId])
  useEffect(() => {
    fetchReferralRewardCap()
  }, [fetchReferralRewardCap])

  return (
    <div id={styles.referrals}>
      <div className={styles.title}>Referrals</div>

      <div className={styles.referralAmountReceived}>
        <span>Referral reward received: ₹</span>
        <span>{referralProfile?.ReferralCashAmount}</span>
        <span> / </span>
        <span>{`₹${referralRewardCap}`}</span>
      </div>

      <div className={styles.table}>
        <div className={styles.th} />
        <div className={styles.th}>NAME</div>
        <div className={styles.th}>PLAYER ID</div>
        <div className={styles.th}>DATE</div>

        {referrals.map(referral => {
          const timestamp = referral.Date.seconds * 1000

          return (
            <React.Fragment key={referral.PlayerId}>
              <div className={`${styles.td} ${styles.dp}`}>
                <img src={getPlayerDpUrl(referral.PhotoUrl)} alt="dp" />
              </div>
              <div className={styles.td}>{referral.DisplayName}</div>
              <div className={styles.td}>{referral.PlayerId}</div>
              <div className={styles.td}>
                <div>{moment(timestamp).format('ll')}</div>
                <div>{moment(timestamp).format('hh:mm A')}</div>
              </div>
            </React.Fragment>
          )
        })}
      </div>

      {referrals.length === 0 && (
        <div className={styles['no-referrals']}>No referrals</div>
      )}
    </div>
  )
}

export default Referrals
