import { all } from 'redux-saga/effects'

import payout from './payout/payout'
import kyc from './kyc/kyc'
import player from './player/player'
import cf from './cfSession/cfSession'
import deposit from './deposit/deposit'

export default function* watchWatson(): Generator {
  yield all([kyc(), player(), payout(), cf(), deposit()])
}
