import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  DownloadOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import styles from './UploadPopup.module.css'
import validationSchema from '../offerValidationSchema'
import { AppState } from '../../../../reducers/reducers'
import { createOffer } from '../api'
import { User } from '../../../../reducers/auth/auth.types'

interface Props {
  offers: string[][]
  hide(): void
  filename: string
  file: File | null
  updateFiles(): void
}

const TITLES = {
  scanning: 'Scanning for Errors',
  uploading: 'Uploading',
  success: 'Upload Successful!',
  error: 'Error Uploading!',
}

const UploadPopup: React.FC<Props> = ({
  offers,
  hide,
  filename,
  file,
  updateFiles,
}) => {
  const { user } = useSelector((state: AppState) => state.auth)
  const [title, setTitle] = useState(TITLES.scanning)

  const transformValues = (v: any): any => {
    const values = { ...v }
    values.t1 = moment(v.t1, 'DD-MM-YYYY hh:mm:ss aa').format()
    values.t2 = moment(v.t2, 'DD-MM-YYYY hh:mm:ss aa').format()
    values.t3 = moment(v.t3, 'DD-MM-YYYY hh:mm:ss aa').format()
    values.t4 = moment(v.t4, 'DD-MM-YYYY hh:mm:ss aa').format()

    values.details = values.details?.split(',').filter((d: string) => d) || []
    values.hide_offer_on_screens =
      values.hide_offer_on_screens?.split(',').filter((s: string) => s) || []
    values.audience_ids =
      values.audience_ids?.split(',').filter((a: string) => a) || []
    values.audience_correlation = v.audience_correlation === 'OR' ? 0 : 1
    values.budget_value = parseFloat(v.budget_value) || 0
    values.max_amount = parseFloat(v.max_amount) || 0
    values.min_amount = parseFloat(v.min_amount) || 0
    values.max_reward_amount = parseFloat(v.max_reward_amount) || 0
    values.percent_value = parseFloat(v.percent_value) || 0
    values.p_max_attempt_overall = parseInt(v.p_max_attempt_overall, 10) || 0
    values.p_max_attempt_per_day = parseInt(v.p_max_attempt_per_day, 10) || 0
    values.p_subsequent_use_interval_minutes =
      parseInt(v.p_subsequent_use_interval_minutes, 10) || 0
    values.should_show = v.should_show === 'true'
    values.tags = v.tags ? JSON.parse(v.tags) : []

    if (v.show_after) {
      values.show_after = moment(
        v.show_after,
        'DD-MM-YYYY hh:mm:ss aa'
      ).format()
    } else {
      values.show_after = values.t1
    }

    return values
  }

  const [errorCsv, setErrorCsv] = useState('')
  const [errorsCount, setErrorsCount] = useState(0)
  const [uploadCount, setUploadCount] = useState(0)
  const [apiCalled, setApiCalled] = useState(false)
  const [uploaded, setUploaded] = useState(false)

  useEffect(() => {
    setApiCalled(false)
  }, [])

  const handleUpload = useCallback(async () => {
    setUploadCount(0)
    const offerValues = offers.slice(1)
    let keys = offers[0]

    if (keys[keys.length - 1] === 'errors') {
      keys = keys.slice(0, -1)
    }

    setTitle(TITLES.scanning)
    let ec = 0
    const validationErrors = offerValues.map((o, i) => {
      let values: { [index: string]: any } = keys.reduce((v, k, j) => {
        return {
          ...v,
          [k]: o[j],
        }
      }, {})

      values = transformValues(values)

      try {
        validationSchema.validateSync(values, { abortEarly: false })
        let errCode = 0
        offerValues.forEach((ofr, j) => {
          if (i !== j) {
            if (o[0] === ofr[0]) errCode = 1
            if (o[1] === ofr[1]) errCode = 2
          }
        })

        if (errCode === 1) {
          ec += 1
          return ['Duplicate name']
        }
        if (errCode === 2) {
          ec += 1
          return ['Duplicate coupon_code']
        }

        return false
      } catch (e) {
        ec += 1
        return e.errors
      }
    })

    let hasErrors = validationErrors.some(v => v !== false)
    setErrorsCount(ec)

    const generateErrorCsv = (errors: string[]) => {
      const href = `${keys.join(',')},errors\n${offerValues.reduce(
        (csv: string, offr, i) => {
          let o = offr
          if (keys.length < offr.length) {
            o = offr.slice(0, -1)
          }

          let newCsv = `${csv}${csv ? '\n' : ''}`
          newCsv += o.reduce((c: string, of, j) => {
            // if value contains `,`
            // put it in quote
            const quoted = `${of.includes(',') ? '"' : ''}${of}${
              of.includes(',') ? '"' : ''
            }${j !== o.length - 1 ? ',' : ''}`
            const x = `${c}${quoted}`
            return x
          }, '')

          return `${newCsv},"${errors[i] || ''}"`
        },
        ''
      )}`

      return `data:application/octet-stream,${encodeURIComponent(href)}`
    }

    if (hasErrors) {
      const href = generateErrorCsv(validationErrors)
      setErrorCsv(href)
      setTitle(TITLES.error)
      return
    }

    setTitle(TITLES.uploading)

    ec = 0 // error count
    setApiCalled(true)
    let uc = 0 // successful upload count
    /* eslint-disable no-restricted-syntax, no-await-in-loop */
    const apiErrors: string[] = []
    for (const o of offerValues) {
      let values: { [index: string]: any } = keys.reduce((v, k, i) => {
        return {
          ...v,
          [k]: o[i],
        }
      }, {})
      values = transformValues(values)

      const { success, error: err } = await createOffer(values, user as User)

      if (success) {
        apiErrors.push('')
        uc += 1
        setUploadCount(uc)
      } else {
        ec += 1
        apiErrors.push(err)
      }
    }

    hasErrors = apiErrors.some(a => a)

    const storeCsv = (content: string) => {
      const data = {
        filename,
        content,
        date: moment().format(),
        offersCount: offerValues.length,
        uploadedBy: user?.email,
      }

      try {
        const filenamesKey = 'allCsvFiles'
        const f = `${filename}-${moment().format()}`
        localStorage.setItem(f, JSON.stringify(data))
        let allFiles: any = localStorage.getItem(filenamesKey)

        if (allFiles) {
          allFiles = JSON.parse(allFiles)
        } else {
          allFiles = []
        }
        ;(allFiles as any).push(f)
        localStorage.setItem(filenamesKey, JSON.stringify(allFiles))
        updateFiles()
      } catch (err) {
        console.error(err)
      }
    }

    if (hasErrors) {
      setErrorsCount(ec)
      const href = generateErrorCsv(apiErrors)
      setErrorCsv(href)
      setTitle(TITLES.error)
      setUploaded(false)
    } else {
      setUploaded(true)
      if (file) {
        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = function (evt) {
          storeCsv(evt.target?.result as string)
        }
      }
    }
  }, [offers, user, file, filename, updateFiles])

  useEffect(() => {
    handleUpload()
  }, [handleUpload])

  const errorCsvFilename = filename.split('.csv').join('-errors.csv')

  return (
    <div id={styles.UploadPopup}>
      {!uploaded ? (
        <div className={styles.header}>
          {title}
          <CloseOutlined
            className={styles.close}
            onClick={() => {
              hide()
            }}
          />
        </div>
      ) : null}

      <div className={styles.content}>
        {!uploaded && <div className={styles.filename}>{filename}</div>}

        {errorCsv ? (
          <>
            <div className={styles.errorHeader}>
              {`Errors found in ${errorsCount} offer${
                errorsCount === 1 ? '' : 's'
              }`}
            </div>
            <div className={styles.offersCount}>
              {`${
                !apiCalled ? offers.length - 1 : offers.length - 1 - errorsCount
              } of ${offers.length - 1} Offers ${
                apiCalled ? 'Uploaded' : 'Scanned'
              }`}
            </div>
            <progress
              value={(uploadCount * 100) / (offers.length - 1)}
              max={100}
            />
            <div className={styles.errorHelp}>
              Errors are mentioned in the CSV file below. Please make the
              changes and re-upload the document.
            </div>
            <div className={styles.download}>
              <a
                href={errorCsv}
                download={errorCsvFilename}
                className={styles.errorCsv}
              >
                <DownloadOutlined className={styles.downloadIcon} />
                {`Download "${errorCsvFilename}"`}
              </a>
            </div>
          </>
        ) : null}

        {!errorCsv && !uploaded ? (
          <>
            <div className={styles.offersCount}>
              {`${uploadCount} of ${offers.length - 1} Offers Uploaded`}
            </div>
            <progress
              value={(uploadCount * 100) / (offers.length - 1)}
              max={100}
            />
          </>
        ) : null}

        {uploaded ? (
          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  marginRight: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CheckCircleOutlined className={styles.checkSvg} />
              </div>
              <div style={{ width: '100%' }}>
                <div className={styles.successHeader}>
                  Successful
                  <CloseOutlined
                    onClick={() => {
                      hide()
                    }}
                  />
                </div>
                <div className={styles.filenameSuccess}>
                  {`${filename} successfuly added`}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default UploadPopup
