import React from 'react'
import styles from './CFSessions.module.css'
import { CFSession } from '../../../../../reducers/watson/player/orders/orders.types'

interface Props {
  cfSession: CFSession
}

const Details: React.FC<Props> = props => {
  const { cfSession } = props

  return (
    <div className={styles.details}>
      <div className={styles.key}>SESSION ID</div>
      <div className={styles.value}>{cfSession.session_id}</div>

      <div className={styles.key}>TOURNAMENT ID</div>
      <div className={styles.value}>{cfSession.tournament_id}</div>

      <div className={styles.key}>TOURNAMENT STATE</div>
      <div className={styles.value}>{cfSession.tournament_state || '-'}</div>

      <div className={styles.key}>BUY IN ORDER ID</div>
      {cfSession.money_in.cashin_orders.map(cio => {
        return (
          <div key={cio} className={styles.value}>
            {cio}
          </div>
        )
      })}

      <div className={styles.key}>BUY IN AMOUNT</div>
      <div className={styles.value}>
        {`₹ ${cfSession.money_in.buy_in_amount}`}
      </div>

      <div className={styles.key}>TOP UP AMOUNT</div>
      <div className={styles.value}>
        {`${
          cfSession.money_in.top_up_amount
            ? `₹ ${cfSession.money_in.top_up_amount}`
            : '-'
        }`}
      </div>

      <div className={styles.key}>TOP UP COUNT</div>
      <div className={styles.value}>
        {cfSession.money_in.top_up_count || '-'}
      </div>

      <div className={styles.key}>SIT IN TIME</div>
      <div className={styles.value}>-</div>

      <div className={styles.key}>CASH OUT ORDER ID</div>
      <div className={styles.value}>
        {cfSession.money_out.cashout_order || '-'}
      </div>

      <div className={styles.key}>WINNINGS AMOUNT</div>
      <div className={styles.value}>
        {`${
          cfSession.money_out.winnings_amount
            ? `₹ ${cfSession.money_out.winnings_amount}`
            : '-'
        }`}
      </div>

      <div className={styles.key}>UNUSED REFUND ORDER ID</div>
      <div className={styles.value}>
        {cfSession.money_out.unused_refund_order_ref || '-'}
      </div>

      <div className={styles.key}>UNUSED AMOUNT</div>
      <div className={styles.value}>
        {`${
          cfSession.money_out.unused_amount
            ? `₹ ${cfSession.money_out.unused_amount}`
            : '-'
        }`}
      </div>

      <div className={styles.key}>CASH OUT TIME</div>
      <div className={styles.value}>-</div>

      <div className={styles.key}>IS CASHED OUT</div>
      <div className={styles.value}>
        {cfSession.is_cashed_out ? 'True' : 'False'}
      </div>

      <div className={styles.key}>ROUNDS HISTORY</div>
      {cfSession.rounds_history?.map(rh => {
        return (
          <div key={rh} className={styles.value}>
            {rh}
          </div>
        )
      })}
    </div>
  )
}

export default Details
