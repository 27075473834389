import React from 'react'
import { useSelector } from 'react-redux'
import styles from './Home.module.css'
import Cards from './Cards'
import SearchPlayer from './SearchPlayer'
import { AppState } from '../../../reducers/reducers'
import useSyncKycOrders from '../Kyc/useSyncKycOrders'

const Home: React.FC = () => {
  const { orders } = useSelector((state: AppState) => state.watson.kyc)
  useSyncKycOrders(orders)

  return (
    <div id={styles.home}>
      <SearchPlayer />
      <Cards />
    </div>
  )
}

export default Home
