import {
  SetLoadingPassTransactions,
  SyncPassTransactions,
  CancelPassTransactionsSync,
  StorePassTransactions,
} from './passTransactions.types'
import {
  SET_LOADING_PASS_TRANSACTIONS,
  SYNC_PASS_TRANSACTIONS,
  CANCEL_PASS_TRANSACTIONS_SYNC,
  STORE_PASS_TRANSACTIONS,
} from './ActionTypes'
import { PassTransaction } from '../../../../reducers/watson/player/passTransactions/passTransactions.types'

export const setLoadingPassTransactions = (): SetLoadingPassTransactions => ({
  type: SET_LOADING_PASS_TRANSACTIONS,
  payload: {},
})

export const syncPassTransactions = (
  playerId: string
): SyncPassTransactions => ({
  type: SYNC_PASS_TRANSACTIONS,
  payload: { playerId },
})

export const cancelPassTransactionsSync = (): CancelPassTransactionsSync => ({
  type: CANCEL_PASS_TRANSACTIONS_SYNC,
  payload: {},
})

export const storePassTransactions = (
  passTransactions: PassTransaction[] | Error
): StorePassTransactions => ({
  type: STORE_PASS_TRANSACTIONS,
  payload:
    passTransactions instanceof Error ? passTransactions : { passTransactions },
  ...(passTransactions instanceof Error && { error: true }),
})
