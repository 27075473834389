import { takeLatest, put, fork, take, cancel } from 'redux-saga/effects'
import firebase from 'firebase/app'
import { Task } from 'redux-saga'
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types'
import rsf from '../../../../firebase'
import {
  SYNC_REWARDS,
  CANCEL_REWARDS_SYNC,
} from '../../../../actions/watson/player/orders/ActionTypes'
import { SyncRewards } from '../../../../actions/watson/player/orders/orders.types'
import {
  setLoadingRewards,
  storeRewards,
} from '../../../../actions/watson/player/orders/orders'
import { Order } from '../../../../reducers/watson/player/orders/orders.types'

function* syncRewards(action: SyncRewards): Generator {
  yield put(setLoadingRewards())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .where('Category', '==', 'Reward')
    .where('State', 'in', ['Complete', 'Failed', 'Pending', 'Processing'])
    .orderBy('CreatedAt', 'desc')

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const orders: Order[] = []

        snapshot.forEach((doc: DocumentData) => {
          orders.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        return storeRewards(orders)
      },
    }
  )

  yield take(CANCEL_REWARDS_SYNC)
  yield cancel(task as Task)
}

export default function* watchRewards(): Generator {
  yield takeLatest(SYNC_REWARDS, syncRewards)
}
