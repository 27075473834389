import React from 'react'
import styles from './MSOrderDetails.module.css'
import {
  Order,
  LBPrizeProps,
  LBEntryFeeProps,
} from '../../../../../reducers/watson/player/orders/orders.types'

interface Props {
  order: Order
}

export enum OrderType {
  LB_PRIZE = 'LB_PRIZE',
  LB_ENTRY = 'LB_ENTRY',
}

const MSOrderDetails: React.FC<Props> = ({ order }) => {
  const { Type } = order.Product

  let GameName
  let LBCategory
  let LBRef
  let MembershipRef
  let PrizeAmount
  let Rake
  if (Type === OrderType.LB_PRIZE) {
    ;({ GameName, LBCategory, LBRef, MembershipRef, PrizeAmount } = order
      .Product.LBPrizeProps as LBPrizeProps)
  } else {
    ;({ GameName, LBCategory, LBRef, Rake } = order.Product
      .LBEntryFeeProps as LBEntryFeeProps)
  }

  const wallets =
    Type === OrderType.LB_PRIZE
      ? order.Fulfillments.map(p => p.Payment)
      : order.Payments

  return (
    <div className={styles.MSOrderDetails}>
      <div className={styles.card}>
        <div className={styles.title}>More Details</div>

        {Type === OrderType.LB_PRIZE ? (
          <>
            <div className={styles.key}>PRIZE AMOUNT</div>
            <div className={styles.value}>{`₹${PrizeAmount}`}</div>
          </>
        ) : null}

        <div className={styles.key}>GAME</div>
        <div className={styles.value}>{GameName}</div>

        <div className={styles.key}>LEADERBOARD CATEGORY</div>
        <div className={styles.value}>{LBCategory}</div>

        <div className={styles.key}>LEADERBOARD ID</div>
        <div className={styles.value}>{LBRef}</div>

        {Type === OrderType.LB_PRIZE ? (
          <>
            <div className={styles.key}>MEMBERSHIP REF</div>
            <div className={styles.value}>{MembershipRef}</div>
          </>
        ) : null}

        {Type === OrderType.LB_ENTRY ? (
          <>
            <div className={styles.key}>RAKE</div>
            <div className={styles.value}>{`₹${Rake}`}</div>
          </>
        ) : null}
      </div>

      <div className={styles.card}>
        <div className={styles.title}>
          {Type === OrderType.LB_PRIZE ? (
            <>Winning Distribution</>
          ) : (
            <>Wallet Details</>
          )}
        </div>

        <div className={styles.wallets}>
          <div className={styles.th}>
            {Type === OrderType.LB_PRIZE ? (
              <>WALLET DESTINATION</>
            ) : (
              <>WALLET SOURCE</>
            )}
          </div>
          <div className={styles.th}>AMOUNT</div>

          {wallets.map(wallet => {
            return (
              <React.Fragment
                key={
                  Type === OrderType.LB_PRIZE
                    ? wallet?.Destination
                    : wallet?.Source
                }
              >
                <div className={styles.td}>
                  {Type === OrderType.LB_PRIZE
                    ? wallet?.Destination
                    : wallet?.Source}
                </div>
                <div className={styles.td}>
                  {wallet?.Currency === 'INR' ? '₹' : null}
                  {`${wallet?.Amount}`}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MSOrderDetails
