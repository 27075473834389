import {
  Order,
  Payment,
} from '../../../../../reducers/watson/player/orders/orders.types'

const filterWinningOrders =
  (wallet: string) =>
  (order: Order): boolean => {
    let isWalletTransaction = false

    const reduce = (payments: Payment[]): boolean => {
      return payments.reduce((prevVal: boolean, p) => {
        if (prevVal) return prevVal

        if (p.Source === wallet || p.Destination === wallet) return true

        return false
      }, false)
    }

    if (order.Payments) {
      isWalletTransaction = reduce(order.Payments)

      if (isWalletTransaction) return isWalletTransaction
    }

    if (order.Refunds) {
      isWalletTransaction = reduce(order.Refunds)

      if (isWalletTransaction) return isWalletTransaction
    }

    if (order.Fulfillments) {
      if (order.Fulfillments[0].Payment) {
        isWalletTransaction =
          order.Fulfillments[0].Payment.Source === wallet ||
          order.Fulfillments[0].Payment.Destination === wallet

        if (isWalletTransaction) return isWalletTransaction
      }

      if (order.Fulfillments[0].Payout) {
        if (order.Fulfillments[0].Payment) {
          isWalletTransaction =
            order.Fulfillments[0].Payment.Source === wallet ||
            order.Fulfillments[0].Payment.Destination === wallet

          if (isWalletTransaction) return isWalletTransaction
        }
      }
    }

    return isWalletTransaction
  }

export default filterWinningOrders
