interface Constants {
  [index: string]: string
}

export const BudgetType: Constants = {
  inr: 'INR',
  passes: 'Passes',
  offerUsage: 'Usage',
}

export const RewardType: Constants = {
  // fixed: 'FIXED',
  cash: 'CASH',
  // scratchCard: 'SCRATCH_CARD',
  staggered: 'STAGGERED',
}

export const RewardDestination: Constants = {
  depositWallet: 'INR_DEPOSIT',
  winningsWallet: 'INR_WINNINGS',
  bonusWallet: 'INR_BONUS',
  // passesWallet: 'PASSES',
}

export const OfferTimestamps: Constants = {
  '1': 'Offer Visible Time',
  '2': 'Offer Active Time',
  '3': 'Offer Closing Time',
  '4': 'Offer Unpublish Time',
}

export const AudienceCorrelation = ['OR', 'AND']

export const OfferTags = [
  {
    tag: 'welcome_offer',
    tagTitle: 'Welcome Offer',
  },
]
