import React, { useEffect, useState } from 'react'
import moment from 'moment'
import firebase from 'firebase/app'
import { Button, Spin } from 'antd'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual } from 'react-redux'
import { DocumentData } from '@firebase/firestore-types'
import Geohash from 'ngeohash'
import styles from './PersonalDetails.module.css'
import { Profile } from '../../../../../../../reducers/watson/player/profile/profile.types'
import { AppState } from '../../../../../../../reducers/reducers'
import { KycOrder } from '../../../../../../../reducers/watson/kyc/kyc.types'
import { PlayerLocation } from '../../../../../../../types/PlayerLocation.types'
import { FundAccount } from '../../../../../../../types/FundAccount.types'
import { ActiveDevice } from '../../../../../../../types/ActiveDevice.types'

interface Props {
  profile: Profile
}

const selectKyc = createSelector(
  (state: AppState) => state.watson.player.kyc,
  kyc => kyc
)

const isKycVerified = (kycOrders: KycOrder[]): boolean => {
  const latestPanOrder = kycOrders
    .filter(order => order.Product.KycDocumentType === 'PAN')
    .sort((o1, o2) => {
      if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
      if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
      return 0
    })[0]
  if (latestPanOrder?.State !== 'Complete') return false

  const latestOtherOrder = kycOrders
    .filter(order => order.Product.KycDocumentType !== 'PAN')
    .sort((o1, o2) => {
      if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
      if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
      return 0
    })[0]
  if (latestOtherOrder?.State !== 'Complete') return false

  return true
}

const renderKycStatus = (
  kycVerified: boolean,
  loading: boolean
): JSX.Element => {
  return (
    <>
      {/* eslint-disable-next-line */}
      {loading ? (
        <Spin tip="loading" />
      ) : kycVerified ? (
        <div style={{ color: 'green' }}>Approved</div>
      ) : (
        <div style={{ color: 'red' }}>Not approved</div>
      )}
    </>
  )
}

const PersonalDetails: React.FC<Props> = props => {
  const { profile } = props
  const { orders: kycOrders, meta } = useSelector(selectKyc, shallowEqual)

  const kycVerified = isKycVerified(kycOrders)

  const [location, setLocation] = useState<PlayerLocation | undefined>()
  useEffect(() => {
    firebase
      .firestore()
      .doc(`/players/${profile.PlayerId}/private/location`)
      .onSnapshot(snap => {
        const loc = snap.data()
        if (loc) {
          const { Lat, Long } = loc
          setLocation({
            Lat,
            Long,
          })
        }
      })
  }, [profile.PlayerId])

  const [fundAccounts, setFundAccounts] = useState<FundAccount[] | undefined>()
  useEffect(() => {
    firebase
      .firestore()
      .collection(`players/${profile.PlayerId}/fundAccounts`)
      .where('VirtualAddress', '!=', '')
      .onSnapshot(snap => {
        const fAccounts: FundAccount[] = []
        snap.forEach((doc: DocumentData) => {
          const fa: FundAccount = doc.data()
          fAccounts.push(fa)
        })
        setFundAccounts(fAccounts)
      })
  }, [profile])

  const [activeDevice, setActiveDevice] = useState<ActiveDevice | undefined>()
  useEffect(() => {
    firebase
      .firestore()
      .doc(`players/${profile.PlayerId}/private/activeDevice`)
      .onSnapshot(snap => {
        setActiveDevice(snap.data() as ActiveDevice)
      })
  }, [profile])

  return (
    <div className={styles['personal-details']}>
      <div className={styles.title}>Personal Details</div>
      <div className={styles.email}>
        <div className={styles.key}>EMAIL</div>
        <div className={styles.value}>{profile.Email || '-'}</div>
      </div>
      <div className={styles.phone}>
        <div className={styles.key}>PHONE NUMBER</div>
        <div className={styles.value}>{profile.Phone || '-'}</div>
      </div>
      <div className={styles.location}>
        <div className={styles.key}>Last Geohash</div>
        <div className={styles.value}>
          {location?.Lat && location?.Long
            ? Geohash.encode(location.Lat, location.Long)
            : '-'}
        </div>
      </div>
      <div className={styles['member-since']}>
        <div className={styles.key}>MEMBER SINCE</div>
        <div className={styles.value}>
          {moment(profile.JoinedAt.seconds * 1000).format('LLL')}
        </div>
      </div>
      <div className={styles.kyc}>
        <div className={styles.key}>KYC STATUS</div>
        <div className={styles['kyc-value']}>
          {renderKycStatus(kycVerified, meta.loadingOrders)}
          <Button type="link" className={styles['kyc-btn']}>
            View KYC
          </Button>
        </div>
      </div>
      <div className={styles.upi}>
        <div className={styles.key}>UPI IDs</div>
        {fundAccounts?.map((f, i) => {
          return (
            <div key={f.VirtualAddress}>
              {i + 1}. {f.VirtualAddress}
            </div>
          )
        })}
        {fundAccounts?.length === 0 ? (
          <div className={styles.value}>No UPI fund accounts</div>
        ) : null}
      </div>
      <div className={styles.phone}>
        <div className={styles.key}>ANDROID ID</div>
        <div className={styles.value}>{activeDevice?.DeviceId || '-'}</div>
      </div>
    </div>
  )
}

export default PersonalDetails
