import React from 'react'
import styles from './Separator.module.css'

interface Props {
  style?: {
    [index: string]: string | number
  }
}

const Separator: React.FC<Props> = props => {
  const { style } = props

  return (
    <div
      className={styles.separator}
      style={{
        border: '1px #ddd solid',
        margin: '40px 0 20px 0',
        ...style,
      }}
    />
  )
}

export default Separator
