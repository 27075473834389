import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Button, message, Modal } from 'antd'
import axios from 'axios'
import styles from './Wallets.module.css'
import {
  cancelWalletsSync,
  syncWallets,
} from '../../../../../../actions/watson/player/wallets/wallets'
import { AppState } from '../../../../../../reducers/reducers'
import {
  cancelPayoutProfileSync,
  syncPayoutProfile,
} from '../../../../../../actions/watson/player/payoutProfile/payoutProfile'
import {
  cancelPassesSync,
  syncPasses,
} from '../../../../../../actions/watson/player/passes/passes'
import { consoleApiBaseUrl } from '../../../../../../config'
import getAccessToken from '../../../../../../helpers/access-token'

interface Props {
  playerId: string
}

const adminMoveMoney = ['rajesh@getmega.com', 'admin.watson@getmega.com', 'amrit@getmega.com']

const selectWallets = createSelector(
  (state: AppState) => state.watson.player.wallets,
  walletsData => walletsData
)
const selectPasses = createSelector(
  (state: AppState) => state.watson.player.passes,
  passes => passes
)

const Wallets: React.FC<Props> = props => {
  const { playerId } = props
  const dispatch = useDispatch()
  const { wallets } = useSelector(selectWallets, shallowEqual)
  const { user } = useSelector((state: AppState) => state.auth)
  const { payoutProfile } = useSelector(
    (state: AppState) => state.watson.player.payoutProfile
  )
  const { passes } = useSelector(selectPasses, shallowEqual)
  const passesCount: { [index: string]: number } =
    passes
      ?.filter(pass => pass.State === 'Unused')
      .reduce((pc: { [index: string]: number }, pass) => {
        const temp = pc
        temp[pass.Category] = (temp[pass.Category] || 0) + 1
        return temp
      }, {}) || {}
  const [reason, setReason] = useState('')

  useEffect(() => {
    dispatch(syncWallets(playerId))
    dispatch(syncPayoutProfile(playerId))
    dispatch(syncPasses(playerId))

    return (): void => {
      dispatch(cancelWalletsSync())
      dispatch(cancelPayoutProfileSync())
      dispatch(cancelPassesSync())
    }
  }, [playerId, dispatch])

  const [isMoveMoneyVisible, setIsMoveMoneyVisible] = useState(false)

  const handleMoveMoneyClose = (): void => {
    setIsMoveMoneyVisible(false)
  }

  const [moveMoneyError, setMoveMoneyError] = useState('')
  const [movingMoney, setMovingMoney] = useState(false)
  const moveMoney = async (): Promise<void> => {
    const endpoint = `${consoleApiBaseUrl}/twirp/pb.WalletMigrator/DepositToWinnings`

    setMoveMoneyError('')
    setMovingMoney(true)
    await axios(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${await getAccessToken()}`,
      },
      data: {
        player_id: playerId,
        agent_id: user?.email,
        transfer_reason: reason,
      },
    })
      .then(() => {
        message.success('Successfully transferred money!')
        setIsMoveMoneyVisible(false)
      })
      .catch(err => {
        console.error(err)
        const e =
          err?.response?.data?.msg ||
          'Error transferring money! Please try again.'
        setMoveMoneyError(e)
        message.error(e)
      })

    setMovingMoney(false)
  }

  return (
    <div id={styles.wallets}>
      <div className={styles.title}>
        <span>Wallet Balance</span>
        {!!user?.email && adminMoveMoney.includes(user.email) && (
          <Button
            type="link"
            onClick={(): void => {
              setIsMoveMoneyVisible(true)
            }}
          >
            Move money
          </Button>
        )}
      </div>
      <div className={styles['wallets-list']}>
        <div className={styles['list-item']}>
          <div className={styles.key}>DEPOSIT</div>
          <div className={styles.value}>
            {`₹${wallets.Deposit?.Balance || '0'}`}
          </div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>WINNINGS</div>
          <div className={styles.value}>
            {`₹${wallets.Winnings?.Balance || '0'}`}
          </div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>BONUS</div>
          <div className={styles.value}>-</div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>CURRENT WITHDRAWAL LIMIT</div>
          <div className={styles.value}>
            {`₹${payoutProfile?.MinCashoutAllowed} to ₹${payoutProfile?.MaxCashoutAllowed}`}
          </div>
        </div>
      </div>

      <div className={styles.title}>PASS BALANCE</div>
      <div className={styles['passes-list']}>
        <div className={styles['list-item']}>
          <div className={styles.key}>UNIVERSAL PASS</div>
          <div className={styles.value}>{passesCount.Mega || 0}</div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>TRIVIA</div>
          <div className={styles.value}>{passesCount.Trivia || 0}</div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>CASUAL</div>
          <div className={styles.value}>{passesCount.Casual || 0}</div>
        </div>
        <div className={styles['list-item']}>
          <div className={styles.key}>CARDS</div>
          <div className={styles.value}>{passesCount.Cards || 0}</div>
        </div>
      </div>

      <Modal
        visible={isMoveMoneyVisible}
        footer={null}
        closable={false}
        onCancel={handleMoveMoneyClose}
        wrapClassName="move-money-modal"
      >
        <div className={styles['modal-title']}>Move Money</div>

        <div className={styles['modal-label']}>wallet source</div>
        <select disabled>
          <option>Deposit Wallet</option>
        </select>

        <div className={styles['modal-label']}>wallet destination</div>
        <select disabled>
          <option>Winnings Wallet</option>
        </select>

        <div className={styles['modal-label']}>reason</div>
        <textarea
          rows={5}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setReason(e.currentTarget.value)
          }}
        />

        <div className={styles['modal-cta']}>
          {moveMoneyError && (
            <div className={styles['modal-error']}>{moveMoneyError}</div>
          )}

          <Button
            type="primary"
            className={`${styles['modal-confirm']} ${styles['modal-btn']}`}
            onClick={moveMoney}
            loading={movingMoney}
          >
            Confirm Transferring
          </Button>
          <Button
            type="link"
            className={styles['modal-btn']}
            onClick={(): void => {
              setIsMoveMoneyVisible(false)
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Wallets
