import { takeLatest, put, fork, take, cancel } from 'redux-saga/effects'
import firebase from 'firebase/app'
import { Task } from 'redux-saga'
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types'
import rsf from '../../../../firebase'
import {
  SYNC_INTERCOM_REFUNDS,
  CANCEL_INTERCOM_REFUNDS_SYNC,
} from '../../../../actions/watson/player/orders/ActionTypes'
import { SyncIntercomRefunds } from '../../../../actions/watson/player/orders/orders.types'
import {
  setLoadingRewards,
  storeIntercomRefunds,
} from '../../../../actions/watson/player/orders/orders'
import { Order } from '../../../../reducers/watson/player/orders/orders.types'

function* syncIntercomRefunds(action: SyncIntercomRefunds): Generator {
  yield put(setLoadingRewards())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection('central_orders')
    .where('PlayerId', '==', playerId)
    .where('Product.RewardProps.RewardType', '==', 'INTERCOM')

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const orders: Order[] = []

        snapshot.forEach((doc: DocumentData) => {
          orders.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        return storeIntercomRefunds(orders)
      },
    }
  )

  yield take(CANCEL_INTERCOM_REFUNDS_SYNC)
  yield cancel(task as Task)
}

export default function* watchRewards(): Generator {
  yield takeLatest(SYNC_INTERCOM_REFUNDS, syncIntercomRefunds)
}
