import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import { StaggeredReward } from '../../../types/StaggeredReward.types'

const collectionRef = firebase.firestore().collection('taskTemplates')

const useStaggeredTemplates = (): StaggeredReward[] => {
  const [templates, setTemplates] = useState<StaggeredReward[]>([])

  useEffect(() => {
    const unsubscribe = collectionRef
      .where('Type', '==', 'STAGGERED')
      .where('State', '==', 'ACTIVE')
      .onSnapshot(snapshot => {
        const aud: StaggeredReward[] = []
        snapshot.forEach((doc: DocumentData) => {
          aud.push(doc.data())
        })
        setTemplates(aud)
      })

    return (): void => {
      unsubscribe()
    }
  }, [])

  return templates
}

export default useStaggeredTemplates
