import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import styles from './AllTransactions.module.css'
import {
  syncAllOrders,
  cancelAllOrdersSync,
} from '../../../../../actions/watson/player/orders/orders'
import { AppState } from '../../../../../reducers/reducers'
import Row from './Row'
import usePagination from './usePagination'

const AllTransactions: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { meta, orders } = useSelector(
    (state: AppState) => state.watson.player.orders
  )
  const sortedOrders = Object.values(orders).sort((a, b) => {
    if (a.CreatedAt > b.CreatedAt) return -1
    if (a.CreatedAt < b.CreatedAt) return 1
    return 0
  })

  useEffect(() => {
    if (playerId) dispatch(syncAllOrders(playerId, 'allOrders'))

    return (): void => {
      dispatch(cancelAllOrdersSync())
    }
  }, [playerId, dispatch])

  usePagination(playerId as string, meta.allOrders)

  return (
    <div className="all-transactions">
      <Spin tip="Loading Orders" spinning={meta.allOrders.loading}>
        <div id={styles['all-transactions']}>
          <div className={styles.title}>All Transactions</div>

          <div className={`${styles.head} ${styles.first}`} />
          <div className={styles.head}>NAME</div>
          <div className={styles.head}>FORMAT</div>
          <div className={styles.head}>AMOUNT</div>
          <div className={styles.head}>REFUNDED</div>
          <div className={styles.head}>ORDER ID</div>
          <div className={styles.head}>DATE & TIME</div>
          <div className={`${styles.head} ${styles.last}`}>STATUS</div>

          {sortedOrders.map(order => {
            return (
              <Row
                order={order}
                orders={Object.values(orders)}
                key={order.id}
              />
            )
          })}

          {sortedOrders.length === 0 && !meta.allOrders.loading && (
            <div className={styles['no-transactions']}>No Transactions</div>
          )}

          {meta.allOrders.lastPage && (
            <div className={styles.lastPage}>No more orders!</div>
          )}
        </div>
      </Spin>
    </div>
  )
}

export default AllTransactions
