import moment from 'moment'
import * as Yup from 'yup'
import { RewardType, OfferTags } from './CreateOffer/constants'

const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Too Long!').required('name Required!'),
  coupon_code: Yup.string()
    .max(10, 'Too Long!')
    .required('coupon_code Required!'),
  title: Yup.string().required('title Required!'),
  subtitle: Yup.string().required('subtitle Required!'),
  ftu_title: Yup.string().when('tags', {
    is: (tags: string[]) => tags.includes(OfferTags[0].tag),
    then: Yup.string().required('Ftu Title is Required!'),
  }),
  ftu_subtitle: Yup.string().when('tags', {
    is: (tags: string[]) => tags.includes(OfferTags[0].tag),
    then: Yup.string().required('Ftu Subtitle is Required!'),
  }),
  icon_url: Yup.string().required('icon_url Required!'),
  details: Yup.array().of(Yup.string().required("Detail can't be empty")),
  budget_type: Yup.string().required('budget_type Required!'),
  budget_value: Yup.number().required('budget_value Required!'),
  p_max_attempt_per_day: Yup.number().required(
    'p_max_attempt_per_day Required!'
  ),
  p_max_attempt_overall: Yup.number().required(
    'p_max_attempt_overall Required!'
  ),
  p_subsequent_use_interval_minutes: Yup.number().required(
    'p_subsequent_use_interval_minutes Required!'
  ),
  t1: Yup.string()
    .test('match', 'T1 should be > current time', t1 => {
      return (t1 as string) > moment().format()
    })
    .required('t1 Required!'),
  t2: Yup.string()
    .required('t2 Required!')
    .when('t1', (t1: string, schema: any) => {
      return schema.test({
        test: (t2: string) => t1 && t2 > t1,
        message: 'T2 should be >= T1',
      })
    }),
  t3: Yup.string()
    .required('t3 Required!')
    .when('t2', (t2: string, schema: any) => {
      return schema.test({
        test: (t3: string) => t2 && t3 > t2,
        message: 'T3 should be >= T2',
      })
    }),
  t4: Yup.string()
    .required('t4 Required!')
    .when('t3', (t3: string, schema: any) => {
      return schema.test({
        test: (t4: string) => t3 && t4 > t3,
        message: 'T4 should be >= T3',
      })
    }),
  min_amount: Yup.number().required('min_amount Required'),
  max_amount: Yup.number()
    .required('max_amount Required!')
    .when('min_amount', (minAmount: number, schema: any) => {
      return schema.test({
        test: (maxAmount: number) => minAmount && minAmount <= maxAmount,
        message: 'max_amount should be >= min_amount',
      })
    }),
  reward_type: Yup.string().required('reward_type Required!'),
  product_id: Yup.string().when('reward_type', {
    is: RewardType.fixed,
    then: Yup.string().required('product_id Required!'),
  }),
  percent_value: Yup.number().when('reward_type', {
    is: RewardType.cash,
    then: Yup.number()
      .min(0)
      .max(999, 'Cashback value cannot be greater than 999%')
      .required('percent_value Required!'),
  }),
  max_reward_amount: Yup.number().when('reward_type', {
    is: RewardType.cash,
    then: Yup.number().required('max_reward_amount Required!'),
  }),
  destination: Yup.string().when('reward_type', {
    is: RewardType.cash,
    then: Yup.string().required('destination Required!'),
  }),
  staggered_reward_template_id: Yup.string().when('reward_type', {
    is: RewardType.staggered,
    then: Yup.string().required('staggered_reward_template_id Required!'),
  }),
  should_show: Yup.boolean().required('should_show Required!'),
  show_after: Yup.string().when('should_show', {
    is: true,
    then: Yup.string()
      .required('show_after Required!')
      .when('t4', (t4: string, schema: any) => {
        return schema.test({
          test: (showAfter: string) => t4 && showAfter <= t4,
          message: 'show_after should be <= T4',
        })
      }),
  }),
  audience_ids: Yup.array().of(Yup.string()).required('audience_ids Required!'),
})

export default validationSchema
