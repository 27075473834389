import React, { useState } from 'react'
import { Select, InputNumber, Input, Button, Modal, message } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styles from './ProcessCustomerSupportReward.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import { AppState } from '../../../../../reducers/reducers'
import { consoleApiBaseUrl } from '../../../../../config'
import getAccessToken from '../../../../../helpers/access-token'

const { Option } = Select

interface Props {
  rewardType: 'GENERAL' | 'AGAINST_ORDER'
  order?: Order // failed order
  visible: boolean
  setRewardModalVisible(v: boolean): void
}

const reasons = ['Failed order', 'Tech issue', 'Other']
const userCategories = ['New user', 'Pro player']

const CustomerSupportReward: React.FC<Props> = props => {
  const { rewardType, order, visible, setRewardModalVisible } = props
  const [reason, setReason] = useState('')
  const [userCategory, setUserCategory] = useState('')
  const orderAmount =
    order?.Payments?.reduce((t: number, p): number => {
      return t + p.Amount
    }, 0) || 1
  const [amount, setAmount] = useState(orderAmount)
  const [ticketId, setTicketId] = useState('')
  const [chatId, setChatId] = useState('')
  const [comment, setComment] = useState('')
  const [rewarding, setRewarding] = useState(false)
  const [error, setError] = useState('')

  const player = useSelector(
    (state: AppState) => state.watson.player.profile.profile
  )
  const { user } = useSelector((state: AppState) => state.auth)

  const processReward = async (): Promise<void> => {
    // call reward processing api
    const endpoint = `${consoleApiBaseUrl}/twirp/pb.Reward/GiveCSReward`
    const data = {
      reward_type: rewardType,
      reward_info: {
        amount,
        reason,
        comment,
        order_id: order?.id ?? '',
        wallet: 'DEPOSIT',
      },
      user_info: {
        user_id: player?.PlayerId,
        user_category: userCategory,
      },
      admin_info: {
        name: user?.displayName,
        email: user?.email,
      },
      chat_info: {
        chat_id: chatId,
        ticket_id: ticketId,
      },
    }

    setRewarding(true)
    const responseData = await axios(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${await getAccessToken()}`,
      },
      data,
    })
      .then(response => response.data)
      .catch(err => {
        console.error(err)
        message.error(err.response?.data?.msg)
        setError(err.response?.data?.msg || '')
        return null
      })
    setRewarding(false)

    if (responseData) {
      message.success(
        `Reward order created with order id: ${responseData.generated_order_id}`
      )
      setRewardModalVisible(false)
    }
  }

  const handleCancel = (): void => {
    setRewardModalVisible(false)
  }

  return (
    <Modal
      visible={visible}
      title={`Reward for ${player?.DisplayName}`}
      onOk={processReward}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={processReward}
          loading={rewarding}
        >
          Reward
        </Button>,
      ]}
    >
      <div id={styles['customer-support-reward']}>
        <div className={styles.key}>REWARD TYPE</div>
        <div className={styles.value}>{rewardType}</div>

        {order && (
          <>
            <div className={styles.key}>ORDER ID</div>
            <div className={styles.value}>{order?.id}</div>
          </>
        )}

        <div className={styles.key}>AMOUNT (Rs)</div>
        <div className={styles.value}>
          <InputNumber
            value={amount}
            min={1}
            max={user?.email !== 'rajesh@getmega.com' ? 1000 : 50000}
            onChange={(amt): void => {
              setAmount(amt || 1)
            }}
          />
        </div>

        <div className={styles.key}>REASON</div>
        <div className={styles.value}>
          <Select
            allowClear
            value={reason}
            onChange={(value): void => {
              setReason(value || '')
            }}
          >
            <Option value="">--- Select reason for reward ---</Option>
            {reasons.map(r => {
              return (
                <Option value={r} key={r}>
                  {r}
                </Option>
              )
            })}
          </Select>
        </div>

        <div className={styles.key}>COMMENT</div>
        <div className={styles.value}>
          <Input
            value={comment}
            onChange={(e): void => {
              setComment(e.currentTarget.value || '')
            }}
          />
        </div>

        <div className={styles.key}>WALLET</div>
        <div className={styles.value}>DEPOSIT</div>

        <div className={styles.key}>USER CATEGORY</div>
        <div className={styles.value}>
          <Select
            allowClear
            value={userCategory}
            onChange={(value): void => {
              setUserCategory(value || '')
            }}
          >
            <Option value="">--- Select user category ---</Option>
            {userCategories.map(c => {
              return (
                <Option value={c} key={c}>
                  {c}
                </Option>
              )
            })}
          </Select>
        </div>

        <div className={styles.key}>TICKET ID</div>
        <div className={styles.value}>
          <Input
            value={ticketId}
            onChange={(e): void => {
              setTicketId(e.currentTarget.value)
            }}
          />
        </div>

        <div className={styles.key}>CHAT ID</div>
        <div className={styles.value}>
          <Input
            value={chatId}
            onChange={(e): void => {
              setChatId(e.currentTarget.value)
            }}
          />
        </div>

        {error && <div className={styles.error}>{`Error: ${error}`}</div>}
      </div>
    </Modal>
  )
}

export default CustomerSupportReward
