import React, { KeyboardEvent } from 'react'
import styles from './Sidebar.module.css'
import menuItems from './menuitems'

interface Props {
  selectedMenuItem: string
  setSelectedMenuItem(ssmi: string): void
}

const Sidebar: React.FC<Props> = props => {
  const { selectedMenuItem, setSelectedMenuItem } = props

  const getClassName = (menuItemKey: string): string =>
    `${styles['menu-item']} ${styles[menuItemKey] || ''} ${
      selectedMenuItem === menuItemKey ? styles.selected : ''
    }`

  return (
    <div id={styles.sidebar}>
      {Object.keys(menuItems).map(menuItemKey => {
        return (
          <div
            key={menuItemKey}
            className={getClassName(menuItemKey)}
            onClick={(): void => setSelectedMenuItem(menuItemKey)}
            role="button"
            tabIndex={0}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
              if (e.key === 'Enter') {
                setSelectedMenuItem(menuItemKey)
              }
            }}
          >
            {menuItems[menuItemKey].title}
          </div>
        )
      })}
    </div>
  )
}

export default Sidebar
