import React, { useState } from 'react'
import styles from './InstantContestJoin.module.css'
import {
  Order,
  Payment,
} from '../../../../../reducers/watson/player/orders/orders.types'
import GemIcon from '../../../../../assets/crystal.svg'
import CouponDetails from './CouponDetails/CouponDetails'

interface Props {
  order: Order
}

const Deposit: React.FC<Props> = props => {
  const { order } = props
  let Fulfillments = order.Payments
  if (order.Fulfillments)
    Fulfillments = order.Fulfillments.map(f => f.Payment) as Payment[]

  let destintion =
    order.Payments[0].Currency === 'INR' ? 'Deposit' : 'User_Gems'
  if (Fulfillments && Fulfillments[0]?.Currency === 'GEM')
    destintion = 'User_Gems'

  const [offerId] = useState(order.Product.OfferProps?.OfferId)

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>More Details</div>

        <div className={styles.key}>PAYMENT GATEWAY</div>
        <div className={styles.value}>{order.Payments[0].PG}</div>

        <div className={styles.key}>ORDER REFERENCE ID</div>
        <div className={styles.value}>{order.Payments[0].PgTxnID}</div>
      </div>

      <div className={styles.card}>
        <div className={styles.title}>Wallet Balance</div>

        <div className={styles.wallets}>
          <div className={styles.th}>WALLET DESTINATION</div>
          <div className={styles.th}>AMOUNT</div>

          {Fulfillments.map(wallet => {
            return (
              <React.Fragment key={wallet?.Destination}>
                <div className={styles.td}>{destintion}</div>
                <div className={styles.td}>
                  {wallet?.Currency === 'INR' ? (
                    '₹'
                  ) : (
                    <img src={GemIcon} alt="gem icon" className="gem" />
                  )}
                  {` ${wallet?.Amount}`}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.title}>Coupon Details</div>

        <div className={styles.key}>OFFER ID</div>
        <div className={styles.value}>{offerId || '----'}</div>

        {offerId ? (
          <CouponDetails
            offerId={offerId}
            orderAmount={order.Product.CashDepositProps?.Amount || 0}
          />
        ) : null}
      </div>
    </>
  )
}

export default Deposit
