import { Offer } from '../Offer'

type BudgetType = 'INR' | 'Passes' | 'Usage'

const getBudgetTypeAndValue = (
  offer: Offer
): {
  budgetType: BudgetType
  budgetValue: number
} => {
  let budgetType: BudgetType
  let budgetValue: number
  if (offer.AllottedBudget?.INR?.IsEnabled) {
    budgetType = 'INR'
    budgetValue = offer.AllottedBudget.INR.Value
  } else if (offer.AllottedBudget?.Passes?.IsEnabled) {
    budgetType = 'Passes'
    budgetValue = offer.AllottedBudget.Passes.Value
  } else {
    budgetType = 'Usage'
    budgetValue = offer.AllottedBudget?.Usage?.Value
  }

  return {
    budgetType,
    budgetValue,
  }
}

export default getBudgetTypeAndValue
