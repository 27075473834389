import { ReferralsReducer } from './referrals.types'
import {
  ReferralsAction,
  StoreReferrals,
  StoreReferralsPayload,
} from '../../../../actions/watson/player/referrals/referrals.types'
import {
  LOADING_REFERRALS,
  STORE_REFERRALS,
} from '../../../../actions/watson/player/referrals/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: ReferralsReducer = {
  referrals: [],
  error: null,
  meta: { loading: false },
}

const referralsReducer = (
  state = initialState,
  action: ReferralsAction
): ReferralsReducer => {
  switch (action.type) {
    case LOADING_REFERRALS:
      return {
        referrals: [],
        error: null,
        meta: { loading: true },
      }

    case STORE_REFERRALS: {
      const { payload, error } = action as StoreReferrals

      if (!error) {
        const { referrals } = payload as StoreReferralsPayload

        return {
          referrals,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        referrals: [],
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default referralsReducer
