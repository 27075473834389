import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './TopPlayedGames.module.css'
import { AppState } from '../../../../../../reducers/reducers'
import {
  syncGameProfiles,
  cancelGameProfilesSync,
} from '../../../../../../actions/watson/player/gameProfiles/gameProfiles'

interface Props {
  count?: number
}

const TopPlayedGames: React.FC<Props> = props => {
  const { count } = props
  const dispatch = useDispatch()
  const playerId =
    useSelector(
      (state: AppState) => state.watson.player.profile.profile?.PlayerId
    ) || ''
  let { gameProfiles } = useSelector(
    (state: AppState) => state.watson.player.gameProfiles
  )

  gameProfiles = gameProfiles.sort((a, b) => {
    if (a.RoomTicketsCount > b.RoomTicketsCount) return -1
    if (a.RoomTicketsCount < b.RoomTicketsCount) return 1
    return 0
  })
  if (count) {
    gameProfiles = gameProfiles.slice(0, count)
  }

  useEffect(() => {
    dispatch(syncGameProfiles(playerId))

    return (): void => {
      cancelGameProfilesSync()
    }
  }, [playerId, dispatch])

  return (
    <div id={styles['top-played-games']}>
      <div className={styles.title}>Top 5 Games Played</div>

      <div className={styles.table}>
        <div className={styles.th} />
        <div className={styles.th}>GAME</div>
        <div className={styles.th}>PLAYED COUNT</div>

        {gameProfiles.map(gp => {
          return (
            <React.Fragment key={gp.GameId}>
              <div className={`${styles.td} ${styles['game-icon']}`}>
                <img
                  src={`//via.placeholder.com/64?text=${gp.GameName}`}
                  alt={gp.GameName}
                />
              </div>
              <div className={styles.td}>{gp.GameName}</div>
              <div className={styles.td}>{gp.RoomTicketsCount}</div>
            </React.Fragment>
          )
        })}
      </div>

      {gameProfiles.length === 0 && (
        <div className={styles['no-games']}>No games played</div>
      )}
    </div>
  )
}

export default TopPlayedGames
