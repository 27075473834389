import React from 'react'
import styles from './AudienceService.module.css'
import useConfig from '../DepositOffers/useConfig'
import CreateAudience from './CreateAudience'

const AudienceService: React.FC = () => {
  const { attributeType } = useConfig()

  return (
    <div id={styles['audience-service']}>
      <CreateAudience attributeType={attributeType} />
    </div>
  )
}

export default AudienceService
