import React, { useState } from 'react'
import moment from 'moment'
import styles from './MoonshotLeaderboard.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import MoreIcon from '../../../../../assets/more.svg'
import LessIcon from '../../../../../assets/less.svg'
import Timestamp from '../../../../../components/Timestamp/Timestamp'
import OrderState from '../AllTransactions/OrderState'
import MSOrderDetails, { OrderType } from './MSOrderDetails'

interface Props {
  order: Order
}

const MSOrderRow: React.FC<Props> = ({ order }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  let LBName
  let amount
  let tds = ''
  if (order.Product.Type === OrderType.LB_ENTRY) {
    LBName = order.Product.LBEntryFeeProps?.LBName
    amount = `- ₹${order.Payments.reduce((sum, p) => {
      return sum + p.Amount
    }, 0)}`
  } else {
    LBName = order.Product.LBPrizeProps?.LBName

    amount = order.Fulfillments[0]
      ? `+ ₹${order.Fulfillments[0].Payment?.Amount}`
      : ''
    tds = order.Fulfillments[1]
      ? `- ₹${order.Fulfillments[1].Payment?.Amount}`
      : ''
  }

  return (
    <>
      <div className={styles.value}>
        {!detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={MoreIcon}
            alt="more"
            onClick={(): void => setDetailsExpanded(true)}
          />
        )}
        {detailsExpanded && (
          // eslint-disable-next-line
          <img
            src={LessIcon}
            alt="less"
            onClick={(): void => setDetailsExpanded(false)}
          />
        )}
      </div>
      <div className={styles.value}>{order.Title}</div>
      <div
        className={`${styles.value} ${
          order.Product.Type === 'LB_ENTRY' ? styles.red : styles.green
        }`}
      >
        {amount}
      </div>
      <div className={`${styles.value} ${styles.red}`}>{tds}</div>
      <div className={styles.value}>{LBName}</div>
      <div className={styles.value}>{order.id}</div>
      <div className={styles.value}>
        <Timestamp timestamp={moment(order.CreatedAt.seconds * 1000)} />
      </div>
      <div className={`${styles.value} ${styles[order.State]}`}>
        <OrderState state={order.State} />
      </div>

      {detailsExpanded ? <MSOrderDetails order={order} /> : null}
    </>
  )
}

export default MSOrderRow
