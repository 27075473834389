import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import moment from 'moment'
import styles from './PassTransactions.module.css'
import { AppState } from '../../../../../reducers/reducers'
import {
  syncPassTransactions,
  cancelPassTransactionsSync,
} from '../../../../../actions/watson/player/passTransactions/passTransactions'
import GemIcon from '../../../../../assets/crystal.svg'

const PassTransactions: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { passTransactions, meta } = useSelector(
    (state: AppState) => state.watson.player.passTransactions
  )

  useEffect(() => {
    if (playerId) dispatch(syncPassTransactions(playerId))

    return (): void => {
      dispatch(cancelPassTransactionsSync())
    }
  }, [dispatch, playerId])

  const renderAmount = (amount: number, currency: string): JSX.Element => {
    return (
      <>
        {currency === 'INR' && '₹ '}
        {amount}
        {currency === 'GEM' && (
          <>
            {' '}
            <img src={GemIcon} alt="gem icon" className="gem" />
          </>
        )}
      </>
    )
  }

  return (
    <Spin tip="Loading Pass Transactions" spinning={meta.loading}>
      <div id={styles['pass-transactions']}>
        <div className={styles.title}>Pass Transactions</div>

        <div className={`${styles.head} ${styles.first}`}>TITLE</div>
        <div className={styles.head}>TRANSACTION TYPE</div>
        <div className={styles.head}>CATEGORY</div>
        <div className={styles.head}>PASS MAX AMOUNT</div>
        <div className={styles.head}>AMOUNT USED</div>
        <div className={styles.head}>ORDER REF</div>
        <div className={styles.head}>PASS PATH</div>
        <div className={`${styles.head} ${styles.last}`}>DATE & TIME</div>

        {passTransactions.map(pt => {
          const ts = pt.CreatedAt.seconds * 1000

          return (
            <React.Fragment key={pt.id}>
              <div className={styles.value}>{pt.Title}</div>
              <div
                className={`${styles.value} ${
                  styles[pt.TxnType.toLowerCase()]
                }`}
              >
                {pt.TxnType}
              </div>
              <div className={styles.value}>{pt.Category}</div>
              <div className={styles.value}>
                {renderAmount(pt.PassMaxAmount, pt.Currency)}
              </div>
              <div className={styles.value}>
                {pt.TxnType === 'DEBIT' && renderAmount(pt.Amount, pt.Currency)}
              </div>
              <div className={styles.value}>{pt.OrderRef}</div>
              <div className={styles.value}>{pt.PassPath}</div>
              <div className={styles.value}>
                <div>{moment(ts).format('ll')}</div>
                <div>{moment(ts).format('hh:mm A')}</div>
              </div>
            </React.Fragment>
          )
        })}

        {passTransactions.length === 0 && !meta.loading && (
          <div className={styles['no-pass-transactions']}>
            No Pass Transactions
          </div>
        )}
      </div>
    </Spin>
  )
}

export default PassTransactions
