import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Spin, Button, Popconfirm } from 'antd'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import moment from 'moment'
import styles from './CFOrder.module.css'
import {
  Order,
  CFSession,
} from '../../../../../reducers/watson/player/orders/orders.types'
import GemIcon from '../../../../../assets/crystal.svg'
import { AppState } from '../../../../../reducers/reducers'
import ProcessCustomerSupportReward from '../ProcessCustomerSupportReward/ProcessCustomerSupportReward'
import getAccessToken from '../../../../../helpers/access-token'
import useSyncFirestoreDocument from '../../../../../hooks/useSyncFirestoreDocument'
import { Tournament } from '../../../../../types/Tournament.types'
import TournamentType from './TournamentType'

interface Props {
  order: Order
}

const collectionRef = firebase.firestore().collection('central_orders')

const CFOrder: React.FC<Props> = props => {
  const { order } = props
  const sessionPath = order.Fulfillments[0].Reference?.TournamentSessionRef
  const [loadingSessionDetails, setLoadingSessionDetails] = useState(false)
  const [sessionDetails, setSessionDetails] = useState<CFSession | null>(null)
  const [closingSession, setClosingSession] = useState(false)
  const cfSessions = useSelector(
    (state: AppState) => state.watson.player.orders.cfSessions
  )
  const [cfSession, setCFSession] = useState<CFSession>()

  // refund rewards
  const [refundDialogVisible, setRefundDialogVisible] = useState(false)
  const [showRefund, setShowRefund] = useState(false)
  const [linkedOrders, setLinkedOrders] = useState<Order[]>([])

  const tournament = useSyncFirestoreDocument<Tournament | null>(
    order.Product.TableTicketProps.TournamentRef
  )

  const getSessionDetails = useCallback(async (sid: string): Promise<void> => {
    setLoadingSessionDetails(true)

    const apiEndpoint = `${process.env.REACT_APP_FETCH_URL}/twirp/pb.WatsonCfManager/GetCfSessionDetails`
    const body = {
      session_id: sid,
    }

    await axios(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${await getAccessToken()}`,
      },
      data: body,
    })
      .then(response => {
        const { data } = response
        setSessionDetails(data)
      })
      .catch(error => {
        console.error(error)
      })

    setLoadingSessionDetails(false)
  }, [])

  const closeSession = useCallback(
    async (sid: string, tid: string): Promise<void> => {
      setClosingSession(true)

      const apiEndpoint = `${process.env.REACT_APP_FETCH_URL}/twirp/pb.WatsonCfManager/CloseActiveCfSession`
      const body = {
        session_id: sid,
        tournament_id: tid,
      }

      await axios(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${await getAccessToken()}`,
        },
        data: body,
      })
        .then(() => {
          //
        })
        .catch(error => {
          console.error(error)
        })

      setClosingSession(false)
    },
    []
  )

  useEffect(() => {
    if (sessionPath) {
      // const sp = sessionPath.split('/')
      // const sid = sp[sp.length - 1]
      // setSessionId(sid)

      // use this when details api is working
      // getSessionDetails(sid)

      // fow now fetch from redux store
      setCFSession(cfSessions[sessionPath])
    }
  }, [order, getSessionDetails, cfSessions, sessionPath])

  useEffect(() => {
    if (!refundDialogVisible) {
      collectionRef
        .where('Product.RewardProps.ParentOrderRef', '==', order.id)
        .get()
        .then(snapshot => {
          const lo: Order[] = []
          snapshot.forEach((doc: DocumentData) => {
            lo.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          setLinkedOrders(lo)

          if (lo.length === 0 && !order.Refunds && order.Payments) {
            setShowRefund(true)
          } else {
            const refundedAmount = lo.reduce((t: number, o): number => {
              return t + (o.Fulfillments[0].Payment?.Amount || 0)
            }, 0)
            const orderAmount =
              order.Payments?.reduce((t: number, p): number => {
                return t + (p.Amount || 0)
              }, 0) || 0

            setShowRefund(refundedAmount > 0 && refundedAmount < orderAmount)
          }
        })
        .catch(err => {
          console.error(err)
          setShowRefund(true)
        })
    }
  }, [order, refundDialogVisible])

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>SESSION DETAILS</div>

        <div className={styles['session-details']}>
          {loadingSessionDetails && (
            <Spin spinning tip="Loading session details..." />
          )}
          {/* {!loadingSessionDetails && !sessionDetails && (
            <>
              <div className={styles.error}>Error fetching session details</div>
              <Button
                onClick={(): void => {
                  getSessionDetails(sessionId)
                }}
              >
                Retry
              </Button>
            </>
          )} */}
          {
            /* sessionDetails */ cfSession && (
              <>
                <div className={styles.key}>SESSION ID</div>
                <div className={styles.value}>{cfSession.session_id}</div>

                <div className={styles.key}>TOURNAMENT ID</div>
                <div className={styles.value}>{cfSession.tournament_id}</div>

                <div className={styles.key}>TOURNAMENT STATE</div>
                <div className={styles.value}>
                  {cfSession.tournament_state || '-'}
                </div>

                {tournament ? <TournamentType t={tournament} /> : null}

                <div className={styles.key}>BUY IN ORDER ID</div>
                {cfSession.money_in.cashin_orders.map(cio => {
                  return (
                    <div key={cio} className={styles.value}>
                      {cio}
                    </div>
                  )
                })}

                <div className={styles.key}>BUY IN AMOUNT</div>
                <div className={styles.value}>
                  {`₹ ${cfSession.money_in.buy_in_amount}`}
                </div>

                <div className={styles.key}>TOP UP AMOUNT</div>
                <div className={styles.value}>
                  {`${
                    cfSession.money_in.top_up_amount
                      ? `₹ ${cfSession.money_in.top_up_amount}`
                      : '-'
                  }`}
                </div>

                <div className={styles.key}>TOP UP COUNT</div>
                <div className={styles.value}>
                  {cfSession.money_in.top_up_count || '-'}
                </div>

                <div className={styles.key}>SIT IN TIME</div>
                <div className={styles.value}>-</div>

                <div className={styles.key}>CASH OUT ORDER ID</div>
                <div className={styles.value}>
                  {cfSession.money_out.cashout_order || '-'}
                </div>

                <div className={styles.key}>WINNINGS AMOUNT</div>
                <div className={styles.value}>
                  {`${
                    cfSession.money_out.winnings_amount
                      ? `₹ ${cfSession.money_out.winnings_amount}`
                      : '-'
                  }`}
                </div>

                <div className={styles.key}>UNUSED REFUND ORDER ID</div>
                <div className={styles.value}>
                  {cfSession.money_out.unused_refund_order_ref || '-'}
                </div>

                <div className={styles.key}>UNUSED AMOUNT</div>
                <div className={styles.value}>
                  {`${
                    cfSession.money_out.unused_amount
                      ? `₹ ${cfSession.money_out.unused_amount}`
                      : '-'
                  }`}
                </div>

                <div className={styles.key}>CASH OUT TIME</div>
                <div className={styles.value}>-</div>

                <div className={styles.key}>IS CASHED OUT</div>
                <div className={styles.value}>
                  {cfSession.is_cashed_out ? 'True' : 'False'}
                </div>

                <div className={styles.key}>ROUNDS HISTORY</div>
                {cfSession.rounds_history?.map(rh => {
                  return (
                    <div key={rh} className={styles.value}>
                      {rh}
                    </div>
                  )
                })}
              </>
            )
          }
        </div>
        {sessionDetails?.can_close_manually && (
          <div className={styles['close-session']}>
            <Popconfirm
              title="Are you sure you want to close this session?"
              onConfirm={(): void => {
                closeSession('', '')
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={closingSession}>Close Session</Button>
            </Popconfirm>
          </div>
        )}
      </div>
      <div className={styles.card}>
        <div className={styles.title}>WALLET DETAILS</div>

        <div className={styles.wallets}>
          <div className={styles.th}>WALLET SOURCE</div>
          <div className={styles.th}>AMOUNT</div>

          {order.Payments.map(wallet => {
            return (
              <React.Fragment key={wallet?.Source}>
                <div className={styles.td}>{wallet?.Source}</div>
                <div className={styles.td}>
                  {wallet?.Currency === 'INR' ? (
                    '₹'
                  ) : (
                    <img src={GemIcon} alt="gem icon" className="gem" />
                  )}
                  {` ${wallet?.Amount}`}
                </div>
              </React.Fragment>
            )
          })}
        </div>

        {showRefund && (
          <div className={styles['process-refund']}>
            <ProcessCustomerSupportReward
              rewardType="AGAINST_ORDER"
              order={order}
              visible={refundDialogVisible}
              setRewardModalVisible={setRefundDialogVisible}
            />
            <Button
              danger
              type="primary"
              onClick={(): void => {
                setRefundDialogVisible(true)
              }}
            >
              Refund
            </Button>
          </div>
        )}

        {linkedOrders.length > 0 && (
          <>
            <div className={`${styles.title} ${styles['linked-rewards']}`}>
              Linked Rewards
            </div>

            <div className={styles['linked-orders']}>
              <div className={styles.th}>ORDER ID</div>
              <div className={styles.th}>AMOUNT</div>
              <div className={styles.th}>DATE</div>

              {linkedOrders.map(lo => {
                const timestamp = moment(lo.CreatedAt.seconds * 1000)

                return (
                  <React.Fragment key={lo.id}>
                    <div className={styles.td}>{lo.id}</div>
                    <div className={styles.td}>
                      {lo.Fulfillments[0].Payment?.Currency === 'INR' ? (
                        '₹'
                      ) : (
                        <img src={GemIcon} alt="gem icon" className="gem" />
                      )}
                      {` ${lo.Fulfillments[0].Payment?.Amount}`}
                    </div>
                    <div className={styles.td}>
                      <div>{timestamp.format('ll')}</div>
                      <div>{timestamp.format('hh:mm A')}</div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CFOrder
