import React, { useState } from 'react'
import { Input, Alert } from 'antd'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'
import { useHistory } from 'react-router-dom'

import styles from './SearchPlayer.module.css'
import { getProfile } from '../../../actions/watson/player/profile/profile'
import { AppState } from '../../../reducers/reducers'

const { Search } = Input

const selectProfile = createSelector(
  (state: AppState) => state.watson.player.profile,
  profile => profile
)

const SearchPlayer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { error, meta } = useSelector(selectProfile, shallowEqual)
  const { loading } = meta
  const [playerId, setPlayerId] = useState('')

  const searchPlayer = (pid: string): void => {
    if (pid) {
      dispatch(getProfile(pid, history))
    }

    setPlayerId(pid)
  }

  return (
    <div className={styles.search}>
      <div className={styles.text}>Search Individual Profiles</div>
      <div className={styles['search-input-div']}>
        <Search
          placeholder="Search by Player ID"
          enterButton
          size="large"
          allowClear
          className={styles['search-input']}
          onSearch={searchPlayer}
          loading={loading}
        />
      </div>
      {error && playerId && (
        <Alert
          message={error.message}
          type="error"
          showIcon
          className={styles['player-not-found']}
          banner
        />
      )}
    </div>
  )
}

export default SearchPlayer
