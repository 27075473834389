import { PassTransactionsReducer } from './passTransactions.types'
import {
  PassTransactionsAction,
  StorePassTransactionsPayload,
  StorePassTransactions,
} from '../../../../actions/watson/player/passTransactions/passTransactions.types'
import {
  SET_LOADING_PASS_TRANSACTIONS,
  STORE_PASS_TRANSACTIONS,
} from '../../../../actions/watson/player/passTransactions/ActionTypes'

const initialState: PassTransactionsReducer = {
  passTransactions: [],
  error: null,
  meta: { loading: false },
}

const passTransactionsReducer = (
  state = initialState,
  action: PassTransactionsAction
): PassTransactionsReducer => {
  switch (action.type) {
    case SET_LOADING_PASS_TRANSACTIONS:
      return {
        passTransactions: [],
        error: null,
        meta: { loading: true },
      }

    case STORE_PASS_TRANSACTIONS: {
      const { payload, error } = action as StorePassTransactions

      if (!error) {
        const { passTransactions } = payload as StorePassTransactionsPayload

        return {
          passTransactions,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        passTransactions: [],
        error: payload as Error,
        meta: { loading: false },
      }
    }
    default:
      return state
  }
}

export default passTransactionsReducer
