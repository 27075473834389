import React, { useEffect, useState } from 'react'
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps,
  FormikValues,
} from 'formik'
// import firebase from 'firebase/app'
import { Checkbox, DatePicker, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import styles from './CreateAudience.module.css'
import { createAudience, getAttributes } from './api'
import { AppState } from '../../../reducers/reducers'
import { User } from '../../../reducers/auth/auth.types'
import {
  AttributeCorrelation,
  AUDIENCE_ATTRIBUTE_TYPES,
  CreationIntent,
  MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE,
  TIME_DURATION_TYPE,
} from './constants'

interface Props {
  attributeType: any
}

// const collectionRef = firebase.firestore().collection('attributes')

const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Too Long!').required('Required'),
  description: Yup.string().max(100, 'Too Long!').required('Required'),
  attributes: Yup.array().of(
    Yup.object().shape({
      category: Yup.string().required('Required'),
      id: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      boolean_value: Yup.bool().default(false),
      number_min: Yup.number().when('type', {
        is: 'NUMBER',
        then: Yup.number().required('Required'),
      }),
      number_max: Yup.number().when('type', {
        is: 'NUMBER',
        then: Yup.number()
          .when('id', {
            is: MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE,
            then: Yup.number()
              .min(1, 'Should be >= 1')
              .max(3, 'Should be <= 3')
              .required('Required'),
          })
          .required('Required'),
      }),
      duration_type: Yup.string().when('type', {
        is: 'TIME',
        then: Yup.string().required('Required!'),
      }),
      time_min: Yup.date().when('duration_type', {
        is: TIME_DURATION_TYPE[0],
        then: Yup.date().required('Required'),
      }),
      time_max: Yup.date().when('duration_type', {
        is: TIME_DURATION_TYPE[0],
        then: Yup.date().required('Required'),
      }),
      duration_min: Yup.number().when('duration_type', {
        is: TIME_DURATION_TYPE[1],
        then: Yup.number().min(0, 'Should be >= 0').required('Required'),
      }),
      duration_max: Yup.number().when('duration_type', {
        is: TIME_DURATION_TYPE[1],
        then: Yup.number().min(0, 'Should be >= 0').required('Required'),
      }),
      str_value: Yup.string().when('type', {
        is: 'STRING',
        then: Yup.string().required('Required'),
      }),
      GameName: Yup.string().when('category', {
        is: (val: string) =>
          val === 'game_specific_attributes' ||
          val === 'game_and_period_specific_attributes',
        then: Yup.string().required('Required'),
      }),
      PeriodName: Yup.string().when('category', {
        is: (val: string) =>
          val === 'period_specific_attributes' ||
          val === 'game_and_period_specific_attributes',
        then: Yup.string().required('Required'),
      }),
      GameCategoryName: Yup.string().when('category', {
        is: 'game_category_specific_attributes',
        then: Yup.string().required('Required'),
      }),
      operator: Yup.string().when('id', {
        is: (val: string) => val !== MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE,
        then: Yup.string().required('Required'),
      }),
    })
  ),
})

const attributeParams = {
  id: '',
  type: '',
  boolean_value: false,
  number_min: 0,
  number_max: 0,
  time_min: undefined,
  time_max: undefined,
  duration_min: 0,
  duration_max: 0,
  str_value: '',
  category: '',
  GameName: '',
  PeriodName: '',
  GameCategoryName: '',
  operator: '',
  duration_type: '',
}

const CreateAudience: React.FC<Props> = props => {
  const { attributeType } = props
  const { user } = useSelector((state: AppState) => state.auth)
  const [error, setError] = useState('')
  const [attributesData, setAttributesData] = useState<any>({})

  const initialFormValues: FormikValues = {
    name: '',
    description: '',
    creation_intent: 0,
    attribute_correlation: 0,
    attributes: [attributeParams],
  }

  const fetchAttributeIds = async (): Promise<void> => {
    const { attributesData: ad, error: err } = await getAttributes()

    if (!err) {
      setAttributesData(ad)
    }
  }

  useEffect(() => {
    fetchAttributeIds()
  }, [])

  return (
    <div id={styles['create-audience']}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm }
        ): Promise<void> => {
          const { success, error: err } = await createAudience(
            values,
            user as User
          )
          setSubmitting(false)

          if (success) {
            message.success(`Audience created successfully!`)
            setError('')
            resetForm(initialFormValues)
          } else if (err) {
            setError(err)
            message.error(err)
          } else {
            const e = 'Error creating Audience!'
            setError(e)
            message.error(e)
          }
        }}
      >
        {(formikProps: FormikProps<FormikValues>): React.ReactElement => (
          <Form className={styles['create-audience-form']}>
            <div className={styles.subtitle}>Create Audience</div>
            <span>Name</span>
            <Field name="name" type="text" />
            <div className={styles['field-error']}>
              <ErrorMessage name="name" className="error" />
            </div>
            <div className={styles.separator} />

            <span>Description</span>
            <Field as="textarea" name="description" type="text" />
            <div className={styles['field-error']}>
              <ErrorMessage name="description" />
            </div>
            <div className={styles.separator} />

            <span>Creation Intent</span>
            <Field as="select" name="creation_intent">
              {CreationIntent.map((ci, i) => {
                return (
                  <option key={ci} value={i}>
                    {ci}
                  </option>
                )
              })}
            </Field>
            <div className={styles['field-error']}>
              <ErrorMessage name="creation_intent" />
            </div>
            <div className={styles.separator} />

            <span>Attribute Correlation</span>
            <Field as="select" name="attribute_correlation">
              {AttributeCorrelation.map((ac, i) => {
                return (
                  <option key={ac} value={i}>
                    {ac}
                  </option>
                )
              })}
            </Field>
            <div className={styles['field-error']}>
              <ErrorMessage name="attribute_correlation" />
            </div>
            <div className={styles.separator} />

            <FieldArray
              name="attributes"
              render={(arrayHelpers): React.ReactElement => (
                <div className={styles.attributes}>
                  {formikProps.values.attributes.map((a: any, i: number) => {
                    return (
                      <div key={a} className={styles.attribute}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                          }}
                        >
                          <span>Attribute Type</span>
                          {formikProps.values.attributes.length > 1 ? (
                            <button
                              type="button"
                              className={styles['remove-attribute']}
                              onClick={(): void => {
                                arrayHelpers.remove(i)
                              }}
                            >
                              -
                            </button>
                          ) : null}
                        </div>
                        <Field as="select" name={`attributes[${i}].category`}>
                          <option value="">
                            --- Select Attribute Type ---
                          </option>
                          {AUDIENCE_ATTRIBUTE_TYPES.map((at: string) => {
                            return (
                              <option key={at} value={at}>
                                {at}
                              </option>
                            )
                          })}
                        </Field>
                        <div className={styles['field-error']}>
                          <ErrorMessage name={`attributes[${i}].category`} />
                        </div>
                        <div className={styles.separator} />

                        <span>Attribute</span>
                        <select
                          onChange={e => {
                            formikProps.setFieldValue('attributes', [
                              ...formikProps.values.attributes.slice(0, i),
                              {
                                ...formikProps.values.attributes[i],
                                id: e.target.value,
                                type:
                                  attributesData[
                                    formikProps.values.attributes[i].category
                                  ]?.find(
                                    (aid: any) =>
                                      aid.attribute_id === e.target.value
                                  )?.value_type || 'NUMBER',
                              },
                              ...formikProps.values.attributes.slice(i + 1),
                            ])
                          }}
                        >
                          <option value="">--- Select Attribute ---</option>
                          {attributesData[a.category]?.map((attr: any) => {
                            return (
                              <option
                                key={attr.attribute_id}
                                value={attr.attribute_id}
                              >
                                {attr.attribute_id}
                              </option>
                            )
                          })}
                        </select>
                        <div className={styles['field-error']}>
                          <ErrorMessage name={`attributes[${i}].id`} />
                        </div>
                        <div className={styles.separator} />

                        {(a.category === 'game_specific_attributes' ||
                          a.category ===
                            'game_and_period_specific_attributes') && (
                          <>
                            <span>Game Name</span>
                            <Field
                              as="select"
                              name={`attributes[${i}].GameName`}
                            >
                              <option value="">--- Select Game Name ---</option>
                              {Object.keys(
                                attributesData?.attribute_game_names
                              )?.map((g: any) => {
                                return (
                                  <option key={g} value={g}>
                                    {g}
                                  </option>
                                )
                              })}
                            </Field>
                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].GameName`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}

                        {(a.category === 'period_specific_attributes' ||
                          a.category ===
                            'game_and_period_specific_attributes') && (
                          <>
                            <span>Period Name</span>
                            <Field
                              as="select"
                              name={`attributes[${i}].PeriodName`}
                            >
                              <option value="">
                                --- Select Period Name ---
                              </option>
                              {attributesData?.attribute_period_names?.map(
                                (p: any) => {
                                  return (
                                    <option key={p} value={p}>
                                      {p}
                                    </option>
                                  )
                                }
                              )}
                            </Field>
                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].PeriodName`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}

                        {a.category === 'game_category_specific_attributes' && (
                          <>
                            <span>Game Category Name</span>
                            <Field
                              as="select"
                              name={`attributes[${i}].GameCategoryName`}
                            >
                              <option value="">
                                --- Select Game Category Name ---
                              </option>
                              {attributesData?.attribute_game_category_names?.map(
                                (c: any) => {
                                  return (
                                    <option key={c} value={c}>
                                      {c}
                                    </option>
                                  )
                                }
                              )}
                            </Field>
                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].GameCategoryName`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}

                        <span>Attribute Value Type</span>
                        <Field
                          as="select"
                          name={`attributes[${i}].type`}
                          disabled
                        >
                          <option value="">
                            --- Select Attribute Value Type ---
                          </option>
                          {Object.keys(attributeType).map((at: string) => {
                            return (
                              <option key={at} value={at}>
                                {at}
                              </option>
                            )
                          })}
                        </Field>
                        <div className={styles['field-error']}>
                          <ErrorMessage name={`attributes[${i}].type`} />
                        </div>
                        <div className={styles.separator} />

                        {formikProps.values.attributes[i].type === 'TIME' ? (
                          <>
                            <span>Duration Type</span>
                            <Field
                              as="select"
                              name={`attributes[${i}].duration_type`}
                            >
                              <option value="">
                                --- Select Duration Type ---
                              </option>
                              {TIME_DURATION_TYPE.map((at: string) => {
                                return (
                                  <option key={at} value={at}>
                                    {at}
                                  </option>
                                )
                              })}
                            </Field>
                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].duration_type`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        ) : null}

                        {formikProps.values.attributes[i].type === 'NUMBER' && (
                          <>
                            {formikProps.values.attributes[i].id !==
                              MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE && (
                              <>
                                <span>Min Value</span>
                                <Field
                                  name={`attributes[${i}].number_min`}
                                  type="number"
                                />
                                <div className={styles['field-error']}>
                                  <ErrorMessage
                                    name={`attributes[${i}].number_min`}
                                  />
                                </div>
                                <div className={styles.separator} />
                              </>
                            )}
                            <span>Max Value</span>
                            <Field
                              name={`attributes[${i}].number_max`}
                              type="number"
                            />
                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].number_max`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}

                        {formikProps.values.attributes[i].type === 'STRING' && (
                          <>
                            <span>Attribute Operator</span>
                            <Field
                              as="select"
                              name={`attributes[${i}].operator`}
                            >
                              <option value="">--- Select Operator ---</option>
                              {['Equals', 'NotEquals'].map((at: string) => {
                                return (
                                  <option key={at} value={at}>
                                    {at}
                                  </option>
                                )
                              })}
                            </Field>
                            <div className={styles.separator} />
                            <span>String Value</span>
                            {formikProps.values.attributes[i].id ===
                              'PreferredGame' ||
                            formikProps.values.attributes[i].id ===
                              'PreferredGameByRake' ||
                            formikProps.values.attributes[i].id ===
                              'UGCPreferredGame' ? (
                              <Field
                                as="select"
                                name={`attributes[${i}].str_value`}
                              >
                                <option value="">
                                  --- Select Game Name ---
                                </option>
                                {Object.values(
                                  attributesData?.attribute_game_names
                                )?.map((at: any) => {
                                  return (
                                    <option key={at} value={at}>
                                      {at}
                                    </option>
                                  )
                                })}
                              </Field>
                            ) : (
                              <Field
                                name={`attributes[${i}].str_value`}
                                type="text"
                              />
                            )}

                            <div className={styles['field-error']}>
                              <ErrorMessage
                                name={`attributes[${i}].str_value`}
                              />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}

                        {(formikProps.values.attributes[i].type === 'TIME' ||
                          formikProps.values.attributes[i].type === 'NUMBER') &&
                          formikProps.values.attributes[i].id !==
                            MAX_SIMULTANEOUS_SESSIONS_ATTRIBUTE && (
                            <>
                              <span>Attribute Operator</span>
                              <Field
                                as="select"
                                name={`attributes[${i}].operator`}
                              >
                                <option value="">
                                  --- Select Operator ---
                                </option>
                                {['In', 'NotIn'].map((at: string) => {
                                  return (
                                    <option key={at} value={at}>
                                      {at}
                                    </option>
                                  )
                                })}
                              </Field>
                              <div className={styles.separator} />
                            </>
                          )}

                        {formikProps.values.attributes[i].type === 'TIME' &&
                          formikProps.values.attributes[i].duration_type ===
                            TIME_DURATION_TYPE[0] && (
                            <>
                              {['min', 'max'].map(k => {
                                return (
                                  <React.Fragment key={k}>
                                    <span>{`${k} Time`}</span>
                                    <Field name={`attributes[${i}][time_${k}]`}>
                                      {({
                                        field, // { name, value, onChange, onBlur }
                                        form: { setFieldValue },
                                      }: FormikValues): React.ReactElement => (
                                        <DatePicker
                                          showTime
                                          onChange={(date): void => {
                                            if (date)
                                              setFieldValue(
                                                field.name,
                                                new Date(date.format())
                                              )
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <div className={styles['field-error']}>
                                      <ErrorMessage
                                        name={`attributes[${i}][time_${k}]`}
                                      />
                                    </div>
                                    <div className={styles.separator} />
                                  </React.Fragment>
                                )
                              })}
                            </>
                          )}

                        {formikProps.values.attributes[i].type === 'TIME' &&
                          formikProps.values.attributes[i].duration_type ===
                            TIME_DURATION_TYPE[1] && (
                            <>
                              {['min', 'max'].map(k => {
                                return (
                                  <React.Fragment key={k}>
                                    <span>{`${k} Last days`}</span>
                                    <Field
                                      name={`attributes[${i}][duration_${k}]`}
                                      type="number"
                                    />
                                    <div className={styles['field-error']}>
                                      <ErrorMessage
                                        name={`attributes[${i}][duration_${k}]`}
                                      />
                                    </div>
                                    <div className={styles.separator} />
                                  </React.Fragment>
                                )
                              })}
                            </>
                          )}

                        {formikProps.values.attributes[i].type ===
                          'BOOLEAN' && (
                          <>
                            <span />
                            <Field name="boolean_value">
                              {({
                                field, // { name, value, onChange, onBlur }
                                form: { setFieldValue },
                              }: FormikValues): React.ReactElement => (
                                <Checkbox
                                  onChange={(e: CheckboxChangeEvent): void => {
                                    setFieldValue(field.name, e.target.checked)
                                  }}
                                >
                                  Player has attribute
                                </Checkbox>
                              )}
                            </Field>
                            <div className={styles['field-error']}>
                              <ErrorMessage name="boolean_value" />
                            </div>
                            <div className={styles.separator} />
                          </>
                        )}
                      </div>
                    )
                  })}
                  <button
                    type="button"
                    onClick={(): void => arrayHelpers.push(attributeParams)}
                    className={styles['add-attribute']}
                  >
                    + Add Attribute
                  </button>
                </div>
              )}
            />

            {error && <div className={styles.error}>{`Error: ${error}`}</div>}

            <button
              type="submit"
              className={styles['create-audience-btn']}
              disabled={formikProps.isSubmitting}
            >
              Create Audience
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateAudience
