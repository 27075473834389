import { DocumentData } from '@firebase/firestore-types'
import {
  GET_ALL_ORDERS,
  STORE_ORDERS,
  SET_LOADING_ALL_ORDERS,
  SET_LOADING_REWARDS,
  SYNC_REWARDS,
  CANCEL_REWARDS_SYNC,
  SYNC_ALL_ORDERS,
  CANCEL_ALL_ORDERS_SYNC,
  SYNC_INTERCOM_REFUNDS,
  CANCEL_INTERCOM_REFUNDS_SYNC,
  STORE_INTERCOM_REFUNDS,
  STORE_REWARDS,
  GET_CF_SESSIONS,
  STORE_CF_SESSIONS,
} from './ActionTypes'
import {
  Order,
  CFSession,
} from '../../../../reducers/watson/player/orders/orders.types'
import {
  GetAllOrders,
  StoreOrders,
  OrderCategories,
  SetLoadingAllOrders,
  SetLoadingRewards,
  SyncRewards,
  CancelRewardsSync,
  SyncAllOrders,
  CancelAllOrdersSync,
  SyncIntercomRefunds,
  CancelIntercomRefundsSync,
  StoreIntercomRefunds,
  StoreRewards,
  GetCfSessions,
  StoreCFSessions,
} from './orders.types'

export const getAllOrders = (
  playerId: string,
  category: OrderCategories
): GetAllOrders => ({
  type: GET_ALL_ORDERS,
  payload: { playerId, category },
})

export const storeOrders = (
  orders: Order[],
  category: OrderCategories,
  lastPage: boolean,
  lastDoc?: DocumentData
): StoreOrders => ({
  type: STORE_ORDERS,
  payload: orders instanceof Error ? orders : { orders },
  ...(orders instanceof Error && { error: true }),
  meta: {
    category,
    lastDoc,
    lastPage,
  },
})

export const setLoadingAllOrders = (): SetLoadingAllOrders => ({
  type: SET_LOADING_ALL_ORDERS,
  payload: {},
})

export const setLoadingRewards = (): SetLoadingRewards => ({
  type: SET_LOADING_REWARDS,
  payload: {},
})

export const syncRewards = (playerId: string): SyncRewards => ({
  type: SYNC_REWARDS,
  payload: { playerId },
})

export const cancelRewardsSync = (): CancelRewardsSync => ({
  type: CANCEL_REWARDS_SYNC,
  payload: {},
})

export const storeRewards = (orders: Order[] | Error): StoreRewards => ({
  type: STORE_REWARDS,
  payload: orders instanceof Error ? orders : { orders },
  ...(orders instanceof Error && { error: true }),
})

export const syncAllOrders = (
  playerId: string,
  category: OrderCategories
): SyncAllOrders => ({
  type: SYNC_ALL_ORDERS,
  payload: {
    playerId,
    category,
  },
})

export const cancelAllOrdersSync = (): CancelAllOrdersSync => ({
  type: CANCEL_ALL_ORDERS_SYNC,
  payload: {},
})

export const syncIntercomRefunds = (playerId: string): SyncIntercomRefunds => ({
  type: SYNC_INTERCOM_REFUNDS,
  payload: { playerId },
})

export const cancelIntercomRefundsSync = (): CancelIntercomRefundsSync => ({
  type: CANCEL_INTERCOM_REFUNDS_SYNC,
  payload: {},
})

export const storeIntercomRefunds = (
  orders: Order[] | Error
): StoreIntercomRefunds => ({
  type: STORE_INTERCOM_REFUNDS,
  payload: orders instanceof Error ? orders : { orders },
  ...(orders instanceof Error && { error: true }),
})

export const getCfSessions = (playerId: string): GetCfSessions => ({
  type: GET_CF_SESSIONS,
  payload: { playerId },
})

export const storeCfSessions = (
  cfSessions: CFSession[] | Error
): StoreCFSessions => ({
  type: STORE_CF_SESSIONS,
  payload: cfSessions instanceof Error ? cfSessions : { cfSessions },
  ...(cfSessions instanceof Error && { error: true }),
})
