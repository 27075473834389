import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import styles from '../AllTransactions/AllTransactions.module.css'
import { AppState } from '../../../../../reducers/reducers'
import {
  syncRewards,
  cancelRewardsSync,
} from '../../../../../actions/watson/player/orders/orders'
import Row from '../AllTransactions/Row'

const Rewards: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const { meta, rewards } = useSelector(
    (state: AppState) => state.watson.player.orders
  )
  const sortedOrders = Object.values(rewards)
    .filter(o => o.Category === 'Reward')
    .sort((a, b) => {
      if (a.CreatedAt > b.CreatedAt) return -1
      if (a.CreatedAt < b.CreatedAt) return 1
      return 0
    })

  useEffect(() => {
    if (playerId) dispatch(syncRewards(playerId))

    return (): void => {
      dispatch(cancelRewardsSync())
    }
  }, [playerId, dispatch])

  return (
    <Spin tip="loading rewards" spinning={meta.rewards.loading}>
      <div id={styles['all-transactions']}>
        <div className={styles.title}>Rewards Transactions</div>

        <div className={`${styles.head} ${styles.first}`} />
        <div className={styles.head}>NAME</div>
        <div className={styles.head}>FORMAT</div>
        <div className={styles.head}>AMOUNT</div>
        <div className={styles.head}>REFUNDED</div>
        <div className={styles.head}>ORDER ID</div>
        <div className={styles.head}>DATE & TIME</div>
        <div className={`${styles.head} ${styles.last}`}>STATUS</div>

        {sortedOrders.map(order => {
          return <Row order={order} orders={sortedOrders} key={order.id} />
        })}
      </div>

      <div className={styles.lastPage}>No more orders!</div>
    </Spin>
  )
}

export default Rewards
