import React from 'react'
import styles from './PlatformDetails.module.css'

const PlatformDetails: React.FC = () => {
  return (
    <div id={styles['platform-details']}>
      <div className={styles.title}>Platform Details</div>
      <div className={styles['coming-soon']}>COMING SOON</div>
    </div>
  )
}

export default PlatformDetails
