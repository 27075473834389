import React from 'react'
import styles from './InstantContestJoin.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import GemIcon from '../../../../../assets/crystal.svg'
import useSyncFirestoreDocument from '../../../../../hooks/useSyncFirestoreDocument'
import { Tournament } from '../../../../../types/Tournament.types'
import TournamentType from './TournamentType'

interface Props {
  order: Order
}

const Winning: React.FC<Props> = props => {
  const { order } = props
  const Fulfillments = order.Fulfillments.map(f => f.Payment)
  const tournament = useSyncFirestoreDocument<Tournament | null>(
    order.Product.GameWinningsProps?.TournamentPath || ''
  )

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>More Details</div>

        <div className={styles.key}>ROOM ID</div>
        <div className={styles.value}>-</div>

        <div className={styles.key}>TOURNAMENT ID</div>
        <div className={styles.value}>
          {order.Product.GameWinningsProps?.TournamentPath}
        </div>

        {tournament ? <TournamentType t={tournament} /> : null}
      </div>

      <div className={styles.card}>
        <div className={styles.title}>Winning Distribution</div>

        <div className={styles.wallets}>
          <div className={styles.th}>WALLET DESTINATION</div>
          <div className={styles.th}>WINNING</div>

          {Fulfillments.map(wallet => {
            return (
              <React.Fragment key={wallet?.Destination}>
                <div className={styles.td}>{wallet?.Destination}</div>
                <div className={styles.td}>
                  {wallet?.Currency === 'INR' ? (
                    '₹'
                  ) : (
                    <img src={GemIcon} alt="gem icon" className="gem" />
                  )}
                  {` ${wallet?.Amount}`}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Winning
