import React, { MouseEvent, useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { message, Button, Spin, Tag } from 'antd'
import styles from './OrdersTable.module.css'
import { CfSessionState } from '../../../reducers/watson/cfSession/cfSession.types'
import { getProfile } from '../../../actions/watson/player/profile/profile'
import usePagination from './usePagination'
import FirestoreFilter, {
  Filter,
  SelectedFilters,
} from '../../../components/FirestoreFilter/FirestoreFilter'
import { filterSessions } from '../../../actions/watson/cfSession/cfSession'
import { AppState } from '../../../reducers/reducers'

interface Props {
  selectedSessionState: CfSessionState
  pauseSync: () => void
}

const stateMap: { [key in CfSessionState]: string } = {
  Active: 'Active',
  CashedOut: 'CashedOut',
  Ended: 'Ended',
}

const OrdersTable: React.FC<Props> = props => {
  const { selectedSessionState, pauseSync } = props
  const { meta, sessions, filteredSessions } = useSelector(
    (state: AppState) => state.watson.cf
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const messageKey = 'profileLoading'
  const [isLive, setIsLive] = useState(true)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({})

  const dispalyOrders =
    Object.keys(selectedFilters).length > 0
      ? filteredSessions
      : Object.values(sessions)

  usePagination(selectedSessionState, Object.keys(selectedFilters).length > 0)

  const filters: Filter[] = [
    {
      field: 'PlayerId',
      title: 'Player ID',
      filterType: 'input',
    },
    {
      field: 'CreatedAt',
      title: 'Date',
      filterType: 'dateRange',
    },
  ]

  const applyFilters = (sf: SelectedFilters): void => {
    setSelectedFilters(sf)
  }

  const toggleFilterVisibility = (): void => {
    setFiltersVisible(!filtersVisible)
  }

  const pauseLiveData = (): void => {
    if (isLive) {
      pauseSync()
    }

    setIsLive(false)
  }

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      dispatch(filterSessions(selectedFilters))
    }
  }, [selectedFilters, dispatch])

  return (
    <Spin
      tip={`Loading ${stateMap[selectedSessionState]} cf sessions...`}
      spinning={meta[selectedSessionState].loading}
    >
      <div id={styles['orders-table']}>
        <div className={styles.header}>
          <div className={styles.title}>
            {`${stateMap[selectedSessionState]} Cf Sessions`}
          </div>
          <Button type="link" size="large" onClick={toggleFilterVisibility}>
            {!filtersVisible ? <>View Filter</> : <>Hide Filter</>}
          </Button>
          <Button type="link" size="large" onClick={pauseLiveData}>
            {!isLive ? (
              <>Refresh browser to stream Live data</>
            ) : (
              <>Pause Live Data</>
            )}
          </Button>
        </div>

        {filtersVisible && (
          <div className={styles.filters}>
            <FirestoreFilter
              filters={filters}
              applyFilter={applyFilters}
              selectedFilter={selectedFilters}
            />
          </div>
        )}

        {Object.keys(selectedFilters).length > 0 && (
          <div className={styles['applied-filters']}>
            {Object.keys(selectedFilters).map(key => {
              return (
                <Tag
                  key={key}
                  color="#2db7f5"
                  closable
                  onClose={(): void => {
                    const sf = { ...selectedFilters }
                    Reflect.deleteProperty(sf, key)
                    setSelectedFilters(sf)
                  }}
                >
                  <span className={styles['filter-key']}>
                    {filters.find(f => f.field === key)?.title || key}
                  </span>
                  <span>: </span>
                  <span className={styles['filter-value']}>
                    {`${
                      typeof selectedFilters[key] === 'string'
                        ? selectedFilters[key]
                        : `${moment(selectedFilters[key][0]).format(
                            'll'
                          )} to ${moment(selectedFilters[key][1]).format('ll')}`
                    }`}
                  </span>
                </Tag>
              )
            })}
          </div>
        )}

        <div className={`${styles.head} ${styles.first}`}>PLAYER ID</div>
        <div className={styles.head}>Session ID</div>
        <div className={styles.head}>Cash In Order</div>
        <div className={styles.head}>Start Time</div>
        <div className={styles.head}>Rounds Played</div>
        <div className={styles.head}>Delay</div>

        {dispalyOrders
          .filter(o => o.State === selectedSessionState)
          .sort((o1, o2) => {
            if (o1.CreatedAt.seconds > o2.CreatedAt.seconds) return -1
            if (o1.CreatedAt.seconds < o2.CreatedAt.seconds) return 1
            return 0
          })
          .map(o => {
            return (
              <React.Fragment key={o.id}>
                <div className={styles.value}>
                  <Button
                    className={styles['player-btn']}
                    type="link"
                    onClick={(e: MouseEvent<HTMLElement>): void => {
                      const pid = e.currentTarget.innerText
                      dispatch(getProfile(pid, history, messageKey))

                      message.loading({
                        content: `Loading profile for "${pid}"`,
                        key: messageKey,
                      })
                    }}
                  >
                    {o.PlayerId}
                  </Button>
                </div>
                <div className={styles.value}>
                  {`houses/mega/tournaments/${o.TournamentId}/sessions/${o.id}`}
                </div>
                <div className={styles.value}>{o.CashInOrderRefs[0]}</div>
                <div className={`${styles.timestamp} ${styles.value}`}>
                  <div>{moment(o.CreatedAt.seconds * 1000).format('ll')}</div>
                  <div>
                    {moment(o.CreatedAt.seconds * 1000).format('hh:mm A')}
                  </div>
                </div>
                {/*
                  <div className={`${styles.timestamp} ${styles.value}`}>
                    <div>{moment(o.EndTime.seconds * 1000).format('ll')}</div>
                    <div>
                      {moment(o.EndTime.seconds * 1000).format('hh:mm A')}
                    </div>
                  </div>
                  */}
                <div className={styles.value}>
                  {o.RoomRefs == null ? 0 : o.RoomRefs.length}
                </div>

                <div className={`${styles.timestamp} ${styles.value}`}>
                  {moment(o.CreatedAt.seconds * 1000).fromNow()}
                </div>
              </React.Fragment>
            )
          })}

        {(meta[selectedSessionState].lastPage ||
          Object.keys(selectedFilters).length > 0) && (
          <div className={styles.lastPage}>No more sessions!</div>
        )}
      </div>
    </Spin>
  )
}

export default OrdersTable
