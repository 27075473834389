import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './CFSessions.module.css'
import { AppState } from '../../../../../reducers/reducers'
import { getCfSessions } from '../../../../../actions/watson/player/orders/orders'
import Row from './Row'

const CFSessions: React.FC = () => {
  const dispatch = useDispatch()
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const cfSessions = useSelector(
    (state: AppState) => state.watson.player.orders.cfSessions
  )

  useEffect(() => {
    if (playerId) dispatch(getCfSessions(playerId))
  }, [playerId, dispatch])

  return (
    <div id={styles['cf-sessions']}>
      <div className={styles.title}>Cash Format Sessions</div>

      <div className={styles.th} />
      <div className={styles.th}>Session ID</div>
      <div className={styles.th} />
      <div className={styles.th}>Buy In Order ID</div>
      <div className={styles.th}>Buy In Amount</div>
      <div className={styles.th}>Is Cashed Out</div>

      {Object.values(cfSessions).map(s => {
        return (
          <Row cfSession={s} key={s.session_id} playerId={playerId || ''} />
        )
      })}
    </div>
  )
}

export default CFSessions
