import {
  SetLoadingTimeline,
  SyncTimeline,
  CancelTimelineSync,
  StoreTimeline,
} from './timeline.types'
import {
  SET_LOADING_TIMELINE,
  SYNC_TIMELINE,
  CANCEL_TIMELINE_SYNC,
  STORE_TIMELINE,
} from './ActionTypes'
import { Event } from '../../../../reducers/watson/player/timeline/timeline.types'

export const setLoadingTimeline = (): SetLoadingTimeline => ({
  type: SET_LOADING_TIMELINE,
  payload: {},
})

export const syncTimeline = (playerId: string): SyncTimeline => ({
  type: SYNC_TIMELINE,
  payload: { playerId },
})

export const cancelTimelineSync = (): CancelTimelineSync => ({
  type: CANCEL_TIMELINE_SYNC,
  payload: {},
})

export const storeTimeline = (timeline: Event[] | Error): StoreTimeline => ({
  type: STORE_TIMELINE,
  payload: timeline instanceof Error ? timeline : { timeline },
  ...(timeline instanceof Error && { error: true }),
})
