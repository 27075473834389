import {
  SetLoadingOrders,
  SyncOrders,
  CancelOrdersSync,
  StoreOrders,
  StoreKycDetails,
  GetKycDetails,
  KycDocumentType,
} from './kyc.types'
import {
  SET_LOADING_ORDERS,
  SYNC_ORDERS,
  CANCEL_ORDERS_SYNC,
  STORE_ORDERS,
  STORE_KYC_DETAILS,
  GET_KYC_DETAILS,
} from './ActionTypes'
import {
  KycOrder,
  KycDocumentData,
} from '../../../../reducers/watson/kyc/kyc.types'

export const setLoadingOrders = (): SetLoadingOrders => ({
  type: SET_LOADING_ORDERS,
  payload: {},
})

export const syncOrders = (playerId: string): SyncOrders => ({
  type: SYNC_ORDERS,
  payload: { playerId },
})

export const cancelOrdersSync = (): CancelOrdersSync => ({
  type: CANCEL_ORDERS_SYNC,
  payload: {},
})

export const storeOrders = (orders: KycOrder[] | Error): StoreOrders => ({
  type: STORE_ORDERS,
  payload: orders instanceof Error ? orders : { orders },
  ...(orders instanceof Error && { error: true }),
})

export const storeKycDetails = (
  kycDetails: KycDocumentData | Error,
  documentType: KycDocumentType
): StoreKycDetails => ({
  type: STORE_KYC_DETAILS,
  payload: kycDetails instanceof Error ? kycDetails : { kycDetails },
  ...(kycDetails instanceof Error && { error: true }),
  meta: {
    documentType,
  },
})

export const getKycDetails = (
  orderId: string,
  documentType: KycDocumentType
): GetKycDetails => ({
  type: GET_KYC_DETAILS,
  payload: { orderId, documentType },
})
