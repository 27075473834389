import { GameProfilesReducer } from './gameProfiles.types'
import {
  GameProfilesAction,
  StoreGameProfiles,
  StoreGameProfilesPayload,
} from '../../../../actions/watson/player/gameProfiles/gameProfiles.types'
import {
  LOADING_GAME_PROFILES,
  STORE_GAME_PROFILE,
} from '../../../../actions/watson/player/gameProfiles/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: GameProfilesReducer = {
  gameProfiles: [],
  error: null,
  meta: { loading: false },
}

const gameProfilesReducer = (
  state = initialState,
  action: GameProfilesAction
): GameProfilesReducer => {
  switch (action.type) {
    case LOADING_GAME_PROFILES:
      return {
        gameProfiles: [],
        error: null,
        meta: { loading: true },
      }

    case STORE_GAME_PROFILE: {
      const { payload, error } = action as StoreGameProfiles

      if (!error) {
        const { gameProfiles } = payload as StoreGameProfilesPayload

        return {
          gameProfiles,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        gameProfiles: [],
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}

export default gameProfilesReducer
