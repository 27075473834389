const prefix = '@@watson/@@deposit'

export const SET_LOADING_DEPOSIT_ORDERS = `${prefix}/SET_LOADING_DEPOSIT_ORDERS`

export const SYNC_ORDERS = `${prefix}/SYNC_ORDERS`
export const CANCEL_ORDERS_SYNC = `${prefix}/CANCEL_ORDERS_SYNC`

export const STORE_ORDERS = `${prefix}/STORE_ORDERS`
// export const RESET_ORDERS = `${prefix}/RESET_ORDERS`
export const GET_ORDERS = `${prefix}/GET_ORDERS`

export const FILTER_ORDERS = `${prefix}/FILTER_ORDERS`
export const STORE_FILTERED_ORDERS = `${prefix}/STORE_FILTERED_ORDERS`
