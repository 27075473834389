import React from 'react'
import moment from 'moment'
import styles from './BlockingDetails.module.css'
import {
  FraudProfile,
  FraudActionProps,
} from '../../../../../../reducers/watson/player/profile/profile.types'
import Timestamp from '../../../../../../components/Timestamp/Timestamp'

interface Props {
  fraudProfile: FraudProfile
  isLoginBlocked: boolean
  isPlatformBlocked: boolean
  isLocationBlocked: boolean
}

const BlockingDetails: React.FC<Props> = ({
  fraudProfile,
  isLoginBlocked,
  // isPlatformBlocked,
  isLocationBlocked,
}) => {
  const getReason = (r: string): string => r.replace('Block Reason - ', '')
  const getBlockingSource = (p: FraudActionProps): string =>
    p.CaseRefs?.length > 0 ? 'Collusion Dashboard' : 'Watson'

  interface ActionsMap {
    title: string
    isBlocked:
      | 'GameplayBlocked'
      | 'DepositBlocked'
      | 'WithdrawalBlocked'
      | 'MoonshotBlocked'
      | 'RewardsBlocked'
    actionProps:
      | 'ContestsProps'
      | 'DespositProps'
      | 'WithDrawalProps'
      | 'LeaderBoardProps'
      | 'RewardProps'
  }
  const actionsMap: ActionsMap[] = [
    {
      title: 'Contest Join', // action to be displayed
      actionProps: 'ContestsProps', // key to get props for this action
      isBlocked: 'GameplayBlocked', // which prop in fraudProfile to check to see if this action is blocked
    },
    {
      title: 'Deposit',
      actionProps: 'DespositProps',
      isBlocked: 'DepositBlocked',
    },
    {
      title: 'Withdrawal',
      actionProps: 'WithDrawalProps',
      isBlocked: 'WithdrawalBlocked',
    },
    {
      title: 'Leaderboard join',
      actionProps: 'LeaderBoardProps',
      isBlocked: 'MoonshotBlocked',
    },
    {
      title: 'Rewards',
      actionProps: 'RewardProps',
      isBlocked: 'RewardsBlocked',
    },
  ]

  return (
    <div id={styles['blocking-details']}>
      <div className={styles.title}>Blocking Details</div>
      <table>
        <thead>
          <tr>
            <th>Blocked Action</th>
            <th>Case Id</th>
            <th>Blocked On</th>
            <th>Blocked By</th>
            <th>Reason</th>
            <th>Description</th>
            <th>Remark</th>
            <th>Blocking Source</th>
          </tr>
        </thead>
        <tbody>
          {actionsMap.map(a => {
            return (
              <React.Fragment key={a.isBlocked}>
                {fraudProfile?.[a.isBlocked] && (
                  <tr>
                    <td>{a.title}</td>
                    <td>
                      {fraudProfile[a.actionProps]?.CaseRefs?.map(caseRef => {
                        const c = caseRef.split('/')
                        return <div key={caseRef}>{c[c.length - 1]}</div>
                      }) || '-'}
                    </td>
                    <td>
                      <Timestamp
                        timestamp={moment(
                          fraudProfile[a.actionProps].ActionTime.seconds * 1000
                        )}
                      />
                    </td>
                    <td>{fraudProfile[a.actionProps].ActionBy}</td>
                    <td>{fraudProfile[a.actionProps].Comment?.Title}</td>
                    <td>{fraudProfile[a.actionProps].Comment?.Description}</td>
                    <td>
                      {fraudProfile[a.actionProps].Comment?.Remark ||
                        getReason(fraudProfile[a.actionProps].Reason)}
                    </td>
                    <td>{getBlockingSource(fraudProfile[a.actionProps])}</td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}

          {isLoginBlocked && (
            <tr>
              <td>Login</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>Watson</td>
            </tr>
          )}

          {isLocationBlocked && (
            <tr>
              <td>Location Block</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>User is from banned state</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default BlockingDetails
