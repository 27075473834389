import { WalletsReducer } from './wallets.types'
import {
  WalletsAction,
  StoreWallets,
  StoreWalletsPayload,
} from '../../../../actions/watson/player/wallets/wallets.types'
import {
  STORE_WALLETS,
  SET_LOADING_WALLETS,
} from '../../../../actions/watson/player/wallets/ActionTypes'
import { RESET_PLAYER } from '../../../../actions/watson/player/profile/ActionTypes'

const initialState: WalletsReducer = {
  wallets: {},
  error: null,
  meta: { loading: false },
}

export default (
  state = initialState,
  action: WalletsAction
): WalletsReducer => {
  switch (action.type) {
    case SET_LOADING_WALLETS: {
      return {
        wallets: {},
        error: null,
        meta: { loading: true },
      }
    }

    case STORE_WALLETS: {
      const { payload, error } = action as StoreWallets

      if (!error) {
        const { wallets } = payload as StoreWalletsPayload

        return {
          wallets,
          error: null,
          meta: { loading: false },
        }
      }

      return {
        wallets: {},
        error: payload as Error,
        meta: { loading: false },
      }
    }

    case RESET_PLAYER:
      return initialState

    default:
      return state
  }
}
