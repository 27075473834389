import axios from "axios";
import getAccessToken from "../../../../../../../helpers/access-token";

const baseUrl = process.env.REACT_APP_FETCH_URL

const apiHandler = async (
  apiEndpoint: string,
  playerId: string
): Promise<boolean> => {
  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: { player_id: playerId },
  })
    .then(() => true)
    .catch(() => {
      return false
    })
}

export const blockPlayer = (playerId: string): Promise<boolean> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.ManageUser/Disable`

  return apiHandler(apiEndpoint, playerId)
}

export const unblockPlayer = (playerId: string): Promise<boolean> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.ManageUser/Enable`

  return apiHandler(apiEndpoint, playerId)
}

export const playerStatus = async (playerId: string): Promise<string> => {
  const apiEndpoint = `${baseUrl}/twirp/pb.ManageUser/Status`

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: { player_id: playerId },
  })
    .then(resp => {
      return resp.data.status
    })
    .catch(() => {
      return 'disabled'
    })
}

interface Reason {
  title?: string
  description?: string
  remark?: string
}

export const blockPlatformActions = async (
  playerId: string,
  authToken: string,
  actions: { [index: string]: boolean },
  reason: Reason,
  enforcer: string,
  source: string
): Promise<boolean> => {
  const apiEndpoint = `${baseUrl}/twirp/fraud.FraudEnforcer/BlockPlatformActionsForUser`

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: {
      player_id: playerId,
      block_actions: actions,
      reason,
      enforcer,
    },
  })
    .then(() => true)
    .catch(() => {
      return false
    })
}

export const unbBockPlatformActions = async (
  playerId: string,
  authToken: string,
  actions: { [index: string]: boolean },
  enforcer: string,
  source: string
): Promise<boolean> => {
  const apiEndpoint = `${baseUrl}/twirp/fraud.FraudEnforcer/UnBlockPlatformActionsForUsers`

  return axios(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${await getAccessToken()}`,
    },
    data: {
      player_ids: [playerId],
      unblock_actions: actions,
      enforcer,
    },
  })
    .then(() => true)
    .catch(() => {
      return false
    })
}
