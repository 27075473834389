import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { DocumentData } from '@firebase/firestore-types'
import { useParams } from 'react-router-dom'
import styles from './MoonshotLeaderboard.module.css'
import { Order } from '../../../../../reducers/watson/player/orders/orders.types'
import MSOrderRow from './MSOrderRow'

const collectionRef = firebase.firestore().collection('central_orders')

const MoonshotLeaderboard: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([])
  const { playerId } = useParams() as any

  useEffect(() => {
    const unsubscribe = collectionRef
      .where('PlayerId', '==', playerId)
      .where('Product.Type', 'in', ['LB_ENTRY', 'LB_PRIZE'])
      .orderBy('CreatedAt', 'desc')
      .limit(100)
      .onSnapshot(snapshot => {
        const o: Order[] = []

        snapshot.forEach((doc: DocumentData) => {
          o.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        setOrders(o)
      })

    return (): void => {
      unsubscribe()
    }
  }, [playerId])

  return (
    <div id={styles.MoonshotLeaderboard}>
      <div className={styles.title}>Moonshot Leaderboard Transactions</div>

      <div className={`${styles.head} ${styles.first}`} />
      <div className={styles.head}>NAME</div>
      <div className={styles.head}>AMOUNT</div>
      <div className={styles.head}>TDS</div>
      <div className={styles.head}>LB NAME</div>
      <div className={styles.head}>ORDER ID</div>
      <div className={styles.head}>TIME</div>
      <div className={`${styles.head} ${styles.last}`}>STATUS</div>

      {orders.map(o => {
        return <MSOrderRow order={o} key={o.id} />
      })}
    </div>
  )
}

export default MoonshotLeaderboard
