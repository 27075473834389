import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  syncOrders,
  cancelOrdersSync,
} from '../../../actions/watson/payout/payout'

const useSyncPayoutOrders = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(syncOrders())

    return (): void => {
      dispatch(cancelOrdersSync())
    }
  }, [dispatch])
}

export default useSyncPayoutOrders
