import { DocumentData } from '@firebase/firestore-types'
import {
  SyncOrders,
  CancelOrdersSync,
  StoreOrders,
  GetOrders,
  SetLoadingDepositOrders,
  FilterOrders,
  StoreFilteredOrders,
} from './deposit.types'
import {
  SYNC_ORDERS,
  CANCEL_ORDERS_SYNC,
  STORE_ORDERS,
  GET_ORDERS,
  SET_LOADING_DEPOSIT_ORDERS,
  FILTER_ORDERS,
  STORE_FILTERED_ORDERS,
} from './ActionTypes'
import {
  OrderState,
  DepositOrder,
} from '../../../reducers/watson/deposit/deposit.types'
import { SelectedFilters } from '../../../components/FirestoreFilter/FirestoreFilter'

export const setLoadingDepositOrders = (
  orderState: OrderState
): SetLoadingDepositOrders => ({
  type: SET_LOADING_DEPOSIT_ORDERS,
  payload: {
    orderState,
  },
})

export const syncOrders = (): SyncOrders => ({
  type: SYNC_ORDERS,
  payload: {},
})

export const cancelOrdersSync = (): CancelOrdersSync => ({
  type: CANCEL_ORDERS_SYNC,
  payload: {},
})

export const storeOrders = ({
  orders,
  orderState,
  lastDoc,
  lastPage,
}: {
  orders: DepositOrder[]
  orderState: OrderState
  lastDoc: DocumentData | null
  lastPage: boolean
}): StoreOrders => ({
  type: STORE_ORDERS,
  payload: {
    orders,
  },
  meta: {
    orderState,
    lastDoc,
    lastPage,
  },
})

export const getOrders = (orderState: OrderState): GetOrders => ({
  type: GET_ORDERS,
  payload: { orderState },
})

export const filterOrders = (
  selectedFilters: SelectedFilters
): FilterOrders => ({
  type: FILTER_ORDERS,
  payload: { selectedFilters },
})

export const storeFilteredOrders = (
  filteredOrders: DepositOrder[] | Error
): StoreFilteredOrders => ({
  type: STORE_FILTERED_ORDERS,
  payload:
    filteredOrders instanceof Error ? filteredOrders : { filteredOrders },
  ...(filteredOrders instanceof Error && { error: true }),
})
