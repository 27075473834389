import { takeLatest, put, fork, take, cancel } from 'redux-saga/effects'
import firebase from 'firebase/app'
import { DocumentData, QuerySnapshot } from '@firebase/firestore-types'
import { Task } from 'redux-saga'
import rsf from '../../../../firebase'
import {
  SYNC_TIMELINE,
  CANCEL_TIMELINE_SYNC,
} from '../../../../actions/watson/player/timeline/ActionTypes'
import {
  storeTimeline,
  setLoadingTimeline,
} from '../../../../actions/watson/player/timeline/timeline'
import { SyncTimeline } from '../../../../actions/watson/player/timeline/timeline.types'
import { Event } from '../../../../reducers/watson/player/timeline/timeline.types'

function* syncTimeline(action: SyncTimeline): Generator {
  yield put(setLoadingTimeline())

  const { playerId } = action.payload

  const query = firebase
    .firestore()
    .collection(`players/${playerId}/private/timeline/activities`)
    .limit(10)
    .orderBy('CreatedAt', 'desc')

  const task = yield fork(
    // eslint-disable-next-line
    // @ts-ignore
    rsf.firestore.syncCollection,
    query,
    {
      successActionCreator: (snapshot: QuerySnapshot) => {
        const timeline: Event[] = []

        snapshot.forEach((doc: DocumentData) => {
          timeline.push(doc.data())
        })

        return storeTimeline(timeline)
      },
    }
  )

  yield take(CANCEL_TIMELINE_SYNC)
  yield cancel(task as Task)
}

export default function* watchTimeline(): Generator {
  yield takeLatest(SYNC_TIMELINE, syncTimeline)
}
