import { History } from 'history'
import {
  SetLoadingProfile,
  GetProfile,
  StoreProfile,
  ResetPlayer,
} from './profile.types'
import {
  SET_LOADING_PROFILE,
  GET_PROFILE,
  STORE_PROFILE,
  RESET_PLAYER,
} from './ActionTypes'
import { Profile } from '../../../../reducers/watson/player/profile/profile.types'

export const setLoadingProfile = (): SetLoadingProfile => ({
  type: SET_LOADING_PROFILE,
  payload: {},
})

export const getProfile = (
  playerId: string,
  history: History,
  messageKey?: string
): GetProfile => ({
  type: GET_PROFILE,
  payload: { playerId, history, messageKey },
})

export const storeProfile = (profile: Profile | Error): StoreProfile => ({
  type: STORE_PROFILE,
  payload: profile instanceof Error ? profile : { profile },
  ...(profile instanceof Error && { error: true }),
})

export const resetPlayer = (): ResetPlayer => ({
  type: RESET_PLAYER,
  payload: {},
})
