import React from 'react'
import { StaggeredReward } from '../../../types/StaggeredReward.types'
import styles from './StaggeredDetails.module.css'
import StaggeredPreview from './StaggeredPreview'

interface Props {
  staggeredReward: StaggeredReward | undefined
}

const StaggeredDetails: React.FC<Props> = ({ staggeredReward }) => {
  if (!staggeredReward) return null

  return (
    <div className={styles.StaggeredDetails}>
      <div className={styles.card}>
        <div className={styles.title}>Details</div>
        <StaggeredPreview
          staggeredReward={staggeredReward}
          showTitle={false}
          showTotal={false}
        />
      </div>
    </div>
  )
}

export default StaggeredDetails
