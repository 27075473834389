import React, { useState, useEffect } from 'react'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'
import styles from './PlayerProfiles.module.css'
import Sidebar from './Sidebar/Sidebar'
import Content from './Content/Content'
import menuItems from './menuItems'
import { AppState } from '../../../reducers/reducers'
import {
  syncOrders as syncKycOrders,
  cancelOrdersSync as cancelKycOrdersSync,
} from '../../../actions/watson/player/kyc/kyc'
import { getProfile } from '../../../actions/watson/player/profile/profile'

const selectProfile = createSelector(
  (state: AppState) => state.watson.player.profile,
  profile => profile
)

const PlayerProfiles: React.FC = () => {
  const playerProfile = useSelector(selectProfile, shallowEqual)
  const history = useHistory()
  const { playerId } = useParams() as any
  const dispatch = useDispatch()
  const { search } = history.location
  let smi = Object.keys(menuItems)[0]
  if (search.includes('/kyc')) smi = 'kyc'

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    // Object.keys(menuItems)[0]
    smi
  )

  useEffect(() => {
    if (playerProfile.profile) {
      dispatch(syncKycOrders(playerProfile.profile.PlayerId))
    }

    return (): void => {
      cancelKycOrdersSync()
    }
  }, [dispatch, playerProfile])

  useEffect((): void => {
    if (playerProfile.profile === null) {
      // if error, redirect to home page
      if (playerProfile.error !== null || !playerId) history.replace('/watson')

      // else get playerId from url
      // and fetch profile
      if (playerId && !playerProfile.meta.loading) {
        const messageKey = 'getProfile'
        message.loading({
          content: `Loading profile for ${playerId}`,
          key: messageKey,
          time: 0,
        })
        dispatch(getProfile(playerId, history, messageKey))
      }
    }
  }, [playerProfile, history, dispatch, playerId])

  if (playerProfile.profile === null) return null

  return (
    <div id={styles.watson}>
      <Sidebar
        menuItems={menuItems}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />
      <Content selectedMenuItem={selectedMenuItem} />
    </div>
  )
}

export default PlayerProfiles
