import { Kyc, KycOrdersMap, KycOrder } from './kyc.types'
import {
  STORE_ORDERS,
  SET_LOADING_KYC_ORDERS,
  STORE_FILTERED_ORDERS,
} from '../../../actions/watson/kyc/ActionTypes'
import {
  KycActions,
  StoreOrders,
  StoreOrdersPayload,
  SetLoadingKycOrders,
  StoreFilteredOrders,
  StoreFilteredOrdersPayload,
} from '../../../actions/watson/kyc/kyc.types'

const initialState: Kyc = {
  orders: {},
  error: {},
  meta: {
    Manual_Supervision: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    Complete: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
    Failed: {
      lastDoc: null,
      loading: false,
      lastPage: false,
    },
  },
  filteredOrders: [],
}

export const getMapFromArray = (
  arr: KycOrder[],
  ordersMap: KycOrdersMap = {}
): KycOrdersMap => {
  return arr.reduce((om: KycOrdersMap, item) => {
    const map = om
    map[item.id] = item

    return map
  }, ordersMap)
}

const kycReducer = (state = initialState, action: KycActions): Kyc => {
  switch (action.type) {
    case SET_LOADING_KYC_ORDERS: {
      const { orderState } = (action as SetLoadingKycOrders).payload

      return {
        ...state,
        meta: {
          ...state.meta,
          [orderState]: {
            ...state.meta[orderState],
            loading: true,
          },
        },
      }
    }

    case STORE_ORDERS: {
      const { payload, error, meta } = action as StoreOrders
      const { orderState, lastPage, lastDoc } = meta

      if (!error) {
        const { orders } = payload as StoreOrdersPayload
        let ordersMap: KycOrdersMap = state.orders

        if (orderState === 'Manual_Supervision') {
          ordersMap = Object.values(state.orders).reduce(
            (prevValue: KycOrdersMap, order): KycOrdersMap => {
              const om = prevValue
              if (order.State !== 'Manual_Supervision') {
                om[order.id] = order
              }
              return om
            },
            {}
          )
        }

        return {
          ...state,
          orders: getMapFromArray(orders, { ...ordersMap }),
          meta: {
            ...state.meta,
            [orderState]: {
              ...state.meta[orderState],
              loading: false,
              lastPage,
              lastDoc,
            },
          },
        }
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          [orderState]: {
            ...state.meta[orderState],
            loading: false,
          },
        },
      }
    }

    case STORE_FILTERED_ORDERS: {
      const { payload, error } = action as StoreFilteredOrders

      if (!error) {
        const { filteredOrders } = payload as StoreFilteredOrdersPayload

        return {
          ...state,
          filteredOrders,
          meta: {
            ...state.meta,
            Manual_Supervision: {
              ...state.meta.Manual_Supervision,
              loading: false,
            },
            Complete: { ...state.meta.Complete, loading: false },
            Failed: { ...state.meta.Failed, loading: false },
          },
        }
      }

      return state
    }

    default:
      return state
  }
}

export default kycReducer
