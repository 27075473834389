import React from 'react'
import styles from './InstantContestJoin.module.css'
import { Tournament } from '../../../../../types/Tournament.types'
import getTournamentType from './tournament-type'

interface Props {
  t: Tournament
}

const TournamentType: React.FC<Props> = ({ t }) => {
  return (
    <>
      <div className={styles.key}>TOURNAMENT TYPE</div>
      <div className={styles.value}>{getTournamentType(t.SubType)}</div>
    </>
  )
}

export default TournamentType
