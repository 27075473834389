import React, { KeyboardEvent } from 'react'

import styles from './Sidebar.module.css'
import { OrderState } from '../../../reducers/watson/payout/payout.types'

interface Props {
  selectedOrderState: OrderState
  setSelectedOrderState(sos: OrderState): void
  pendingOrdersCount: number
}

const Sidebar: React.FC<Props> = props => {
  const { selectedOrderState, setSelectedOrderState, pendingOrdersCount } =
    props

  const getClassName = (orderState: OrderState): string =>
    `${styles['menu-item']} ${
      selectedOrderState === orderState ? styles.selected : ''
    }`

  return (
    <div id={styles.sidebar}>
      <div
        className={getClassName('Processing')}
        onClick={(): void => setSelectedOrderState('Processing')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedOrderState('Processing')
          }
        }}
      >
        {`Pending (${pendingOrdersCount})`}
      </div>
      <div
        className={getClassName('Complete')}
        onClick={(): void => setSelectedOrderState('Complete')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedOrderState('Complete')
          }
        }}
      >
        Accepted
      </div>
      <div
        className={getClassName('Failed')}
        onClick={(): void => setSelectedOrderState('Failed')}
        role="button"
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter') {
            setSelectedOrderState('Failed')
          }
        }}
      >
        Rejected
      </div>
    </div>
  )
}

export default Sidebar
