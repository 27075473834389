import React from 'react'
import styles from './OfferDetails.module.css'
import { Audience } from '../Offer'
import AttributeDetails from './AttributeDetails'

interface Props {
  audience: Audience
}

const AudienceDetails: React.FC<Props> = props => {
  const { audience } = props

  return (
    <>
      <div className={styles.key}>NAME</div>
      <div className={styles.value}>{audience.Name}</div>

      <div className={styles.key}>DESCRIPTION</div>
      <div className={styles.value}>{audience.Description}</div>

      <div className={styles.key}>CREATED BY</div>
      <div className={styles.value}>{audience.CreatedBy}</div>

      <div className={styles.key}>ATTRIBUTE CORRELATION</div>
      <div className={styles.value}>{audience.AttributeCorrelation}</div>

      <div className={styles['attributes-list']}>
        {audience.Attributes.map(a => {
          return <AttributeDetails key={a.AttributeId} attribute={a} />
        })}
      </div>
    </>
  )
}

export default AudienceDetails
