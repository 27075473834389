import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import ReduxSagaFirebase from 'redux-saga-firebase'

import { firebaseConfig } from './config'

const myFirebaseApp = firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV !== 'test') {
  firebase
    .firestore()
    .enablePersistence()
    .catch(err => {
      if (err.code === 'failed-precondition') {
        console.error(
          'Multiple tabs open, persistence can only be enabled, ' +
            'in one tab at a a time.'
        )
      } else if (err.code === 'unimplemented') {
        console.error(
          'The current browser does not support all of the ' +
            'features required to enable persistence'
        )
      }
    })
}

const rsf = new ReduxSagaFirebase(myFirebaseApp)

export default rsf
