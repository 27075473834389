import React, { useState } from "react";
import moment from "moment";
import { Button, Input, message, Modal, Radio, Select } from "antd";
import { useSelector } from "react-redux";
import styles from "./Collusion.module.css";
import styles2 from "./CollusionRow.module.css";
import { CollusionCase } from "../../../../../types/Collusion.types";
import Timestamp from "../../../../../components/Timestamp/Timestamp";
import { BlockPlatformActionsForUser, BlockReasonDescriptionMapping, UnblockPlatformActionsForUser } from "./api";
import { AppState } from "../../../../../reducers/reducers";

interface Props {
  collusionCase: CollusionCase
  refresh(): void
}

type Status = 'Block' | 'Unblock'

const actions = [
  'account',
  'withdrawal',
  'deposit',
  'gameplay',
  'rewards',
  'moonshot',
]

const CollusionRow: React.FC<Props> = ({ collusionCase: c, refresh }) => {
  const playerId = useSelector(
    (state: AppState) => state.watson.player.profile.profile?.PlayerId
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [status, setStatus] = useState<Status>('Block')
  const [action, setAction] = useState(actions[0])
  const [reason, setReason] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [apiCalled, setApiCalled] = useState(false)

  const { Option } = Select

  const showModal = (s: Status) => () => {
    setStatus(s)
    setIsModalVisible(true)
  }

  const handleOk = async () => {
    setApiCalled(true)

    let err
    if (status === 'Block') {
      err = await BlockPlatformActionsForUser({
        playerId: playerId || '',
        blockActions: actions.reduce(
          (ba: any, a: string) => ({ ...ba, [a]: action === a }),
          {}
        ),
        reason: {
          title,
          description,
          remark: reason,
        },
        source: c.case_ref,
        referenceIds: [c.case_ref],
      })
    } else {
      err = await UnblockPlatformActionsForUser({
        playerId: playerId || '',
        blockActions: actions.reduce(
          (ba: any, a: string) => ({ ...ba, [a]: action === a }),
          {}
        ),
        reason: {
          remark: reason,
        },
        source: c.case_ref,
        referenceIds: [c.case_ref],
      })
    }

    if (!err) {
      message.success(`${status}ed`)
      setIsModalVisible(false)
      refresh()
    } else {
      message.error(err)
    }
    setApiCalled(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <div className={styles.value}>{c.case_ref}</div>
      <div className={styles.value}>{c.case_type}</div>
      <div className={styles.value}>{c.role}</div>
      <div className={styles.value}>
        <Timestamp timestamp={moment(c.case_date)} />
      </div>
      <div className={styles.value}>{c.case_status}</div>
      <div className={styles.value}>
        <Button
          type="primary"
          danger
          className={styles.blockBtn}
          onClick={showModal('Block')}
        >
          Block
        </Button>
        <Button
          type="primary"
          className={styles.unblockBtn}
          onClick={showModal('Unblock')}
        >
          Unblock
        </Button>
        {/* <Button className={styles.closeBtn}>Close</Button> */}
      </div>

      <Modal
        title={status}
        visible={isModalVisible}
        okText={status}
        onOk={handleOk}
        onCancel={handleCancel}
        width={902}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={apiCalled}
            onClick={handleOk}
            disabled={
              status === 'Block'
                ? title === '' || description === ''
                : reason === ''
            }
          >
            {status}
          </Button>,
        ]}
      >
        <div className={styles.actions}>
          <div className={styles2.modalH}>Action</div>
          <Radio.Group
            value={action}
            onChange={e => {
              setAction(e.target.value)
            }}
            className={styles2.actionsGroup}
          >
            {actions.map(a => {
              return (
                <Radio key={a} value={a} className={styles2.radio}>
                  {a.charAt(0).toUpperCase()}
                  {a.slice(1)}
                </Radio>
              )
            })}
          </Radio.Group>
          {status === 'Block' && (
            <>
              <div className={`${styles2.modalH} ${styles2.sectionDivider}`}>
                Reason
              </div>
              <Select
                className={styles2.selectTitle}
                onChange={(value: string) => {
                  setTitle(value)
                  setDescription('')
                }}
              >
                {Object.keys(BlockReasonDescriptionMapping).map(a => (
                  <Option key={a} value={a}>
                    {a}
                  </Option>
                ))}
              </Select>
              {!title && <div style={{ color: 'red' }}>Required!</div>}
              <div className={`${styles2.modalH} ${styles2.sectionDivider}`}>
                Description
              </div>
              <Select
                className={styles2.selectDesc}
                disabled={!title}
                onChange={(value: string) => setDescription(value)}
              >
                {BlockReasonDescriptionMapping[title]?.map(a => (
                  <Option key={a} value={a}>
                    {a}
                  </Option>
                ))}
              </Select>
              {!description && <div style={{ color: 'red' }}>Required!</div>}
            </>
          )}
          <div className={`${styles2.modalH} ${styles2.sectionDivider}`}>
            {status === 'Block' ? 'Remark' : 'Reason'}
          </div>
          <Input.TextArea
            value={reason}
            rows={3}
            onChange={e => {
              setReason(e.target.value)
            }}
          />
          {status !== 'Block' && !reason && (
            <div style={{ color: 'red' }}>Required!</div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default CollusionRow
