import React from 'react'
import styles from './Campaigns.module.css'

const Campaign: React.FC = () => {
  return (
    <div id={styles.campaign}>
      <div className={styles.title}>Campaign</div>
      <div className={styles['coming-soon']}>COMING SOON</div>
    </div>
  )
}

export default Campaign
